import React, { useContext } from "react";
import { Button, Card, CardContent, Divider, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import ThemeTable from "../../Table/ThemeTable";
import { convertFirestoreTimestampToDate, formatDate } from "../../../services/helpers";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Checkbox from '@mui/material/Checkbox';
import styles from "../ReportsComponent/Reports.module.scss"
import AuthContext from "../../../hooks/authContext";

dayjs.extend(utc);
dayjs.extend(timezone);

const groupByParentCategoryId = (rows) => {
    return rows.reduce((acc, row) => {
        const { parentCategoryId } = row;
        if (!acc[parentCategoryId]) {
            acc[parentCategoryId] = [];
        }
        acc[parentCategoryId].push(row);
        return acc;
    }, {});
};

const ManageSuppliesDetailsResponsive = (props) => {
    const {
        rows,
        headCells,
        renderRowCell,
        requestData,
        datePickerProps,
        checkProps,
        categories,
        selectedIds,
        handleCheckboxChange,
        settingsData
    } = props;

    const {
        openDatePickerId,
        showDatePicker,
        setShowDatePicker,
        setOpenDatePickerId,
        updateDeliveryDate
    } = datePickerProps;

    const {
        isSelected,
        handleMenuClick,
        anchorEl,
        isMenuOpen,
        handleMenuClose,
        allStatuses,
        selectedStatus,
        handleStatusSelect,
        isUndelivered,
        handleArchive,
        selectAllChecked,
        handleSelectAllChange
    } = checkProps;

    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const auth = useContext(AuthContext);

    const groupedData = groupByParentCategoryId(rows);

    const handleDateIconClick = (id) => {
        if (openDatePickerId === id) {
          setShowDatePicker(false);
          setOpenDatePickerId(null);
        } else {
          setOpenDatePickerId(id);
          setShowDatePicker(true);
        }
      };
    
      const handleDateChangeConfirmed = (id, date) => {
        const utcDate = dayjs(date).toISOString();
        updateDeliveryDate(requestData.id, id, utcDate);
        setShowDatePicker(false);
      };
    
      const handleDateChangeCancelled = () => {
        setShowDatePicker(false);
      };

    return (
        <>
            {!isMobile && !isLandscape && (
                <ThemeTable
                    rows={rows}
                    headCells={headCells}
                    hidePagination={true}
                    renderRowCell={renderRowCell}
                    supplies={true}
                    requestData={requestData}
                    categories={categories}
                />
            )}
            {(isMobile || isLandscape) && (
                <>
                    <div className="d-flex flex-space-between flex-center mb-20">
                        <div className="flex-start">
                            <Button
                                color={isSelected ? "primary" : ""}
                                className="mr-20"
                                variant="contained"
                                size="large"
                                disabled={!isSelected}
                                onClick={handleMenuClick}
                            >
                                Action
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                            >
                                {allStatuses.map(status => (
                                    <MenuItem
                                        key={status}
                                        selected={status === selectedStatus}
                                        onClick={() => handleStatusSelect(status)}
                                    >
                                        {status}
                                    </MenuItem>
                                ))}
                            </Menu>
                            <Button
                                color={isUndelivered ? "primary" : ""}
                                className="mr-20"
                                variant="contained"
                                size="large"
                                onClick={isUndelivered ? handleArchive : null}
                                disabled={!(isUndelivered && settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role))}
                            >
                                Archive
                            </Button>
                        </div>
                        <Checkbox
                            className="mr-12"
                            size="large"
                            checked={selectAllChecked}
                            onChange={handleSelectAllChange}
                        />
                    </div>
                    {Object.entries(groupedData).map(([parentCategoryId, items]) => (
                        <Card key={parentCategoryId} className="mb-20" style={{ boxShadow:"none", border:"1px solid #d7d7d7"}}>
                            <CardContent>
                                <Typography variant="h5" component="div" className={`${styles.cardHeader} p-15`}>
                                    {categories[parentCategoryId] || parentCategoryId}
                                </Typography>
                                <Divider />
                                <List>
                                    {items.map((item, index) => {
                                        const data = convertFirestoreTimestampToDate(item?.deliveryDate);
                                        const dates = dayjs(data).format('YYYY-MM-DD').isValid;

                                        return (
                                            <>
                                                <ListItem key={item?.id}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body"
                                                                style={{ fontSize: '18px', fontWeight: "600", color: "#3d3d3d" }}
                                                            >
                                                                {`${item?.title} (${item?.qty})`}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <>
                                                                <Typography variant="body1" className="d-flex flex-space-between flex-center mt-20">
                                                                    <span>Est. Delivery Date</span>
                                                                    <div>
                                                                        {item?.deliveryDate ? formatDate(item?.deliveryDate) : "---"}
                                                                        <IconButton className="pl-7 pv-0 pr-0">
                                                                            <DateRangeIcon className="color-primary" fontSize="medium" onClick={() => handleDateIconClick(item?.id)} />
                                                                        </IconButton>
                                                                    </div>
                                                                </Typography>
                                                                {showDatePicker && openDatePickerId === item?.id && (
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DesktopDatePicker']}>
                                                                            <DesktopDatePicker
                                                                                value={data ? dayjs(data) : dayjs(dates).local()}
                                                                                onAccept={(date) => handleDateChangeConfirmed(item?.id, date)}
                                                                                onClose={() => {
                                                                                    handleDateChangeCancelled();
                                                                                    setOpenDatePickerId(null);
                                                                                }}
                                                                                format="YYYY-MM-DD"
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                )}
                                                                <Typography variant="body1" className="d-flex flex-space-between flex-center mt-10">
                                                                    <span>Status</span>
                                                                    <div>
                                                                        {item.status}
                                                                        <CheckBoxComponent
                                                                            rcellItem={item}
                                                                            rowIndex={index}
                                                                            handleCheckboxChange={handleCheckboxChange}
                                                                            isChecked={selectedIds.includes(item.id)}
                                                                        />
                                                                    </div>
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                {index < items.length - 1 && <Divider className="p-0 mb-8" />}
                                            </>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    ))}
                </>
            )}
        </>
    );
};

const CheckBoxComponent = ({ rcellItem, rowIndex, handleCheckboxChange, isChecked }) => {
    return (
        <Checkbox
            style={{ paddingRight: 0 }}
            checked={isChecked}
            onChange={() => handleCheckboxChange(rcellItem.id)}
        />
    );
};

export default ManageSuppliesDetailsResponsive;



export function SupplyArchivesDetailsResponsive (props) {
    const {
        rows,
        headCells,
        renderRowCell,
        requestData,
        categories,
        handelUnArchive,
        settingsData
    } = props;

    const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const auth = useContext(AuthContext);

    const groupedData = groupByParentCategoryId(rows);

    return (
        <>
            {!isMobile && !isLandscape && (
                <ThemeTable
                    rows={rows}
                    headCells={headCells}
                    hidePagination={true}
                    renderRowCell={renderRowCell}
                    supplies={true}
                    requestData={requestData}
                    categories={categories}
                />
            )}
            {(isMobile || isLandscape) && (
                <>
                    <div className="d-flex flex-justify-end mb-20">
                        <Button
                            color="primary"
                            className=""
                            variant="contained"
                            onClick={handelUnArchive}
                            disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                            >
                            UnArchive
                        </Button>
                    </div>
                <>
                    {Object.entries(groupedData).map(([parentCategoryId, items]) => (
                        <Card key={parentCategoryId} className="mb-20" style={{ boxShadow: "none", border: "1px solid #d7d7d7" }}>
                            <CardContent>
                                <Typography variant="h5" component="div" className={`${styles.cardHeader} p-15`}>
                                    {categories[parentCategoryId] || parentCategoryId}
                                </Typography>
                                <Divider />
                                <List>
                                    {items.map((item, index) => {
                                        return (
                                            <>
                                                <ListItem key={item?.id}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body"
                                                                style={{ fontSize: '18px', fontWeight: "600", color: "#3d3d3d" }}
                                                            >
                                                                {`${item?.title} (${item?.qty})`}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <>
                                                                <Typography variant="body1" className="d-flex flex-space-between flex-center mt-20">
                                                                    <span>Est. Delivery Date</span>
                                                                    <div>
                                                                        {item?.deliveryDate ? formatDate(item?.deliveryDate) : "---"}
                                                                    </div>
                                                                </Typography>
                                                                <Typography variant="body1" className="d-flex flex-space-between flex-center mt-10">
                                                                    <span>Status</span>
                                                                    <div>
                                                                        {item.status}
                                                                    </div>
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                {index < items.length - 1 && <Divider className="p-0 mb-8" />}
                                            </>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    ))}
                </>
            </>
            )
            }
        </>
    )
}