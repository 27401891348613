/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import EmployeeStepsContext from "../../../hooks/EmployeeStepsContext";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import AddNewGroup from "../Groups/AddNewGroup";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { getGroupsList } from "../../../services/apiservices/groups";

const roles = ["Admin", "Support", "Guest", "Client Team"];

const AssignGroup = (props) => {
  const stepsContext = React.useContext(EmployeeStepsContext);
  const { handleInputChange } = props;
  const { stepFormErrors, stepFormValues } = stepsContext;
  const [selectedrole, setRole] = React.useState("Admin");
  const [allgroups, setAllGroups] = React.useState([]);
  const [groups, setGroups] = React.useState([]);

  const handleChangeRole = (event) => {
    const {
      target: { value },
    } = event;
    setRole(value);
    handleInputChange({ target: { name: "employeerole", value: value } });
    setGroups([]);
  };

  const getGroups = async () => {
    await getGroupsList(
      {},
      (res) => {
        if (res) {
          setAllGroups(res);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  };
  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    setGroups(stepFormValues?.group);
  }, [stepFormValues?.group]);

  useEffect(() => {
    setRole(stepFormValues?.employeerole);
  }, [stepFormValues?.employeerole]);

  const onAddGroupSuccess = () => {
    getGroups();
  };

  const handleChangeGroup = (event) => {
    const {
      target: { value },
    } = event;
    let newGroup = [...groups];
    if (groups.includes(value)) {
      newGroup = [...groups.filter((groupitem) => groupitem !== value)];
    } else {
      newGroup = [...groups, value];
    }
    setGroups(newGroup);
    handleInputChange({ target: { name: "group", value: newGroup } });
  };
  const checkSelected = (group) => {
    return groups && groups.includes(group) ? true : false;
  };
  return (
    <>
      <div className="clear">
        <div className="fleft w-50 pr-25">
          <h5 className="stepSectionTitle">Select Role</h5>
          <div className="stepSectionContent">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="role"
                name="employeerole"
                value={selectedrole ? selectedrole : ''}
                onChange={handleChangeRole}
              >
                {roles?.map((role, index) => (
                  <FormControlLabel
                    key={role + index}
                    value={role}
                    control={<Radio color="primary" />}
                    label={role}
                  />
                ))}
              </RadioGroup>
              {typeof stepFormErrors?.employeerole !== "undefined" &&
                stepFormErrors?.employeerole !== "" && (
                  <span className="small error color-danger">
                    {stepFormErrors?.employeerole}
                  </span>
                )}
            </FormControl>

          </div>
        </div>
        <div className="fleft w-50">
          <h5 className="stepSectionTitle">Select Group</h5>
          <div className={`stepSectionContent`}>
            <FormControl component="fieldset">
              <FormGroup>
                {Array.isArray(allgroups) && allgroups.map((group, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={group?.title + index}
                        checked={checkSelected(group?.groupId)}
                        onChange={handleChangeGroup}
                        name={group?.title}
                        value={group?.groupId}
                        color="primary"
                        disabled={selectedrole !== "Client Team"}
                      />
                    }
                    label={group?.title}
                  />
                ))}
              </FormGroup>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{ size: "medium", variant: "contained" }}
                buttonLabel={"Add New Group"}
                ModalTitle={"Add New Group"}
                ModalContent={<AddNewGroup onAddSuccess={onAddGroupSuccess} />}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignGroup;
