import { Button, Container, IconButton, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import TableToolbar from "../../components/Table/TableToolbar";
import { useNavigate } from "react-router";
import { getAllEmployee } from "../../services/apiservices/employee";
import ThemeTable from "../../components/Table/ThemeTable";
import EditIcon from "@material-ui/icons/Edit";

const ManageSuperUser = () => {

    const [searchTerm, setsearchTerm] = React.useState("");
    const [superUserData, setSuperUserData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    const Columns = [
        {
            id: "userName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            isSortable: true,
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            isSortable: true,
        },
        {
            id: "phoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Contact",
            isSortable: true,
        },
        {
            id: "role",
            numeric: false,
            disablePadding: true,
            label: "Role",
            isSortable: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            rowclassName: "text-right",
            className: "text-right pr-30"
        },
    ];

    const fetchUserList = async () => {
        setIsLoading(true);
        let superUserData = [];
        await getAllEmployee((res) => {
            superUserData = res?.data;
        });
        const userDetails = superUserData.filter((item) => item.role === 'superUser' && !item.deleted);

        let newData = userDetails.map((res) => {
            return {
                ...res,
                userName: `${res.firstName} ${res.lastName}`,
            }
        })
        let sortingData = sortingFunction(newData);
        setSuperUserData(sortingData);
        setIsLoading(false);
    }

    const sortingFunction = (data) => {
        const result = data?.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;

            if (a.userName < b.userName) return -1;
            if (a.userName > b.userName) return 1;

            return 0;
        });

        return result;
    };


    useEffect(() => {
        fetchUserList();
    }, []);


    const redirectToCreateSuperUser = (superUserId) => {
        if (typeof superUserId !== "undefined" && superUserId !== "")
            navigate("/createSuperUser/" + superUserId);
        else navigate("/createSuperUser");
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "action":
                return (
                    <>
                        <IconButton
                            color="primary"
                            onClick={() => redirectToCreateSuperUser(rcellItem?.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                );

            default:
                return <p>{rcellItem[hcellItem?.id]} </p>;
        }
    }

    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch">
                <div className="mh-9">
                    <TextField
                        fullWidth={true}
                        size="small"
                        name="searchTerm"
                        id="searchTerm"
                        label=""
                        variant="outlined"
                        placeholder="Search SuperUser..."
                        className="input"
                        onChange={(event) => {
                            setsearchTerm(event.target.value);
                        }}
                        value={searchTerm ? searchTerm : ""}
                    />
                </div>
                <Button
                    className="flatbutton w-norm"
                    size={"small"}
                    variant="contained"
                    color="primary"
                    onClick={() => redirectToCreateSuperUser()}
                >
                    Add New SuperUser
                </Button>
            </div>
        )
    };

    const checkRowSearch = (searchTerm, row) => {
        return (
            String(row.userName)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.phoneNumber)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.email)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase())
        );
    };

    const filteredList =
        searchTerm !== ""
            ? superUserData?.filter((item) => checkRowSearch(searchTerm, item))
            : superUserData;

    return (
        <Container>
            <PageTitle
                pageTitle={"Manage SuperUser"}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                ]}
                BreadCrumbCurrentPageTitle={"SuperUser"}
                showLastSeen={true}

            />
            <div className="sectionBox">
                <TableToolbar
                    title={"Manage SuperUser"}
                    rightComponent={tableRightComponent()}
                />

                <ThemeTable
                    rows={filteredList}
                    headCells={Columns}
                    hidePagination={false}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                />
            </div>
        </Container>
    )
}

export default ManageSuperUser;