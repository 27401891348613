/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import TaskLists from "./Tasks/TasksLists";
import { EventEmitter } from "../../../services/event.service";
import { Button, FormHelperText } from "@material-ui/core";
import { getuniqueId } from "../../../services/helpers";

const CreateTaskItem = (props) => {
  const [isEditMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [editId, setEditId] = useState(false);
  const [disabledsubSteps, setDisabledSubStep] = useState(1);
  const [toggleCustomTaskForm, setToggleCustomTaskForm] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [selectedShift, setSelectedShift] = useState("");
  const [tasks, setTasks] = useState([]);

  const onChangeFrequency = (e) => {
    const newfrequency = e.target.value;

    setFrequency(newfrequency);
    setDisabledSubStep(3);
    setErrorMessage({ ...errorMessage, frequency: '' });

  };
  const onChangeShift = (e) => {
    const newshift = e.target.value;

    setSelectedShift(newshift);
    setDisabledSubStep(2);
    setErrorMessage({ ...errorMessage, selectedShift: '' });

  };
  const fitfunn = ["Monthly", "Quarterly",
    "Semi-Annual",
    "Annually",];

  const fitfunnt = ["Daily",
    "Weekends",
    "Weekly",
    "Bi-Weekly",
  ];

  const onEdit = (item) => {
    if (item) {
      setEditMode(true);
      setEditId(item?.id);
      setFrequency(item?.frequency);
      setSelectedShift(item?.shift);
      setTasks(item?.tasks);
      setDisabledSubStep(4);
    }
  };
  useEffect(() => {
    EventEmitter.subscribe("EditSummaryFrequencyItem", onEdit);
    EventEmitter.subscribe("clearFormCreateTaskGroupItem", clearform);
    return () => {
      EventEmitter.unsubscribe("EditSummaryFrequencyItem", onEdit);
      EventEmitter.unsubscribe("clearFormCreateTaskGroupItem", clearform);
    };
  }, []);

  const onChangeTask = (event, Task) => {
    const {
      target: { checked },
    } = event;
    let newSelectedTasks = tasks ? tasks : [];
    if (checked) {
      if (!newSelectedTasks?.some((item) => Task?.id === item)) {
        newSelectedTasks = [...newSelectedTasks, Task?.id];
      }
    } else {
      newSelectedTasks = [
        ...newSelectedTasks?.filter((item) => Task?.id !== item),
      ];
    }
    setTasks(newSelectedTasks);
    setErrorMessage({ ...errorMessage, tasks: '' });
  };


  const validateStep = () => {
    let isValid = true;
    let errorMessages = errorMessage;
    if (frequency === "") {
      isValid = false;
      errorMessages = { ...errorMessages, frequency: 'Select Frequancy' }
    }
    else {
      errorMessages = { ...errorMessages, frequency: '' }
    }
    if (typeof selectedShift === "undefined" || selectedShift === "") {
      isValid = false;
      errorMessages = { ...errorMessages, selectedShift: "Select Shifts" };
    } else {
      errorMessages = { ...errorMessages, selectedShift: "" };
    }
    if (typeof tasks === "undefined" || tasks === "" || tasks?.length === 0) {
      isValid = false;
      errorMessages = { ...errorMessages, tasks: "Select Task/s" };
    } else {
      errorMessages = { ...errorMessages, tasks: "" };
    }
    setErrorMessage(errorMessages);
    return isValid;
  };
  const submitTaskItem = () => {
    let valid = validateStep();
    if (valid === true) {
      if (isEditMode === true && editId !== false) {
        props?.updateSummaryListItem(editId, {
          id: editId,
          frequency: frequency,
          tasks: tasks,
          shift: selectedShift,
        });
      } else {
        let newid = getuniqueId("shifttask");

        props?.addSummaryListItem({
          id: newid,
          frequency: frequency,
          tasks: tasks,
          shift: selectedShift,
        });
      }
      clearform();
    }
  };
  const clearform = () => {
    setEditMode(false);
    setFrequency("");
    setSelectedShift("");
    setTasks([]);
    setEditId(false);
    setDisabledSubStep(1);
  };

  const onCreateNewTask = (newtask) => {
    let newid = getuniqueId("clienttask");
    let prevTaskList = props?.customtaskList ? props?.customtaskList : [];
    setToggleCustomTaskForm(false);
    props?.setCustomTask([
      ...prevTaskList,
      {
        taskId: newid,
        title: `${newtask.title}`,
        description: `${newtask.description}`,
        startDate: newtask.startDate || '',
        endDate: newtask.endDate || '',
        custom: true,
      },
    ]);
  };

  const onEditTask = (taskId, Task) => {
    props?.setCustomTask([
      ...props?.customtaskList.map((Item) =>
        Item?.taskId === taskId ? { ...Item, ...Task } : Item
      ),
    ]);

  };
  const removeTask = (Task) => {
    let newTaskList = [
      ...props?.customtaskList.filter((sitem) => sitem.taskId !== Task.taskId),
    ];
    props?.setCustomTask(newTaskList);
  };

  const toggleCreateCustomTask = () => {
    setToggleCustomTaskForm(!toggleCustomTaskForm);
  };

  const checkselected = (taskId) => {
    return tasks ? tasks?.some((item) => item === taskId) : false;
  };

  return (
    <>
      <div className={"pb-20"}>
        <div className="d-flex delete">
          <div className="mr-20">
            <h5 className="stepSectionTitle color-black mt-10 ">
              1. Select Shift
            </h5>
            <div className="stepSectionContent pl-15">
              <div className="taskGroupSelect">
                <SingleSelect
                  label="Select Shift"
                  value={selectedShift ? selectedShift : ""}
                  className={`field`}
                  name={"selectedShift"}
                  onChange={onChangeShift}
                  options={
                    props?.shifts && props?.shifts?.length > 0
                      ? [...props?.shifts].map((item) => {
                        let nameco = item?.titlen !== 0 ? "-" + item?.titlen : "";
                        return {
                          label: item?.title + nameco + " (" + (item?.day?.map(
                            (dayitem, index) =>
                              dayitem?.toLowerCase().substring(0, 3) +
                              (index < item?.day?.length - 1 ? "" : "")
                          )) + ")",
                          value: item,

                        };

                      })

                      : []
                  }
                />
              </div>
              {typeof errorMessage?.selectedShift !== "undefined" &&
                errorMessage?.selectedShift !== "" && (
                  <FormHelperText className="small error color-danger">
                    {errorMessage?.selectedShift}
                  </FormHelperText>
                )}
            </div>
          </div>
          <div className="mr-20">
            <h5 className="stepSectionTitle color-black mt-10 ">
              2. Select Task Frequency
            </h5>
            <div className="stepSectionContent pl-15">
              <div className="taskGroupSelect">
                <SingleSelect
                  label="Select Task Frequency"
                  value={frequency ? frequency : ""}
                  className={`field`}
                  name={"frequency"}
                  onChange={onChangeFrequency}
                  disabled={disabledsubSteps < 2}
                  options={selectedShift?.title === "Opentasks" ? fitfunn.map((item) => {
                    return {
                      label: `${item}`,
                      value: item,
                    };
                  }) : [...fitfunnt].map((item) => {
                    return {
                      label: `${item}`,
                      value: item,
                    };
                  })}
                />
              </div>
              {typeof errorMessage?.frequency !== "undefined" &&
                errorMessage?.frequency !== "" && (
                  <FormHelperText className="small error color-danger">
                    {errorMessage?.frequency}
                  </FormHelperText>
                )}
            </div>
          </div>
        </div>
        {selectedShift?.title !== "Opentasks" && <TaskLists
          disabled={disabledsubSteps < 3}
          label={"3. Standard Tasks List"}
          taskList={props?.standardTasks}
          search={true}
          handleTasksCheckChange={onChangeTask}
          checkselected={checkselected}
        />}
        {/* <TaskLists
          disabled={disabledsubSteps < 3}
          label={"4. Custom Tasks List"}
          taskList={props?.customtaskList}
          shifttitle={selectedShift?.title}
          custom={true}
          toggleCreateCustomTask={toggleCreateCustomTask}
          handleTasksCheckChange={onChangeTask}
          onCreateNewTask={onCreateNewTask}
          removeTask={removeTask}
          onEditTask={onEditTask}
          checkselected={checkselected}
        /> */}

        <div className="mt-20">
          {typeof errorMessage?.tasks !== "undefined" &&
            errorMessage?.tasks !== "" && (
              <FormHelperText className="small error color-danger">
                {errorMessage?.tasks}
              </FormHelperText>
            )}
          <Button
            className="flatbutton themebutton mr-15"
            color="primary"
            variant="contained"
            onClick={submitTaskItem}
          >
            {isEditMode === true
              ? "Update Task Frequency"
              : "Save Task Frequency"}
          </Button>
          {isEditMode === true && (
            <Button
              className="flatbutton themebutton"
              variant="contained"
              onClick={clearform}
            >
              Cancel Edit
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateTaskItem;
