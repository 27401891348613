import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import styles from "../dashboard.module.scss";
import { getDocCount } from "../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../services/config";
import AuthContext from "../../../hooks/authContext";

function EmpRequests(props) {
  const auth = useContext(AuthContext);
  const [progress, setProgress] = useState(0);
  const [empReqData, setEmpReqData] = useState([]);
  const [empTotal, setEmpTotal] = useState(0);
  const [empUrg, setEmpUrg] = useState(0);
  const [status, setStatus] = useState("Supplies");
  const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
  const statusList = [
    { label: "Supplies", value: "Supplies" },
    { label: "Clothing", value: "Clothing" },
  ];

  const onChangeInput = (e) => {
    setStatus(e.target.value);
  };

  const updateProgress = (newval) => {
    setProgress(newval);
  };

  useEffect(() => {
    getDocCount(
      COLLECTIONS.SUPPLIES_ORDER,
      (res) => {
        const details = res.filter((item) => item.companyId === companyId && item.archive !== true)
        setEmpReqData(details);

        // Calculate progress
        const urgentreq = details.filter((item) => item.priority === '1');
        if (res.length) {
          updateProgress((100 / res.length) * urgentreq.length);
        } else {
          updateProgress(0);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  useEffect(() => {
    let filteredData = [];

    if (status === "Clothing") {
      filteredData = empReqData.filter(record =>
        record.supplyItems.some(item => item.maxNumberAllowed !== undefined)
      );
    } else if (status === "Supplies") {
      filteredData = empReqData.filter(record =>
        record.supplyItems.every(item => !item.hasOwnProperty('maxNumberAllowed'))
      );
    }

    const urgentRequests = filteredData.filter(item => item.priority === '1').length;

    setEmpTotal(filteredData.length);
    setEmpUrg(urgentRequests);
  }, [status, empReqData]);

  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe">Employee Requests</p>
          <SingleSelect
            value={status}
            className={`field newonr`}
            name={"status"}
            onChange={(e) => onChangeInput(e)}
            options={statusList}
          />
        </Grid>
        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel signt={"yes"} value={progress} wrapclass="color-empre" />
        </Grid>
      </Grid>
      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>Total Requests</Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{empTotal}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>Urgent Request</Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{empUrg}</strong>
            </Grid>
          </Grid>
        </li>
      </ul>
    </>
  );
}

export default EmpRequests;
