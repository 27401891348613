export const BaseUrl = (function() {
  if(process.env.REACT_APP_CUSTOM_ENV === "prod") return 'https://us-central1-csmg-a0cd2.cloudfunctions.net/';
  else if(process.env.REACT_APP_CUSTOM_ENV === "stage") return 'https://us-central1-cms-staging-4da9c.cloudfunctions.net/';
  else return 'https://us-central1-csmg-dev-dc546.cloudfunctions.net/';
}());

// console.log("BaseUrl1>>", BaseUrl1);
// export const BaseUrl = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'https://us-central1-csmg-a0cd2.cloudfunctions.net/' : 'https://us-central1-csmg-dev-dc546.cloudfunctions.net/';

export const DOMAINURL = (function() {
  if(process.env.REACT_APP_CUSTOM_ENV === "prod") return 'proteams.io';
  else if(process.env.REACT_APP_CUSTOM_ENV === "stage") return 'stage.proteams.io';
  else return 'dev.proteams.io';
}());
// export const DOMAINURL = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'proteams.io' : 'dev.proteams.io';

export const APP_DOMAINURL = (function() {
  if(process.env.REACT_APP_CUSTOM_ENV === "prod") return 'https://appview.proteams.io/auth/loginweb/';
  else if(process.env.REACT_APP_CUSTOM_ENV === "stage") return 'https://appviewstage.proteams.io/auth/loginweb/';
  else return 'https://appviewdev.proteams.io/auth/loginweb/';
}());

// export const APP_DOMAINURL = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'https://appview.proteams.io/auth/loginweb/' : 'https://appviewdev.proteams.io/auth/loginweb/';

// export const APP_DOMAINURL = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'https://appview.proteams.io/auth/loginweb/' : 'http://localhost:5173/auth/loginweb/';
// export const APP_DOMAINURL = 'http://localhost:5173/auth/loginweb/';

export const ISSUE_DOMAIN = (function() {
  if(process.env.REACT_APP_CUSTOM_ENV === "prod") return 'http://issues.proteams.io/';
  else if(process.env.REACT_APP_CUSTOM_ENV === "stage") return 'http://issuesstage.proteams.io/';
  else return 'http://issuesdev.proteams.io/';  
}());
// export const ISSUE_DOMAIN = process.env.REACT_APP_CUSTOM_ENV === "prod" ? 'http://issues.proteams.io/' : 'http://issuesdev.proteams.io/';

export const DOMAINURL_SUPPIESEMAIL = 'devemp.proteams.io';

export const ROLES = {
  CLIENT: 'Client',
  EMPLOYEE: 'Employee',
  ADMIN: 'admin',
  SUPPORT: 'support',
  SUPERUSER: 'superUser',
  COMPANYUSER: 'companyUser'
};

export const EMP_ROLES = {
  CLIENT_TEAM: 'Client Team',
  ADMIN: 'Admin',
  GUEST: 'Guest',
  SUPPORT: 'Support'
};
export const COLLECTIONS = {
  USERS: 'users',
  EMPLOYEE: 'employees',
  GROUPS: 'groups',
  CLIENT: 'clientsNew',
  SHIFTS: 'shifts',
  TIMESHEETS: 'timesheets',
  STANDARDTAKS: 'standardTasks',
  SUPPLIES_CLEANINGS: 'supplies_cleanings',
  SUPPLIES_ORDER: 'supply_order',
  SUPPLIES_CATEGORY: 'supplies_category',
  SUPPLIES_ORDER_NOTES: 'supply_order_notes',
  ISSUES: 'issues',
  ADMIN_STAT_DATA_COUNT: 'stat_data',
  NOTIFICATIONS: 'notifications',
  ALERT: 'alerts',
  CHAT_CHANNELS: 'chat_channels',
  CHAT_MESSAGES: 'chat_messages',
  USER_ACTIVITY_LOG: 'user_activity_log',
  ISSUE_NOTIFICATIONS:'issue_notifications',
  APPOINMENTS:'appoinments',
  NOTIFICATION_SETTINGS: 'notification_settings',
  ISSUE_SETTINGS: 'issue_settings',
  APPOINTMENT_SETTINGS: 'appointment_settings',
  CLIENT_SETTINGS: 'client_settings',
  SUPPLIES_SETTINGS: 'supplie_settings',
  SUPPLIES_COMPANY_CATEGORY: 'supplies_company_category',
  SUPPLIES_INDUSTRY: 'supplies_industry',
  STRIPE_EVENT: 'strip_events',
  STRIPE_PRODUCTS: 'strip_products',
  STRIPE_INVOICE: 'strip_invoice',
  STRIPE_SUBSCRIPTION: 'strip_subscription',
};
