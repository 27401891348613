import { firebase } from './firebase-config';
import axiosInstance from './axios';
import { confirmPasswordReset, createUserWithEmailAndPassword, getAuth, getIdToken, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { getLoginToken } from './storage';
import { BaseUrl, DOMAINURL } from './config';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
// const firestore = firebase.firestore();
// const settings = { timestampsInSnapshots: true };
// firestore.settings(settings);

/**
 * so this function is called when the authentication state changes
 * in the application, a side effect of that is that we need to get
 * the rest of the user data from the user collection, that is
 * done with the _handleAuthedUser callback
 */
const auth = getAuth();
const storage = getStorage();

export const authCheck = async (_handleAuthedUser) => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        return resolve(await _handleAuthedUser(user));
      } else {
        _handleAuthedUser(null);
        return resolve(null);
      }
    });
  });
};

/**
 *
 * @param {*} email
 * @param {*} password
 */
export const loginWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdToken();

    return {
      userId: user?.uid,
      user,
      token
    };
  } catch (error) {
    return error;
  }
};
/**
 *
 * @returns data of current logged in user from users collection
 */
export const getCurrentUser = async () => {

  const user = auth.currentUser;
  let token = null;
  let userData = {};

  if (user) {
    token = await getIdToken(user);

    const userDocRef = doc(firebase, 'users', user.uid);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      userData = { ...docSnap.data(), id: user.uid };
    } else {
      console.log('No such document!', user.uid);
    }
  }
  return { user, userData, token };
};

export const getCurrentUserToken = async (user) => {
  let token = await user.getIdToken();
  return token;
};
/**
 * Log out user
 */
export const logOut = () => {
  return signOut(auth);
};

/**
 *
 * @param {*} userInfo.lastName
 * @param {*} userInfo.firstName
 * @param {*} userInfo.email
 * @param {*} userInfo.password
 */

export const registerUser = async (userInfo) => {
  let newcreateduser = {};
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password);
    newcreateduser = userCredential;

    const { email, firstName, lastName, role, createdAt, phonenumber } = userInfo;

    await setDoc(doc(firebase, 'users', newcreateduser.user.uid), {
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || '',
      role: role || 'Employee',
      createdAt: createdAt || '',
      phoneNumber: phonenumber || '',
    });

    return { ...newcreateduser.user, firstName, lastName };
  } catch (error) {
    return { status: false, error };
  }
};

/**
 * register new user to firebase auth and users collection (not used for now manage from cloud function)
 * @param {*} newUser
 * @returns
 */
export async function firebasesignupUser(newUser) {
  try {
    const userRef = doc(firebase, 'users', newUser.email);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      return { status: false, message: 'This username is already taken' };
    } else {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const newCreatedUser = userCredential.user;

      const { email, firstName, lastName, role, createdAt, phonenumber } = newUser;

      await setDoc(doc(firebase, 'users', newCreatedUser.uid), {
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        role: role || '',
        createdAt: createdAt || '',
        phoneNumber: phonenumber || '',
      });

      return { ...newCreatedUser, firstName, lastName };
    }
  } catch (error) {
    return { status: false, error: error.message };
  }
}

/**
 * Sent Forgot password email for give email user
 * @param {*} email
 * @param {*} onSuccess
 * @param {*} onError
 */
export const forgotPasswordUser = async (email, onSuccess, onError) => {
  try {
    const userQuery = query(collection(firebase, 'users'), where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);

    if (userSnapshot.empty) {
      if (onError) {
        onError({ status: false, message: 'No user found with this email' });
      }
    } else {
      await sendPasswordResetEmail(auth, email);
      
      if (onSuccess) {
        onSuccess({ status: true, message: 'Password reset email sent!' });
      }
    }
  } catch (error) {
    const errorMessage = error.message;
    if (onError) {
      onError({ status: false, message: errorMessage });
    }
  }
};

export const forgotPasswordAdmin = async (email, phoneNumber, onSuccess, onError) => {
  try {
    const userQuery = query(collection(firebase, 'users'), where('email', '==', email));
    const userSnapshot = await getDocs(userQuery);
    
    if (userSnapshot.empty) {
      if (onError) {
        onError({ status: false, message: 'No User found with this email' });
      }
      return;
    }
    
    let user = {};
    userSnapshot.forEach((doc) => {
      user = doc.data();
    });

    let actionCodeSettings = {
      url: `https://${DOMAINURL}/`
    };

    if (user.employeerole === 'Client Team') {
      actionCodeSettings = {};
    }

    await sendPasswordResetEmail(auth, email, actionCodeSettings);

    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
    await axiosInstance.post(
      `${BaseUrl}sendSmsText`,
      {
        phoneNumber: [phoneNumber],
        textMessage: `Hello,\n\nYour ProTeams password has been reset. Please check ${email} for your password reset link`,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true,
          ...authHeaders,
        },
      }
    );

    if (onSuccess) {
      onSuccess({ status: true, message: 'Password reset email sent!' });
    }
  } catch (error) {
    const errorMessage = error.message;
    if (onError) {
      onError({ status: false, message: errorMessage });
    }
  }
};

export const ResendAppLink = async (
  email,
  phoneNumber,
  firstName,
  password,
  onSuccess,
  onError
) => {
  try {
    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };

    await axiosInstance.post(
      `${BaseUrl}sendEmailData`,
      {
        email: email,
        subject: `App Invitation From ProTeams.io `,
        contenttxt:
          `<p>Hello ` +
          firstName +
          `,</p>
  
      <p>You've been invited to the ProTeams mobile app. Please click on the https://${DOMAINURL}/apps/ to download the app from iOS or Android stores. Once you have installed the app you can you login by using the following details: </p>
      <p>User name:<br />` +
                  email +
                  `</p> 
                 <p>Password:<br />` +
                  password +
                  `</p>
                  <p></p>
                  <p></p>
                  <p>Proteams Support Team</p>`,
     
      },
      {
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true,
          ...authHeaders,
        },
      }
    );

    await axiosInstance.post(
      `${BaseUrl}sendSmsText`,
      {
        phoneNumber: [phoneNumber],
        textMessage: `Hello ${firstName},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${email}`,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true,
          ...authHeaders,
        },
      }
    );

    if (onSuccess) {
      onSuccess({ status: true, message: 'Resend Invitation sent!' });
    }
  } catch (error) {
    if (onError) {
      onError({ status: false, message: error.message });
    }
  }
};

/**
 * Returns Current login user profile data
 */
export const getUserProfile = async (token, onSuccess, onError) => {
  try {
    const user = auth.currentUser;

    if (user) {
      const userRef = doc(firebase, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        onSuccess && onSuccess({ status: true, data: { ...docSnap.data(), id: user.uid } });
      } else {
        onError && onError({ status: false, data: 'No such document!' });
      }
    } else {
      onError && onError({ status: false, data: 'No user logged in!' });
    }
  } catch (error) {
    onError && onError({ status: false, data: 'Error retrieving user profile!', error });
  }
};

/**
 *
 * @param {*} blob
 */
export const uploadImage = (blob) => {
  return new Promise((resolve, reject) => {
    const currentUserId = auth.currentUser?.uid;

    const imageRef = ref(storage, `${currentUserId}/${new Date().getTime()}-${currentUserId}.jpeg`);

    const uploadTask = uploadBytesResumable(imageRef, blob);

    uploadTask.on(
      'state_changed',
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      
      (error) => {
        console.log('error', error);
        reject(error);
      },
      async () => {

        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); 
          const metadata = uploadTask.snapshot.metadata; 
          resolve({
            url: downloadURL,
            contentType: metadata.contentType,
            name: metadata.name,
            size: metadata.size,
          });
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};

export const updatepasswordem = (code, newPassword, onSuccess, onError) => {

  confirmPasswordReset(auth, code, newPassword)
    .then(() => {
      onSuccess && onSuccess({ status: true, message: 'Password Updated!' });
    })
    .catch((error) => {
      const errorMessage = error.message;
      onError && onError({ status: false, message: errorMessage });
    });
};
