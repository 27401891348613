/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import CommonContext from "../../../hooks/commonContext";
import { Container, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./suppliesTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getSupplyOrderList, updateSuppliesStatus, supplyRejectReasons, getCategories, updateSupplyItemsStatus } from "../../../services/apiservices/supplies";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { FormatedDates, groupByKey } from "../../../services/helpers";
import ConfirmationDialog from "../../../components/DialogComponent/ConfirmationDialog";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { saveNotification2 } from "../../../services/apiservices/notifications";
import AuthContext from "../../../hooks/authContext";
import { useMediaQuery } from "@mui/material";
import SupplyCommonCard from "../../../components/ResponsiveCardComponent/SupplyComponent/SupplyListPageResponsive";
import { SuppplyDataDialog, ConfirmationsDialog, RejectDailog, SuccessDialog, CustomTooltip } from "../../../components/DialogComponent/SupplyDataDialog";
import { getSettings } from "../../../services/apiservices/settings";
import { DOMAINURL_SUPPIESEMAIL } from "../../../services/config";


const Supplies = (props) => {
  const commonContext = useContext(CommonContext);
  const auth = useContext(AuthContext);
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [allClient, setAllClients] = React.useState([]);
  const [allDate, setAllDate] = React.useState([]);
  const [selectedPriority, setSelectedPriority] = useState("any");
  const [recordID, setRecordID] = useState();
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonMessage, setReasonMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [data, setData] = useState();
  const [empData, setEmpData] = useState();
  const [categoryMap, setCategoryMap] = useState({});
  const [dialogOpenSucces, setDialogOpenSucces] = useState(false);
  const [dialogOpenReject, setDialogOpenReject] = useState(false);
  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
  const [opens, setOpens] = useState(null);
  const [openSupplyDialog, setOpenSupplyDialog] = useState(false);
  const [openData, setOpenData] = useState(null);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = useState(null);
  const [settingsData, setSettingsData] = useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
  // const [recPerPage, setRecordsPerPage] = React.useState(10);
  const [filter, setFilterValues] = React.useState({
    date: "any",
    client: "any",
    status: "all",
    priority: "any",
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const checkFilteredList = () => {
    if (allSupplies) {
      let filteredSuppliesList = allSupplies?.filter((row) => {
        let searchFilter = true,
          filterstatus = true,
          filterclient = true,
          filterdate = true,
          filterpriority = true;
        
        if (filter?.status !== "" && filter?.status !== "all") {
          filterstatus =
            String(row.status).toLowerCase() ===
            String(filter.status).toLowerCase();
        }
        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.date !== "" && filter?.date !== "any") {
          filterdate =
            String(row.dateOfRequest).toLowerCase() ===
            String(filter.date).toLowerCase();
        }
        if (filter?.priority !== "" && filter?.priority !== "any") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }
        return searchFilter && filterstatus && filterclient && filterdate && filterpriority;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allSupplies, filter]);

  const manageDataList = (newRows) => {
    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);

    let allDates = groupByKey(newRows, "dateOfRequest");
    let allDateOptions = Object.keys(allDates)?.map((aacitem) => {
      return { label: FormatedDates(aacitem), value: aacitem };
    });
    let finalSetAllDate = allDateOptions ? allDateOptions : [];
    setAllDate([...finalSetAllDate]);
  };

  const fetchSuppliesList = async () => {
    try {
      setIsLoading(true);

      const [supplyResponse, categoriesResponse] = await Promise.all([
        new Promise((resolve) => getSupplyOrderList({}, (resolve))),
        new Promise((resolve) => getCategories(resolve)),
      ])
  
      let records = supplyResponse?.filter(order => order.companyId === companyId && order.status === "Pending");
  
      if (records?.length === 0) {
        auth.resetPendingSupplyStatus();
      } else {
        auth.setPendingSupplyStatus(records);
      }
  
      const clientIds = [...new Set(records?.map(order => order.clientId))];
      const empIds = [...new Set(records?.map(order => order.createdBy))];
  
      const [clientsResult, employeesResult] = await Promise.all([
        new Promise((resolve) => getClientsListbasedonIds(clientIds, resolve)),
        new Promise((resolve) => getEmployeesListBasedOnId(empIds, resolve)),
      ]);
  
      const clintsData = clientsResult?.map(item => ({
        value: item?.id,
        label: item?.clientName
      }));
  
      const employeeData = employeesResult?.map(item => ({
        value: item?.userId,
        label: `${item?.firstName} ${item?.lastName}`,
        email: item?.email
      }));
  
      setEmpData(employeeData);
  
      const newRows = records?.map(ritem => {
        const client = clintsData?.find(client => client.value === ritem.clientId);
        const employee = employeeData?.find(emp => emp.value === ritem.createdBy);
  
        const totalQuantity = ritem?.supplyItems?.reduce((sum, item) => sum + (item.qty || 0), 0) || 0;
  
        return {
          ...ritem,
          orderId: String(ritem?.orderNumber).padStart(6, '0'),
          supplies: `(${totalQuantity})`,
          employeeName: employee ? employee.label : 'Unknown employee',
          clientName: client ? client.label : 'Unknown Client',
          dateOfRequest: ritem?.dateOfRequest,
        };
      });
  
      const sortedData = sortingFunction(newRows);
  
      await Promise.all([
        fetchAndStoreCategories(sortedData, categoriesResponse),
        manageDataList(sortedData),
        setAllSupplies(sortedData),
      ]);
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching supplies:", error);
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchSuppliesList();
    getSettings('supplie_settings', (res)=> {
      if (res?.data.length > 0) {
        const filteredData = res?.data?.filter(
          (item) => item?.companyId === companyId && !item.deleted
        );
        const data = filteredData?.[0];
        setSettingsData(data)
      } else {
        setSettingsData();
      }
    })
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.orderId < b.orderId) return -1;
      if (a.orderId > b.orderId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return (
      <div className="d-flex flex-center">
      </div>
    );
  };

  const priorityOptions = [
    { label: "Any", value: "any" },
    { label: "High", value: "1" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "3" }
  ];

  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setSelectedPriority(value);
    setFilterValues(prev => ({ ...prev, priority: value }));
  };


  const tableRightComponent = () => {
    return ( 
      <div className="d-flex flex-center w320 editEmp issueAlign pr-2">
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Priority"
            value={selectedPriority}
            className={`field w-60`}
            name={"Priority"}
            onChange={handlePriorityChange}
            options={priorityOptions}
          />
        </div>
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Client Name"
            value={filter?.client ? filter?.client : "any"}
            className={`field w-60 w90 `}
            name={"client"}
            onChange={(e) => {
              setFilter("client", e?.target?.value);
            }}
            options={
              allClient
                ? [{ label: "Any", value: "any" }, ...allClient]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Date"
            value={filter?.date ? filter?.date : "any"}
            className={`field w-60 w90 `}
            name={"date"}
            onChange={(e) => {
              setFilter("date", e?.target?.value);
            }}
            options={
              allDate
                ? [{ label: "Any", value: "any" }, ...allDate]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
      </div>
    );
  };

  const fetchAndStoreCategories = async (latestData, categoriesData) => {
    const newCategoryMap = {};
    for (const item of latestData) {
      const { id: mainId, supplyItems } = item;
      if (supplyItems) {
        const categoriesForItem = [];
        for (const supplyItem of supplyItems) {
          const { parentCategoryId } = supplyItem;
          if (parentCategoryId) {
            try {
              const category = categoriesData?.find((cate) => cate?.id === parentCategoryId && cate?.companyId === companyId);
              const title = category?.title;
              if (title) {
                categoriesForItem.push({
                  parentCategoryId,
                  title
                });
              }
            } catch (error) {
              console.error(`Error fetching categories for parentCategoryId ${parentCategoryId}:`, error);
            }
          }
        }
        newCategoryMap[mainId] = categoriesForItem;
      }
    }
    setCategoryMap(newCategoryMap);
  };

  const sendRejectNotification = async () => {
    let name;
    let email;
    const orderId = data ? data.orderId : null;
    const clientName = data ? data.clientName : null;
    const supply = true;

    const employee = empData.find(emp => emp.value === data.createdBy);
    if (employee) {
      name = employee.label;
      email = employee.email;
    }

    let updatedTitles = [];
    const category = categoryMap[data.id];
    if (category) {
      updatedTitles = data.supplyItems.map((supplyItem, index) => {
        const categoryItem = category.find(cat => cat.parentCategoryId === supplyItem.parentCategoryId);
        const title = categoryItem ? `${categoryItem.title} - ${supplyItem.title}` : `${supplyItem.title}`;
        return `${index + 1}. ${title} (${supplyItem.qty})`;
      });
    }

    const updatedTitlesStr = updatedTitles.join('\n');
    const link = `https://${DOMAINURL_SUPPIESEMAIL}/Supplies/${data.id}`
    const notifications = [
      {
        Type: "PushNotification",
        Message: `Your supply request OrderID ${data.orderId} has been cancelled. Please check your email.`,
        read: false,
        companyId: companyId,
        Employee: [
          {
            id: data?.createdBy,
            name: data?.employeeName,
            email: email
          }
        ]
      },
      {
        Type: "Email",
        Message: `Your supply request OrderId ${data.orderId} has been canceled by the support team.<br>
          Please contact us to resubmit a new request or discuss this order with your support team.<br>
          Details:\n 
          ${updatedTitlesStr}<br><br>
          <p>To respond to this message, please click <a href="${link}" target="_blank">here</a> to open the issue the <span style="white-space: nowrap;">proteams.<wbr>io</span> app.</p><br>
          ProTeams Support Team`,
        read: false,
        companyId: companyId,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];

    await saveNotification2(notifications, supply, orderId, clientName);
  };

  const handleDialogOpen = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
    setSelectedReason('');
    setReasonMessage('');
  };

  const handleProceed = async () => {

    if (!selectedReason && !reasonMessage) {
      setAlertOpen(true);
      return;
    }

    const result = {
      reason: selectedReason,
      message: reasonMessage,
    };

    commonContext?.setLoader(true);

    try {
      const formData = {
        status: "Rejected"
      };

      const promises = [
        supplyRejectReasons(recordID, result),
        updateSuppliesStatus(recordID, formData),
        updateSupplyItemsStatus(recordID, "Cancel Item"),
        sendRejectNotification()
      ];

      handleDialogClose();

      await Promise.all(promises);

      setSelectedReason('');
      setReasonMessage('');
      commonContext?.setLoader(false);
      setDialogOpenReject(true);
    } catch (error) {
      console.error("Error handling proceed:", error);
    }
  };

  const approvedClick = async (id) => {
    const formData = {
      status: "Approved"
    }
    await updateSuppliesStatus(id, formData);
    setDialogConfirmOpen(false);
    setSelectedItem(null);
    setDialogOpenSucces(true);
  }

  const handelApproveClick = async (id) => {
    setDialogConfirmOpen(true);
    setSelectedItem(id);
  }

  const handleDialogConfirmClose = () => {
    setDialogConfirmOpen(false);
    setSelectedItem(null);
  };

  const handleCloseSuccesDialog = async () => {
    setDialogOpenSucces(false);
    await fetchSuppliesList();
  };
  const handleCloseRejectDialog = async () => {
    setDialogOpenReject(false);
    await fetchSuppliesList();
  };

  const handleDialogSupplyOpen = async (data) => {
    setOpenSupplyDialog(true);
    setOpenData(data);
  };

  const handleDialogSupplyClose = async () => {
    setOpenSupplyDialog(false);
    setOpenData(null);
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case "1":
        return "High";
      case "2":
        return "Medium";
      case "3":
        return "Low";
      default:
        return "Unknown";
    }
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {

    switch (hcellItem.id) {
      case "dateOfRequest":
        return FormatedDates(rcellItem?.dateOfRequest);
      case "priority":
        return <>{getPriorityLabel(rcellItem?.priority)}</>;
      case "supplies":
        return (
          <>
            <CustomTooltip
              listData={rcellItem?.supplyItems}
              title={`${rcellItem?.supplies}`}
            />
          </>
        )
      case "action":
        return (
          <>
            <IconButton
            style={{
              color: !settingsData?.approveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green"
            }}
              onClick={() => handelApproveClick(rcellItem?.id)}
              disabled={!settingsData?.approveRequestRole?.includes(auth?.userProfile?.role)}
            >
              <CheckCircleOutlinedIcon />
            </IconButton>
            <IconButton
              style={{
                color: !settingsData?.approveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "red"
              }}
              onClick={() => {
                handleDialogOpen(rcellItem?.id);
                setRecordID(rcellItem?.id);
                setData(rcellItem);
              }}
              disabled={!settingsData?.approveRequestRole?.includes(auth?.userProfile?.role)}
            >
              <CancelOutlinedIcon/>
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };

  const handelTooltipClicks = (id) => {
    setOpens(opens === id ? null : id);
  }

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
        // showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title=""
            leftComponent={tableLeftComponent()}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) &&
            <ThemeTable
              rows={filteredList}
              headCells={Columns}
              hidePagination={false}
              isLoading={isLoading}
              renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            />
          }
          {(isMobile || isLandscape) && (
            <>
              {filteredList.map((record, index) => {
                const isOpen = expanded === record?.id;
                return (
                  <SupplyCommonCard
                    key={record?.id}
                    index={index}
                    record={record}
                    isOpen={isOpen}
                    handleExpandClick={handleExpandClick}
                    getPriorityLabel={getPriorityLabel}
                    handelTooltipClicks={handelTooltipClicks}
                    opens={opens}
                    handelApproveClick={handelApproveClick}
                    handleDialogOpen={handleDialogOpen}
                    setRecordID={setRecordID}
                    setData={setData}
                    handleDialogSupplyOpen={handleDialogSupplyOpen}
                    columns={ColumnsRes}
                    Suppies={true}
                    settingsData={settingsData}
                  />
                );
              })}
            </>
          )}
        </div>
      </Container>
      {dialogOpen && (
        <ConfirmationDialog
          show={dialogOpen}
          title="Reason to Reject Supply"
          confirmation={
            <>
              <SingleSelect
                select
                label="Reason"
                fullWidth
                margin="normal"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
                options={[
                  { label: "Recent Shipment", value: "Recent Shipment" },
                  { label: "Duplicate Request ", value: "Duplicate Request" },
                  { label: "Contact Management", value: "Contact Management" }
                ]}
              />
              <TextField
                fullWidth
                multiline={true}
                label="Reason Message"
                variant="outlined"
                placeholder="Reason Message"
                rows={3}
                margin="normal"
                value={reasonMessage}
                onChange={(e) => setReasonMessage(e.target.value)}
              />
            </>
          }
          proceed={handleProceed}
          dismiss={handleDialogClose}
          cancel={handleDialogClose}
        />
      )}

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        <DialogTitle>Missing Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason or a message before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {dialogConfirmOpen && (
        <ConfirmationsDialog
          open={dialogConfirmOpen}
          onConfirm={() => approvedClick(selectedItem)}
          onClose={handleDialogConfirmClose}
        />
      )}
      <SuccessDialog open={dialogOpenSucces} onClose={handleCloseSuccesDialog} />
      <RejectDailog open={dialogOpenReject} onClose={handleCloseRejectDialog} />
      <SuppplyDataDialog open={openSupplyDialog} onClose={handleDialogSupplyClose} listData={openData}/>
    </>
  );
};
export default Supplies;
