/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import CommonContext from "../../hooks/commonContext";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import StepProfile from "./steps/Profile";
import EmployeeStepsContext from "../../hooks/EmployeeStepsContext";
import validator from "validator";
import { validateUniqueUser } from "../../services/apiservices/users";
import ConfirmCancel from "../../components/DialogComponent/ConfirmCancel";
import DialogComponent from "../../components/DialogComponent/DialogComponent";
import ClientTeam from "./steps/ClientTeam";
import AssignGroup from "./steps/AssignGroup";
import AcuityProfile from "./steps/AcuityProfile";
import { generateP } from "../../services/helpers";
import confirm from "../../components/DialogComponent/confirm";
import { forgotPasswordUser, ResendAppLink } from "../../services/firebase";
import {
  addEmployee,
  updateEmployee,
  getEmployee,
  deleteEmployee,
  updateUser,
  updateUserPassword,
  updateEmployeetoGroup
} from "../../services/apiservices/employee";
import AuthContext from "../../hooks/authContext";

const CreateEmployee = (props) => {
  const commonContext = useContext(CommonContext);
  let history = useNavigate();
  const { employeeId } = useParams();
  const stepsContext = useContext(EmployeeStepsContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [editData, setEditData] = useState({});
  const [stepFormValues, setStepFormValue] = useState({});
  const [stepFormErrors, setStepFormError] = useState({});
  const [finalLoading, setFinalLoading] = useState(false);
  const { stepsArray } = stepsContext;
  const auth = useContext(AuthContext);

  const getEmployeeDetails = (employeeId) => {
    if (typeof employeeId !== "undefined" && employeeId !== "") {
      commonContext?.setLoader(true);
      getEmployee(
        employeeId,
        (res) => {
          if (res?.status === true) {
            setEditData({ ...res?.data, employeeId: employeeId })
            setStepFormValue({ ...res?.data, employeeId: employeeId });
          }
          commonContext?.setLoader(false);
        },
        (resError) => {
          if (resError?.error === 'Employee not found') {
            history("/manageEmp");
          }
          commonContext?.setLoader(false);
        }
      );
    } else {
      setStepFormValue({});
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    getEmployeeDetails(employeeId);
  }, [employeeId]);
  const goPrev = () => {
    let newStep = currentStep > 1 ? currentStep - 1 : 1;
    setCurrentStep(newStep);
  };

  const goNext = () => {
    let newStep =
      currentStep < stepsArray?.length ? currentStep + 1 : currentStep;
    setCurrentStep(newStep);
  };

  const handleInputChange = (event) => {

    const {
      target: { name, value },
    } = event;
    if (name === "manualAcuityId") {
      setStepFormValue({ ...stepFormValues, [name]: value, acuityId: value });
    } else
      setStepFormValue({ ...stepFormValues, [name]: value });
    setStepFormError({ ...stepFormErrors, [name]: "" });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <StepProfile
            handleInputChange={handleInputChange}
            validateAndGoNext={validateAndGoNext}
          />
        );
      case 2:
        return (
          <AssignGroup
            handleInputChange={handleInputChange}
            validateAndGoNext={validateAndGoNext}
          />

        );
      case 3:
        return (
          <ClientTeam
            handleInputChange={handleInputChange}
            validateAndGoNext={validateAndGoNext}
          />
        );
      case 4:
        return (
          <AcuityProfile
            handleInputChange={handleInputChange}
            validateAndGoNext={validateAndGoNext}
          />
        );
      default:
        return <></>;
    }
  };

  const onCancelAll = () => {
    setStepFormValue({});
    setStepFormError({});
    setCurrentStep(1);
    const isEdit = typeof employeeId !== "undefined" && employeeId !== "";
    isEdit === true && history("/manageEmp");
  };

  const checkUniqUserValidation = async () => {
    setFinalLoading(true);
    let isUniqueValid = true;
    const { email, phoneNumber, userId } = stepFormValues;
    await validateUniqueUser(
      { email: email, mobile: phoneNumber, userId: userId },
      (res) => {
        let dataobj = res?.data;
        if (
          dataobj &&
          Object.keys(dataobj).length === 0 &&
          dataobj.constructor === Object
        ) {
          isUniqueValid = true;
        } else {
          isUniqueValid = dataobj;
        }
        setFinalLoading(false);
      },
      () => {
        isUniqueValid = { form: "Something went wrong" };
        setFinalLoading(false);
      }
    );
    return await isUniqueValid;
  };

  const registerEmployee = async () => {
    setFinalLoading(true);
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      clients,
      employeerole,
      group,
      acuityId,
      isManager,
      timeZone,
      companyId
    } = stepFormValues;

    const determinedCompanyId =
      auth.userProfile.role === "superUser"
        ? companyId
        : auth.userProfile.role === "companyUser"
          ? auth.userProfile.id
          : auth.userProfile.companyId;

    const password = generateP();
    var newUser = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      username: email,
      timeZone: timeZone,
      companyId: determinedCompanyId,
      isManager: isManager ? isManager : false,
      role: "Employee",
      userId: "1",
      password: password,
      confirmPassword: password,
      // clients: clients ? clients : [],
      // employeerole: employeerole ? employeerole : "Guest",
      // group: group ? group : [],
      // acuityId: acuityId ? String(acuityId) : "",
    };
    await addEmployee(
      newUser,
      (empRes) => {
        if (empRes?.data?.status === true) {
          auth.setNewEmpId(empRes?.data?.id);
          setFinalLoading(false);
          // confirm("Employee is Created Successfully", { cancel: false }).then(
          //   () => {
          //     history("/manageEmp");
          //   }
          // );
          // onCancelAll();
        } else {
          setFinalLoading(false);
        }
      },
      (empErrorRes) => {
        setFinalLoading(false);
        let errordata = empErrorRes?.response?.data;
        let error = errordata?.error
          ? errordata?.error?.message
          : errordata?.message
            ? errordata?.message
            : "";
        setStepFormError({ finalerror: error });
      }
    );
  };

  const runupdateEmployee = async ({ finalStep } = {}) => {
    const id = editData ? editData?.userId : auth.newEmpId;
    const newId = employeeId ? employeeId : auth.newEmpId;
    setFinalLoading(true);

    const fieldsToUpdate = {};
    for (const key in stepFormValues) {
      if (
        stepFormValues[key] !== undefined &&
        stepFormValues[key] !== editData?.[key]
      ) {
        fieldsToUpdate[key] = stepFormValues[key];
      }
    }

    if (Object.keys(fieldsToUpdate).length > 0) {
      await updateUser(id, fieldsToUpdate);
    }

    let oldGroups = editData.group ? editData.group : [];
    let newGroups = stepFormValues.group ? stepFormValues.group : [];
    if (
      newGroups &&
      newGroups !== "" &&
      newGroups.length > 0 &&
      newGroups !== oldGroups
    ) {
      let toRemove = [];
      if (oldGroups) {
        toRemove = oldGroups.filter(
          (olditem) => !newGroups.includes(olditem)
        );
      }
      let toAdd = newGroups.filter(
        (newitem) => !oldGroups.includes(newitem)
      );

      if (toRemove && toRemove?.length > 0) {
        await toRemove.map(async (groupItem) => {
          await updateEmployeetoGroup("remove", groupItem, employeeId);
          return groupItem;
        });
      }
      if (toAdd && toAdd?.length > 0) {
        await toAdd.map(async (groupItem) => {
          await updateEmployeetoGroup("add", groupItem, employeeId);
          return groupItem;
        });
      }
    }
    await updateEmployee(
      newId,
      stepFormValues,
      (empRes) => {
        if (empRes?.status === true) {
          setFinalLoading(false);
          if (finalStep && finalStep === true) {
            confirm("Employee is Updated Successfully", { cancel: false }).then(
              () => {
                history("/manageEmp");
              }
            );
            onCancelAll();
          }
        } else {
          setFinalLoading(false);
        }
      },
      (empErrorRes) => {
        setFinalLoading(false);
        let errordata = empErrorRes?.response?.data;
        let error = errordata?.error
          ? errordata?.error?.message
          : errordata?.message
            ? errordata?.message
            : "";
        setStepFormError({ finalerror: error });
      }
    );
  };

  const rundeleteEmployee = async () => {
    commonContext?.setLoader(true);
    deleteEmployee(
      employeeId,
      stepFormValues,
      (empRes) => {
        if (empRes?.status === true) {
          commonContext?.setLoader(false);
          confirm("Employee is Deleted Successfully", { cancel: false }).then(
            () => {
              history("/manageEmp");
            }
          );
          onCancelAll();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (empErrorRes) => {
        commonContext?.setLoader(false);
        let errordata = empErrorRes?.response?.data;
        let error = errordata?.error
          ? errordata?.error?.message
          : errordata?.message
            ? errordata?.message
            : "";
        setStepFormError({ finalerror: error });
      }
    );
  };

  const ResetPasswordLink = async () => {
    commonContext?.setLoader(true);
    const { email } = stepFormValues;
    try {
      await forgotPasswordUser(email, () => {

      });
      commonContext?.setLoader(false);
      alert("Reset Password Link sent to User's Email...");
    } catch (error) {
      commonContext?.setLoader(false);
      console.error("Error resetting password:", error);
      alert("An error occurred. Please try again.");
    }
  };


  const ResendinvitaLink = async () => {
    commonContext?.setLoader(true);
    const { firstName, email, phoneNumber } = stepFormValues;
    const password = generateP();
    try {
      await updateUserPassword(editData.userId, {
        password: password,
        email: email,
      });

      await ResendAppLink(email, phoneNumber, firstName, password, () => {

      });
      commonContext?.setLoader(false);
      alert("Resend Invitation sent to Employee");
    } catch (error) {
      commonContext?.setLoader(false);
      console.error("Error sending invitation link:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const validateAndGoNext = async () => {
    let isValid = true;
    let errormessages = stepFormErrors;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      timeZone,
      companyId,
      clients,
      employeerole,
      group,
      acuityId
    } = stepFormValues;


    // validate step
    switch (currentStep) {
      case 1:
        if (
          typeof firstName === "undefined" ||
          firstName === undefined ||
          firstName === ""
        ) {
          isValid = false;
          errormessages = {
            ...errormessages,
            firstName: "First Name is Required",
          };
        } else {
          errormessages = { ...errormessages, firstName: "" };
        }
        if (typeof lastName === "undefined" || lastName === "") {
          isValid = false;
          errormessages = {
            ...errormessages,
            lastName: "Last Name is Required",
          };
        } else {
          errormessages = { ...errormessages, lastName: "" };
        }

        if (typeof phoneNumber === "undefined" || phoneNumber === "") {
          isValid = false;
          errormessages = {
            ...errormessages,
            phoneNumber: "Mobile is Required",
          };
        } else if (!validator.isMobilePhone(phoneNumber, ["en-US", "en-IN"])) {
          isValid = false;
          errormessages = {
            ...errormessages,
            phoneNumber: "Invalid Mobile Number",
          };
        } else {
          errormessages = { ...errormessages, phoneNumber: "" };
        }

        if (typeof email === "undefined" || email === "") {
          isValid = false;
          errormessages = { ...errormessages, email: "Email Required" };
        } else if (!validator.isEmail(email)) {
          isValid = false;
          errormessages = { ...errormessages, email: "Invalid Email" };
        } else {
          errormessages = { ...errormessages, email: "" };
        }

        if (
          isValid
        ) {
          let isunique = await checkUniqUserValidation();
          if (isunique !== true && isunique !== "") {
            isValid = false;
            errormessages = { ...errormessages, ...isunique };
          }
        }

        if (auth.userProfile.role === "superUser") {
          if (
            typeof companyId == "undefined" ||
            companyId === ""
          ) {
            isValid = false;
            errormessages = {
              ...errormessages,
              companyId: "Company is Required",
            };
          } else {
            errormessages = { ...errormessages, timeZone: "" };
          }
        }

        if (typeof timeZone === "undefined" || timeZone === "") {
          isValid = false;
          errormessages = {
            ...errormessages,
            timeZone: "TimeZone is Required",
          };
        } else {
          errormessages = { ...errormessages, timeZone: "" };
          if (auth.newEmpId || employeeId) {
            await runupdateEmployee();
          } else {
            await registerEmployee();
          }
        }

        break;
      case 2:
        if (typeof employeerole === "undefined" || employeerole === "") {
          isValid = false;
          errormessages = { ...errormessages, employeerole: "Role is Required" };
        } else {
          errormessages = { ...errormessages, employeerole: "" };
          if (
            employeerole === "Client Team" &&
            (typeof group === "undefined" || group === "" || group.length === 0)
          ) {
            await confirm(
              "This employee is not being assigned to any groups. Do you wish to proceed?"
            ).then(
              async () => {
                isValid = true;
                await runupdateEmployee();
              },
              () => {
                isValid = false;
              }
            );
          } else {
            await runupdateEmployee();
          }
        }
        break;
      case 3:

        if (
          typeof clients === "undefined" ||
          clients === "" ||
          clients.length === 0
        ) {
          if (employeerole === "Guest" || employeerole === 'Admin') {
            isValid = true;
          } else {
            isValid = false;
            await confirm(
              "This employee is not being assigned to a client. Do you wish to proceed?"
            ).then(
              async () => {
                isValid = true;
                await runupdateEmployee();
              },
              () => {
                isValid = false;
              }
            );
          }
        } else {
          await runupdateEmployee();
        }
        break;

      case 4:
        if (
          employeerole === "Client Team" &&
          (typeof acuityId === "undefined" || acuityId === "")
        ) {
          isValid = false;
          errormessages = {
            ...errormessages,
            acuityId: "Acuity Id is Required",
          };
        } else {
          errormessages = { ...errormessages, acuityId: "" };
          await runupdateEmployee({ finalStep: true });
        }
        break;
      default:
        isValid = true;
        break;
    }
    setStepFormError(errormessages);
    if (isValid) {
      goNext();
    }
  };

  const renderStepItem = (stepItem, index) => {
    const stepId = stepItem.id;
    const isEdit = typeof employeeId !== "undefined" && employeeId !== "";
    let stepClasses = "step step" + stepId + " ";
    stepClasses += currentStep > stepId ? "complated" : "";
    stepClasses += currentStep === stepId ? "activeStep" : "";
    return (
      <div key={`stepdiv${stepId}`} className={`${stepClasses}`}>
        <h4
          className="stepTitle"
          onClick={() => currentStep > stepId && setCurrentStep(stepId)}
        >
          {stepItem.title} <span className="right">{stepItem.subTitle}</span>
        </h4>
        <div className="stepContent">
          <div className="stepSection">
            {getStepContent(stepId)}
            {stepFormErrors?.finalerror &&
              stepFormErrors?.finalerror !== "" && (
                <span className="small error color-danger">
                  {stepFormErrors?.finalerror}
                </span>
              )}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton "
                onClick={() => validateAndGoNext()}
                disabled={finalLoading === true ? true : false}
              >
                {finalLoading === true
                  ? "Loading..."
                  : stepItem?.NextButtonText
                    ? stepItem?.NextButtonText
                    : "Next"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: finalLoading === true ? true : false,
                }}
                buttonLabel={
                  stepItem?.CancelButtonText
                    ? stepItem?.CancelButtonText
                    : "Cancel"
                }
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={
                  <ConfirmCancel isEdit={isEdit} onCancelAll={onCancelAll} />
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onDeleteEmployee = async () => {
    await confirm(
      "Are you Sure you want to permanent Remove this employee?"
    ).then(
      () => {
        rundeleteEmployee();
      },
      () => {
        console.log("cancel!");
      }
    );
  };
  const onResetPassword = async () => {
    await confirm(
      "Are you Sure you want to Send Reset Password Link to this employee?"
    ).then(
      () => {
        ResetPasswordLink();
      },
      () => {
        console.log("cancel!");
      }
    );
  };
  const onResendinvitation = async () => {
    await confirm(
      "Are you Sure you want to Resend invitation to this employee?"
    ).then(
      () => {
        ResendinvitaLink();
      },
      () => {
        console.log("cancel!");
      }
    );
  };


  return (
    <>
      <EmployeeStepsContext.Provider
        value={{
          stepFormValues: stepFormValues,
          stepFormErrors: stepFormErrors,
          currentStep: currentStep,
          setStepFormValue: setStepFormValue,
          setStepFormError: setStepFormError,
          goPrev: goPrev,
          goNext: goNext,
        }}
      >
        <Container>
          <PageTitle
            pageTitle={
              typeof employeeId !== "undefined" && employeeId !== ""
                ? "Edit Employee"
                : "Create Employee"
            }
            showBreadcrumb={[
              { label: "Home", to: "/" },
              { label: "Employee", to: "/manageEmp" },
            ]}
          />
          <div className={`sectionBox`}>
            <div className="d-flex flex-center flex-space-between editEmp">
              <h3 className="sectiontitle">
                {typeof employeeId !== "undefined" && employeeId !== ""
                  ? "Edit Employee"
                  : "Create New Employee"}{" "}
                -{" "}
                {`${stepFormValues?.firstName
                  ? stepFormValues?.firstName
                  : "Employee x"
                  } ${stepFormValues?.lastName ? stepFormValues?.lastName : ""
                  }`}{" "}
              </h3>{" "}
              {typeof employeeId !== "undefined" && employeeId !== "" && (
                <div className="d-flex flex-center flex-space-between ">
                  <Button
                    variant="contained"
                    className="flatbutton button-danger mr-12"
                    onClick={onDeleteEmployee}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    className="flatbutton button-danger mr-12"
                    onClick={onResetPassword}
                  >
                    Reset password
                  </Button>
                  <Button
                    variant="contained"
                    className="flatbutton button-danger mr-12"
                    onClick={onResendinvitation}
                  >
                    Resend Invitation
                  </Button>

                </div>
              )}
            </div>
            <div className="stepformwrap">
              {stepsArray?.length > 0 &&
                stepsArray?.map((stepItem, index) =>
                  renderStepItem(stepItem, index)
                )}
            </div>
          </div>
        </Container>
      </EmployeeStepsContext.Provider>
    </>
  );
};

export default CreateEmployee;
