/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment-timezone";
import { Button, Container } from "@material-ui/core";
import TableToolbar from "../../../../components/Table/TableToolbar";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { getTimesheetListReport } from "../../../../services/apiservices/timesheets";
import { getClientsList } from "../../../../services/apiservices/client";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import styles from "../../Report.module.scss";
import Popover from "@mui/material/Popover";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as Excel from 'exceljs';
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveTable from "../../../../components/ResponsiveCardComponent/ReportsComponent/ClientReports/ResponsiveClientTable";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div className={styles.Action}>
        <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
          <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
        </Link>
        <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
      </div>
      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          // vertical: 'left',
          horizontal: "left",
        }}
        transformOrigin={{
          // vertical: 'left',
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",

            // padding: '50px', // Add some padding
          },
        }}
      >
        <div className={styles.box}>
          <div className={styles.box2}>Download</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              left: 20,
            }}
          >
            <div>
              <CSVLink
                data={clientsListCSV}
                filename={`ShiftReport_${rcellItem.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
                className={styles.Link}
                asyncOnClick={true}
                onClick={(event, done) => {
                  setDownloadCSVData(event, done, rcellItem);
                }}
              >
                csv
              </CSVLink>
            </div>
            <div>
              <button
                onClick={() => { downloadExcel(rcellItem); }}
                className={styles.Link}
                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize:'small' }}
              >
                Excel
              </button>
            </div>
            <div>
              <button
                onClick={() => { downloadPDF(rcellItem); }}
                className={styles.Link}
                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize:'small' }}
              >
                Pdf
              </button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

function Shiftreport(props) {
  const csvHeaders = [
    "Client Name",
    "Date",
    "Shift Name",
    "Task Assigned",
    "Completed",
    "Pending",
  ];
  const [clientsList, setClientList] = useState([]);
  const [clientsListMaster, setClientsListMaster] = useState([]);
  const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [shiftName, setShiftName] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [clientName, setClientName] = useState("All");
  const [clientNameList, setClientNameList] = useState([
    { label: "All", value: "All" },
  ]);
  const [shiftData, setShiftData] = useState([{ label: "All", value: "All" }]);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  const [detailedView, setDetailedView] = useState(null);

  const getColumns = () => {
    const columns = [
      {
        id: "clientName",
        numeric: false,
        disablePadding: false,
        label: "Client Name",
        isSortable: false,
      },
      {
        id: "appointmentDate",
        numeric: false,
        disablePadding: false,
        label: "Date",
        isSortable: false,
      },
      {
        id: "shiftName",
        numeric: false,
        disablePadding: false,
        label: "Shift Name",
        isSortable: false,
      },
      {
        id: "assigned",
        numeric: false,
        disablePadding: false,
        label: "Task Assigned",
        isSortable: false,
      },
      {
        id: "complete",
        numeric: false,
        disablePadding: false,
        label: "Completed",
        isSortable: false,
      },
      {
        id: "pending",
        numeric: false,
        disablePadding: false,
        label: "Pending",
        isSortable: false,
      },
      {
        id: "Action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        isSortable: false,
      },
    ];
    if (isMobile || isLandscape) {
      return columns.filter(col => col.id !== "clientName" && col.id !== "appointmentDate" && col.id !== "Action");
    }
    return columns;
  };

  const handleclientNameChange = (e) => {
    const str = e.target.value;
    setClientName(str);
    if (str === "All") {
      setClientList(clientsListMaster);
    } else {
      setClientList(() =>
        clientsListMaster.filter((itme) => {
          let returnFlg = true;
          if (shiftName !== "All") {
            returnFlg = itme?.shiftName[0] === shiftName;
          }
          if (str !== "All" && returnFlg) {
            returnFlg = itme?.clientNameId === str;
          }
          return returnFlg;
        })
      );
    }
  };

  const getDatatoDownload = (rcellItem) => {
    let clientCSV = [];
    if (!Array.isArray(rcellItem)) {
      clientCSV = clientsList?.filter(
        (item) => item.clientNameId === rcellItem.clientNameId
      );
      clientCSV = clientCSV?.map((item) => {
        return [
          item?.clientName,
          item?.appointmentDate,
          item?.shiftName[0],
          item?.assigned,
          item?.complete,
          item?.pending,
        ];
      });
    } else {
      clientCSV = rcellItem?.map((item) => {
        return [
          item?.clientName,
          item?.appointmentDate,
          item?.shiftName[0],
          item?.assigned,
          item?.complete,
          item?.pending,
        ];
      });
    }
    return clientCSV;
  }

  const getDatatoFullDownload = (rcellItem) => {
    if (rcellItem) {
       clientsList?.filter(
        (item) => item.clientNameId === rcellItem.clientNameId
      );
      return [
        rcellItem?.clientName,
        rcellItem?.appointmentDate,
        rcellItem?.shiftName[0],
        rcellItem?.assigned,
        rcellItem?.complete,
        rcellItem?.pending,
      ];
    } else {
      return [
        rcellItem?.clientName,
        rcellItem?.appointmentDate,
        rcellItem?.shiftName[0],
        rcellItem?.assigned,
        rcellItem?.complete,
        rcellItem?.pending,
      ];
    }
  }

  const downloadForExcel = async (rcellItem, emailSend) => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Shift Report');

    const clientCSV = getDatatoDownload(rcellItem);
    let fileName;
    if (Array.isArray(rcellItem)) {
      fileName = `ShiftReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    } else {
      fileName = `ShiftReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    }
    return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
  }

  const downloadForPDF = async (rcellItem, emailSend) => {
    const clientCSV = getDatatoDownload(rcellItem);
    let fileName;
    if (Array.isArray(rcellItem)) {
      fileName = `ShiftReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    } else {
      fileName = `ShiftReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    }
    return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
  }

  const setDownloadCSVData = (event, done, rcellItem) => {
    const clientCSV = getDatatoDownload(rcellItem);

    const reportName = "Shift Report";
    const clientName = Array.isArray(rcellItem) ? `${rcellItem[0]?.clientName}` : `${rcellItem?.clientName}`;
    const reportPeriod = `${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format("YYYY-MM-DD")}`;
    // Create the report details rows
    const reportDetails = [
      [`Client Name: ${clientName}`],
      [`Report Name: ${reportName}`],
      [`Report Period: ${reportPeriod}`],
      [],
      [...csvHeaders],
      ...clientCSV
    ];

    // Set the CSV data
    setClientListCSV(reportDetails);
    done();
  };


  const downloadForFullPDF = async (emailSend) => {
    const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));
    let clientNamefordwonLoad = 'All'

    if (clientName !== 'All') {
      clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
    }
    let fileName = `ShiftReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
    return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
  }

  const downloadForFullExcel = async (emailSend) => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Shift Report');
    let clientNamefordwonLoad = 'All'

    const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));

    if (clientName !== 'All') {
      clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
    }
    let fileName = `ShiftReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
    return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
  }

  const handleEmailIconClick = async (rcellItem) => {
    const emailSend = true;
    const excelBlob = await downloadForExcel(rcellItem, emailSend);
    let name;
    if (Array.isArray(rcellItem)) {
      name = rcellItem[0]?.clientName;
    } else {
      name = rcellItem?.clientName;
    }
    localStorage.setItem("FileName", "ShiftReport");

    localStorage.setItem("ReportsName", "Client Report");

    localStorage.setItem("Name", name);

    localStorage.setItem("Date", startDate);

    localStorage.setItem("EndDate", endDate);

    const pdfBlob = await downloadForPDF(rcellItem, emailSend);
    localStorage.setItem("pdfData", pdfBlob);
    setIsLoading(true);

    // Convert the Excel blob to a base64-encoded string
    const reader = new FileReader();
    reader.readAsDataURL(excelBlob);
    reader.onloadend = function () {
      const base64Data = reader.result;
      localStorage.setItem("emailData", base64Data);

      // Navigate to the email reports page
      navigate("/emailreports");
    };
  };

  const handelEmailFullReport = async () => {
    const emailSend = true;

    localStorage.setItem("FileName", "ShiftReport");

    localStorage.setItem("ReportsName", "Client Report");

    let clientNamefordwonLoad = 'All'

    if (clientName !== 'All') {
      clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
    }
    localStorage.setItem("Name", clientNamefordwonLoad);

    localStorage.setItem("Date", startDate);

    localStorage.setItem("EndDate", endDate);

    setIsLoading(true);
    const fullReport = await downloadForFullPDF(emailSend);
    localStorage.setItem("fullReport", fullReport);

    const fullExcelReport = await downloadForFullExcel(emailSend);
    const reader = new FileReader();
    reader.readAsDataURL(fullExcelReport);
    reader.onloadend = function () {
      const base64Data = reader.result;
      localStorage.setItem("fullExcelReport", base64Data);

      navigate("/emailreports");
    }
  }

  const handlePdfClick = async (records, emailSend) => {
    await downloadForPDF(records, emailSend);
  }

  const handleExcelClick = async (records, emailSend) => {
    await downloadForExcel(records, emailSend);
  }

  const handleCSvClick = async (event, done, records) => {
    await setDownloadCSVData(event, done, records);
  };

  const handelEmailClicks = async (records) => {
    await handleEmailIconClick(records);
  }

  const handleShiftNameChange = (e) => {
    const str = e.target.value;
    setShiftName(str);
    setClientList(() =>
      clientsListMaster.filter((itme) => {
        let returnFlg = true;
        if (clientName !== "All") {
          returnFlg = itme.clientNameId === clientName;
        }
        if (str !== "All" && returnFlg) {
          returnFlg = itme.shiftName[0] === str;
        }
        return returnFlg;
      })
    );
  };

  useEffect(() => {
    let paramStartDate = "";
    let paramEndDate = "";

    paramStartDate = startDate;
    paramEndDate = endDate;

    getReportListData(paramStartDate, paramEndDate);
  }, [startDate, endDate]);

  const mapWithClientIds = async (clientIds, timeSheet) => {
    getClientsList(clientIds, (result) => {
      const shiftNames = ["All"];
      const filteredClients = result?.filter((item) =>
        timeSheet.some((sheet) => sheet.clientId === item.id)
      );
      const clients = filteredClients?.map((item) => ({
        value: item.id,
        label: item.clientName,
      }));
      setClientNameList([{ label: "All", value: "All" }, ...clients]);

      const finaleData = timeSheet?.map((resItem) => {
        const userTimeSheet = result?.filter(
          (itm) => itm.id === resItem.clientId
        );

        const taskTitles = Object.values(resItem.tasks).map(
          (task) => task.title
        );
        const task = Object.values(resItem.tasks).map((task) => task.tasksList);
        const completedTask = task[0].filter((task) => task.completed === true);
        if (!shiftNames.includes(taskTitles[0])) {
          shiftNames.push(taskTitles[0]);
        }

        resItem["clientName"] = userTimeSheet[0]?.clientName;
        resItem["clientNameId"] = userTimeSheet[0]?.id;
        resItem["date"] = userTimeSheet[0]?.appointmentDate;
        resItem["shiftName"] = taskTitles;
        resItem["assigned"] = task[0]?.length;
        resItem["complete"] = completedTask.length;
        resItem["pending"] = task[0].length - completedTask.length;
        return resItem;
      });

      setShiftData(() =>
        shiftNames.map((itm) => {
          return { value: itm, label: itm };
        })
      );

      let sortedData = sortingFunction(finaleData);
      setClientsListMaster(sortedData);

      setClientList(() =>
        sortedData?.filter((itme) => {
          let returnFlg = true;
          if (clientName !== "All") {
            returnFlg = itme.clientNameId === clientName;
          }
          if (shiftName !== "All" && returnFlg) {
            returnFlg = itme.shiftName[0] === shiftName;
          }
          return returnFlg;
        })
      );
    });
  };

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return 1;
      // If clientName is the same, sort by appointmentDate
      if (a.appointmentDateTime < b.appointmentDateTime) return -1;
      if (a.appointmentDateTime > b.appointmentDateTime) return 1;
      return 0;
    });

    return result;
  };

  const getReportListData = async (startDt, endDt) => {
    const param = {
      startDate: `${dayjs(startDt).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${dayjs(endDt).format("YYYY-MM-DD")}T23:59:59`
    };

    getTimesheetListReport(
      param,
      (result) => {
        setIsLoading(false);
        const clientIds = [];
        for (let i = 0; i < result.length; i++) {
          if (clientIds.indexOf(result[i].clientId) === -1) {
            clientIds.push(result[i].clientId);
          }
        }

        mapWithClientIds(clientIds, result);
      },
      (err) => {
        setIsLoading(false);
        console.log("err>>", err);
      }
    );
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex,) => {
    switch (hcellItem.id) {
      case "clientName":
        if (
          rowindex !== 0 &&
          clientsList[rowindex - 1].clientName !== rcellItem.clientName
        ) {
          return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        } else if (rowindex === 0) {
          return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        } else {
          return "";
        }
      case "Action":
        if (isMobile || isLandscape) {
          if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return null;
          }
        } else {
          if (
            rowindex !== 0 &&
            clientsList[rowindex - 1].clientName !== rcellItem.clientName
          ) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return "";
          }
        }
      default:
        return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
    }
  };


  const tableRightComponent = () => {
    return (
      <div className="d-flex w320 editEmp issueAlign ph-8">
        <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
        <div className="reportsMargin mv-7">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              className="customDate customDatePicker"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                if (newValue) {
                  setEndDate(moment());
                } else {
                  setEndDate(null);
                }
              }}
              maxDate={moment()}
              views={['year', 'month', 'day']}
              inputFormat="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </div>
        <div className="reportsMargin mv-7">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="End Date"
              className="customDate  customDatePicker"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                if (!newValue) {
                  setStartDate(null);
                }
              }}
              maxDate={moment()}
              views={['year', 'month', 'day']}
              inputFormat="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </div>
        <div className="reportsMargin mv-7">
          <SingleSelect
            value={clientName}
            className={`field reportsSingleSelect`}
            name={"clientName"}
            label={"Client Name"}
            onChange={(e) => handleclientNameChange(e)}
            options={clientNameList}
          />
        </div>
        <div className="reportsMargin mv-7">
          <SingleSelect
            value={shiftName}
            className={`field reportsSingleSelect`}
            name={"shiftName"}
            label={"Shift Name"}
            onChange={handleShiftNameChange}
            options={shiftData}
          />
        </div>
      </div>
    );
  };


  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Shift Reports"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Reports" },
            { label: "Client Reports", to: "/reports/clientreport" }
          ]}
          BreadCrumbCurrentPageTitle={"Shift Reports"}
          showLastSeen={true}
        ></PageTitle>

        <div className={`sectionBox2`}>
          {!detailedView && (
            <TableToolbar
              rightComponent={tableRightComponent()}
            />
          )}
          <ResponsiveTable
            rows={clientsList}
            getColumns={getColumns}
            hidePagination={true}
            isLoading={isLoading}
            renderRowCell={renderRowCell}
            detailedView={detailedView}
            setDetailedView={setDetailedView}
            fileName={"Shift Report"}
            onClickPdf={handlePdfClick}
            onClickExcel={handleExcelClick}
            onClickCsv={handleCSvClick}
            clientsListCSV={clientsListCSV}
            onClickEmail={handelEmailClicks}
          />
        </div>
        {!detailedView && (
          <div className={styles.report}>
            <div className={styles.download1}>
              <EmailRoundedIcon />
              <div>
                <Link to={clientsList.length !== 0 && "/emailreports"} rel="noopener" >
                  <Button
                    className={styles.actions}
                    disabled={clientsList.length === 0}
                    onClick={() => {
                      handelEmailFullReport()
                    }}
                  >
                    Full Report
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.download1}>
              <PictureAsPdfIcon />
              <div>
                <Button
                  className={styles.actions}
                  disabled={clientsList.length === 0}
                  onClick={() => {
                    downloadForFullPDF();
                  }}
                >
                  Download Full Report
                </Button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default Shiftreport;
