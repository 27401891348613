/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@material-ui/core';
import SingleSelect from '../../components/SelectField/SingleSelect';
import { disableTimeSlots, formatTime24hrsOr12hrs, generateOptionsList, generateTimes, isTimeInRange, numberOptions, timeToMinutes, ValidTime } from '../../services/helpers';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    selectinput: {
        padding: "15px 10px"
    },
    selectlabel: {
        transform: "translate(14px, 15px) scale(1)"
    },
    selectoption: {
        margin: 0
    }
}));

const DateAndTimeSelector = ({ DateTimeProps }) => {
    const {
        empNames, selectedDate, setSelectedDate, startTime, setStartTime, endTime, setEndTime, frequencyData, setFrequencyData, numberOfTime, setNumberOfTime, isInputVisible,
        setInputVisible, timeVisible, setTimeVisible, errors, setErrors, appointmentType, manualDate, setManualDate, handleAddTime, setRecurringDates, handleCancelTime,
        filterAppointmentOnTheDate, selectedMonth, selectedDay, selectedYear, setSelectedYear, setSelectedDay, setSelectedMonth, daysInMonth, setDaysInMonth, editAppointmentData, settingsData
    } = DateTimeProps;

    const intervalStartString = settingsData?.startTimeInterval || "30 min";
    const intervalStartTime = parseInt(intervalStartString.split(" ")[0], 10);
    const startTimes = generateTimes(intervalStartTime);
    const disabledStartTimeOptions = settingsData?.standardWorkTime === "yes" ?  disableTimeSlots(startTimes) : disableTimeSlots(startTimes, settingsData?.startTime, settingsData?.endTime);

    const intervalEndString = settingsData?.endTimeInterval || "30 min";
    const intervalEndTime = parseInt(intervalEndString.split(" ")[0], 10);
    const endTimes = generateTimes(intervalEndTime);
    const disableEndTimeOptions = settingsData?.standardWorkTime === "yes" ?  disableTimeSlots(endTimes) : disableTimeSlots(endTimes, settingsData?.startTime, settingsData?.endTime);

    const optionsList = generateOptionsList(selectedDate || manualDate);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const classes = useStyles();
    const endTimeRef = useRef(null);

    const handleLinkClick = () => {
        setInputVisible(!isInputVisible);
        if (!isInputVisible) {
            setSelectedDate(null);
        } else {
            setManualDate(null);
        }
    };

    const handleTimeLinkClick = () => {
        setTimeVisible(!timeVisible);
    };

    const minutesToTime = (minutes) => {
        const hour = Math.floor(minutes / 60) % 12 || 12;
        const minute = minutes % 60;
        const period = minutes < 720 ? "AM" : "PM";
        return `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
    };
    const normalizeTime = (time) => {
        const [hour, rest] = time.trim().split(":");
        const normalizedHour = parseInt(hour, 10);
        return `${normalizedHour}:${rest.toLowerCase()}`;
    };
    
    const scrollToSuggestedEndTime = (suggestedTime) => {
        if (endTimeRef.current) {
            const items = endTimeRef.current.querySelectorAll("label");
            const normalizedSuggestedTime = normalizeTime(suggestedTime);
            const target = Array.from(items).find((item) => {
                const itemText = normalizeTime(item.textContent);
                return itemText === normalizedSuggestedTime
            });
            if (target) {
                target.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.error("Target not found in list");
            }
        }
    };

    const handleStartTimeChange = (event) => {
        const selectedStartTime = event.target.value;
        setStartTime(selectedStartTime);
        setRecurringDates([]);

        const startMinutes = timeToMinutes(selectedStartTime);
        const suggestedEndTime = minutesToTime(startMinutes + 30);
        scrollToSuggestedEndTime(suggestedEndTime);
    };

    const months = [
       "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 51 }, (_, i) => currentYear + i);

    const getDaysInMonth = (month) => {
        const date = new Date(currentYear, month + 1, 0);
        const dates = [];
        for (let i = 1; i <= date.getDate(); i++) {
            dates.push(i.toString().padStart(2, '0'));
        }
        return dates;
    };


    const getDayOfWeek = (date, month, year) => {
        if (!year) {
            year = new Date().getFullYear();
        }
        const dateObject = new Date(year, month, date);
        const dayOfWeek = dateObject.getDay();
        const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        return dayNames[dayOfWeek];
    };

    const manualDateSet = (selectedMonth, selectedDay, selectedYear) => {
        if (selectedMonth !== null && selectedDay !== null && selectedYear !== null) {
            const monthIndex = selectedMonth;
            const formattedDate = dayjs(
                `${selectedYear}-${(monthIndex + 1).toString().padStart(2, "0")}-${selectedDay.toString().padStart(2, "0")}`
            );
            setManualDate(formattedDate);
            filterAppointmentOnTheDate(formattedDate);
        }
    }

    const handleMonthChange = (e) => {
        const month = months.indexOf(e.target.value);
        setSelectedMonth(month);
        const days = getDaysInMonth(month);
        setDaysInMonth(days);
        setSelectedDay(null);
    };

    const handleDateChange = (e) => {
        setSelectedDay(e.target.value);
        setSelectedYear(null);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        manualDateSet(selectedMonth, selectedDay, e.target.value);
    };
    
    const checkInTime = editAppointmentData?.checkInDateTime?.seconds 
    ? moment.unix(editAppointmentData.checkInDateTime.seconds).tz(editAppointmentData?.apoointmentDetails?.calendarTimezone).format("hh:mm a") : null;
    
    const checkOutTime = editAppointmentData?.checkOutDateTime?.seconds 
    ? moment.unix(editAppointmentData.checkOutDateTime.seconds).tz(editAppointmentData?.apoointmentDetails?.calendarTimezone).format("hh:mm a") : null;

    return (
        <div>
            <div className={`${isMobile || isLandscape ? "d-flex flex-direction-column" : "d-flex w-100 mt-10"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className={`${isMobile || isLandscape ? "d-flex flex-direction-column" : "d-flex"}`}>
                        {isInputVisible ? (
                            <div>
                                <div className='pb-20'>
                                    <Link className="link fright" onClick={handleLinkClick}>Calender View</Link>
                                </div>
                                <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}
                                    className={isMobile || isLandscape ? " " : "mh-5"}>
                                    Enter Date
                                </Typography>
                                <div className='pt-8'>
                                <div className='d-flex'>
                                        <div>
                                            <select
                                                value={selectedMonth !== null ? months[selectedMonth] : ""}
                                                onChange={handleMonthChange}
                                                disabled={!!checkInTime || !!checkOutTime || empNames === "All"}
                                                style={{ height: "40px", width: "120px", appearance: "none"}}
                                            >          <option value="" disabled>Select Month</option>
                                                {months.map((month, index) => (
                                                    <option key={index} value={month}>{month}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <select
                                                value={selectedDay || ""}
                                                onChange={handleDateChange}
                                                disabled={!!checkInTime || !!checkOutTime || selectedMonth === null}
                                                style={{ height: "40px", marginLeft: "10px", width: "120px", appearance: "none"}}
                                            > <option value="" disabled>Select Day</option>
                                                {daysInMonth?.map((date) => {
                                                      const dayOfWeek = getDayOfWeek(date, selectedMonth, selectedYear);
                                                      const disabledDays = settingsData?.workWeek;
                                                      const isDisabled = !disabledDays.includes(dayOfWeek); 
                                                    return(
                                                        <option key={date} value={date} disabled={isDisabled}>{date}</option>
                                                    )})}
                                            </select>
                                        </div>

                                        <div>
                                            <select
                                                value={selectedYear || ""}
                                                onChange={handleYearChange}
                                                disabled={!!checkInTime || !!checkOutTime || selectedDay === null}
                                                style={{ height: "40px", marginLeft: "10px", width: "120px", appearance: "none"}}
                                            > <option value="" disabled>Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                        </div>      
                                        {errors.manualDate && <p style={{ color: 'red' }}>Choose One</p>}
                                </div>
                            </div>
                        ) : <div>
                            <div className='pb-20'>
                                <Link className="link fright" onClick={handleLinkClick}>Set Manually</Link>
                            </div>
                            <div className="calendarHeader">
                                <span>Select Date</span>
                                <div className='mt-20'>
                                    <Typography variant='body' style={{ fontSize: "25px", fontWeight: "600" }}>{selectedDate ? selectedDate.format('ddd, MMM D') : "No date selected"}</Typography>
                                </div>
                            </div>
                            <DateCalendar
                                value={selectedDate}
                                onChange={(newDate) => {
                                    setSelectedDate(newDate);
                                    setErrors({ ...errors, date: false });
                                    setRecurringDates([]);
                                    filterAppointmentOnTheDate(newDate);

                                    if (!selectedDate) {
                                        setStartTime('');
                                    }
                                }}
                                disablePast
                                sx={{
                                    height: '300px',
                                    width: "380px",
                                    justifyContent: "space-between"
                                }}
                                views={['year', 'month', 'day']}
                                openTo="day"
                                disabled={!!checkInTime || !!checkOutTime || empNames === "All"}
                                shouldDisableDate={(date)=>{
                                    const disabledDays = settingsData?.workWeek;
                                    const dayOfWeek = date?.format('dddd').toLowerCase();
                                    return !disabledDays?.includes(dayOfWeek);
                                }}
                            />
                            {errors.date && <p style={{ color: 'red' }}>Choose One</p>}
                        </div>
                        }
                        {(isMobile || isLandscape) ? (
                            <>
                                {timeVisible ? (
                                    <div>
                                        <div className='pb-20 pt-6'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Calender View</Link>
                                        </div>
                                        <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                            Enter Start Time
                                        </Typography>
                                        <div className='pt-6 pb-20'>
                                            <input
                                                type="text"
                                                value={startTime || ""}
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                            
                                                    const isValidFormat = ValidTime(inputValue);
                                                    const isInRange = isValidFormat && isTimeInRange(inputValue, settingsData?.startTime, settingsData?.endTime);
                                            
                                                    setStartTime(inputValue);
                                            
                                                    if (!isValidFormat) {
                                                        setErrors({ ...errors, startTime: "Invalid Time" });
                                                    } else if (!isInRange) {
                                                        setErrors({ ...errors, startTime: "Time is out of range" });
                                                    } else {
                                                        setErrors({ ...errors, startTime: false });
                                                    }
                                            
                                                    if (inputValue === '') {
                                                        setEndTime('');
                                                    }
                                                }}
                                                placeholder="StartTime (HH:MM AM/PM)"
                                                disabled={(checkInTime || checkOutTime) ? true : (!selectedDate && !manualDate)}
                                                style={{
                                                    width: isMobile || isLandscape ? "400px" : "170px",
                                                    height: isMobile || isLandscape ? "45px" : "40px"
                                                }}
                                            />
                                            {errors.startTime && <p style={{ color: 'red' }}>{errors.startTime}</p>}
                                        </div>
                                        <div className='pb-10'>
                                            <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                Enter End Time
                                            </Typography>
                                        </div>
                                        <div className='pb-8'>
                                            <input
                                                type="text"
                                                value={endTime || ""}
                                                onChange={(event) => {
                                                    setEndTime(event.target.value);
                                                    setRecurringDates([]);
                                                    if (ValidTime(event.target.value)) {
                                                        setErrors({ ...errors, endTime: false });
                                                    } else {
                                                        setErrors({ ...errors, endTime: true });
                                                    }
                                                    if (endTime === '') {
                                                        setFrequencyData()
                                                    }
                                                }}
                                                style={{
                                                    width: isMobile || isLandscape ? "400px" : "170px",
                                                    height: isMobile || isLandscape ? "45px" : "40px"
                                                }}
                                                placeholder="EndTime (HH:MM AM/PM)"
                                                disabled={!ValidTime(startTime)}
                                            />
                                            {errors.endTime && <p style={{ color: 'red' }}>Choose One</p>}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='w-100 pv-10'>
                                        <Link className="link fright" onClick={handleTimeLinkClick}>Set Manually</Link>
                                    </div>
                                )}
                                {!timeVisible ?
                                    <div className='mt-15'>
                                        <div className='mb-10'>
                                            <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                Select Start Time
                                            </Typography>
                                            <div className='mt-10'>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel className={classes.selectlabel}>Start Time</InputLabel>
                                                    <Select
                                                        value={startTime}
                                                        onChange={(event) => {
                                                            setStartTime(event.target.value);
                                                            setRecurringDates([]);
                                                            setErrors({ ...errors, startTime: false });
                                                            if (startTime === '') {
                                                                setEndTime('');
                                                            }
                                                        }}
                                                        label="Start Time"
                                                        inputProps={{ className: classes.selectinput }}
                                                        disabled={(checkInTime || checkOutTime) ? true : (!selectedDate && !manualDate)}
                                                    >
                                                        {disabledStartTimeOptions?.map((slot, index) => {
                                                            const formattedTime = formatTime24hrsOr12hrs(slot.time, settingsData?.timeFormat);
                                                            return (
                                                                <MenuItem key={index} value={slot.time} disabled={slot.disabled}>
                                                                    {formattedTime}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                {errors.startTime && <p style={{ color: 'red' }}>Choose One</p>}
                                            </div>
                                        </div>
                                        <div className='mb-10'>
                                            <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                Select End Time
                                            </Typography>
                                            <div className='mt-10'>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel className={classes.selectlabel}>End Time</InputLabel>
                                                    <Select
                                                        value={endTime}
                                                        className='selectPadding'
                                                        onChange={(event) => {
                                                            setEndTime(event.target.value);
                                                            setRecurringDates([]);
                                                            setErrors({ ...errors, endTime: false });
                                                            if (endTime === '') {
                                                                setFrequencyData();
                                                            }
                                                        }}
                                                        label="End Time"
                                                        inputProps={{ className: classes.selectinput }}
                                                        disabled={!ValidTime(startTime)}
                                                    >
                                                        {disableEndTimeOptions?.map((slot, index) => {
                                                            const formattedTime = formatTime24hrsOr12hrs(slot.time, settingsData?.timeFormat);
                                                            const endMinutes = timeToMinutes(slot.time);
                                                            const startMinutes = timeToMinutes(startTime);
                                                            const isDisabled = !startTime || endMinutes <= startMinutes || slot.time === startTime;

                                                            return (
                                                                <MenuItem key={index} value={slot.time} disabled={slot.disabled || isDisabled}>
                                                                    {formattedTime}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                {errors.endTime && <p style={{ color: 'red' }}>Choose One</p>}
                                            </div>
                                        </div>
                                    </div> : null}
                            </>
                        ) : (
                            <>
                                {timeVisible ? (
                                    <div className='ph-10'>
                                        <div className='pb-20'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Calender View</Link>
                                        </div>
                                        <div className='d-flex flex-center'>
                                            <div className='ph-10'>
                                                <div>
                                                    <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                        Enter Start Time
                                                    </Typography>
                                                </div>
                                                <div className='pt-8'>
                                                    <input
                                                        type="text"
                                                        value={startTime || ""}
                                                        onChange={(event) => {
                                                            const inputValue = event.target.value;
                                                    
                                                            const isValidFormat = ValidTime(inputValue);
                                                            const isInRange = isValidFormat && isTimeInRange(inputValue, settingsData?.startTime, settingsData?.endTime);
                                                    
                                                            setStartTime(inputValue);
                                                            setRecurringDates([]);
                                                            if (!isValidFormat) {
                                                                setErrors({ ...errors, startTime: true, text:"Invalid Time" });
                                                            } else if (!isInRange) {
                                                                setErrors({ ...errors, startTime: true, text:"Time is out of range"  });
                                                            } else {
                                                                setErrors({ ...errors, startTime: false });
                                                            }
                                                    
                                                            if (inputValue === '') {
                                                                setEndTime('');
                                                            }
                                                        }}
                                                        disabled={(checkInTime || checkOutTime) ? true : (!selectedDate && !manualDate)}
                                                        placeholder="StartTime (HH:MM AM/PM)"
                                                        style={{
                                                            width: isMobile || isLandscape ? "400px" : "170px",
                                                            height: isMobile || isLandscape ? "45px" : "40px"
                                                        }}
                                                    />
                                                    {errors.startTime && <p style={{ color: 'red' }}>{errors.text}</p>}
                                                </div>
                                            </div>
                                            <div className='ph-10'>
                                                <div>
                                                    <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                        Enter End Time
                                                    </Typography>
                                                </div>
                                                <div className='pt-8'>
                                                    <input
                                                        type="text"
                                                        value={endTime || ""}
                                                        onChange={(event) => {
                                                            setEndTime(event.target.value);
                                                            setRecurringDates([]);
                                                            if (endTime === '') {
                                                                setFrequencyData()
                                                            }
                                                            if (ValidTime(event.target.value)) {
                                                                setErrors({ ...errors, endTime: false });
                                                            } else {
                                                                setErrors({ ...errors, endTime: true });
                                                            }
                                                        }}
                                                        disabled={!ValidTime(startTime)}
                                                        placeholder="EndTime (HH:MM AM/PM)"
                                                        style={{
                                                            width: isMobile || isLandscape ? "400px" : "170px",
                                                            height: isMobile || isLandscape ? "45px" : "40px"
                                                        }}
                                                    />
                                                    {errors.endTime && <p style={{ color: 'red' }}>Choose One</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div className='d-flex flex-direction-column flex-justify-center' style={{ marginTop: "-20px" }}>
                                        <div className='mr-40'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Set Manually</Link>
                                        </div>
                                        <Box className="d-flex ph-10">
                                            <fieldset className="mh-20" style={{ border: "0.10px solid #d2d2d2", borderRadius: "5px" }}>
                                                <legend style={{ color: "#6c6c6c", fontWeight: "600" }}>Start Time</legend>
                                                <RadioGroup
                                                    name="start-time"
                                                    className='radiotimes'
                                                    value={startTime}
                                                    onChange={handleStartTimeChange}
                                                >
                                                    {disabledStartTimeOptions?.map((slot, index) => {
                                                        const formattedTime = formatTime24hrsOr12hrs(slot.time, settingsData?.timeFormat);
                                                        return (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={slot.time}
                                                                control={<Radio color='primary' disabled={slot.disabled || (checkInTime || checkOutTime) ? true : (!selectedDate && !manualDate)} />}
                                                                label={formattedTime}
                                                            />
                                                        )
                                                    })}
                                                </RadioGroup>
                                                {errors.startTime && <p style={{ color: 'red' }}>Choose One</p>}
                                            </fieldset>
                                            <fieldset className="mh-20" style={{ border: "0.10px solid #d2d2d2", borderRadius: "5px" }}>
                                                <legend style={{ color: "#6c6c6c", fontWeight: "600" }}>End Time</legend>
                                                <RadioGroup
                                                    ref={endTimeRef}
                                                    name="end-time"
                                                    className='radiotimes'
                                                    value={endTime}
                                                    onChange={(event) => {
                                                        setEndTime(event.target.value);
                                                        setRecurringDates([]);
                                                        if (endTime === '') {
                                                            setFrequencyData()
                                                        }
                                                        setErrors({ ...errors, endTime: false });
                                                    }}
                                                >
                                                    {disableEndTimeOptions?.map((slot, index) => {
                                                        const formattedTime = formatTime24hrsOr12hrs(slot.time, settingsData?.timeFormat);
                                                        const endMinutes = timeToMinutes(slot.time);
                                                        const startMinutes = timeToMinutes(startTime);
                                                        const isDisabled = !startTime || endMinutes <= startMinutes || slot.time === startTime;

                                                        return (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={slot.time}
                                                                control={<Radio color='primary' disabled={slot.disabled ||isDisabled} />}
                                                                label={formattedTime}
                                                            />
                                                        );
                                                    })}
                                                </RadioGroup>
                                                {errors.endTime && <p style={{ color: 'red' }}>Choose One</p>}
                                            </fieldset>
                                        </Box>
                                    </div>
                                )}
                            </>
                        )}
                    </Box>
                </LocalizationProvider>
                <div className="w-25">
                    {appointmentType === "Recurring Appointment" && (
                        <>
                            <div>
                                <Typography
                                    variant="body"
                                    component="label"
                                    htmlFor="time-picker"
                                    style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}
                                >
                                    Select Frequency
                                </Typography>
                                <div className="mt-10">
                                    <SingleSelect
                                        name="selectfrequency"
                                        label="Select Frequency"
                                        value={frequencyData}
                                        onChange={(event) => {
                                            setFrequencyData(event.target.value);
                                            setRecurringDates([]);
                                            setErrors({ ...errors, frequency: false });
                                            if (!frequencyData) {
                                                setNumberOfTime()
                                            }
                                        }}
                                        disabled={!endTime}
                                        options={optionsList}
                                        datetime={true}
                                    />
                                    {errors.frequency && <p style={{ color: 'red' }}>Choose One</p>}
                                </div>
                            </div>

                            <div className='mv-20'>
                                <Typography
                                    variant="body"
                                    component="label"
                                    htmlFor="time-picker"
                                    style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}
                                >
                                    Select Number Of Times
                                </Typography>
                                <div className="mt-10">
                                    <SingleSelect
                                        name="numberoftimes"
                                        label="Number Of Times"
                                        value={numberOfTime}
                                        onChange={(event) => {
                                            setNumberOfTime(event.target.value);
                                            setRecurringDates([]);
                                            setErrors({ ...errors, numberOfTimes: false });
                                        }}
                                        type="number"
                                        options={numberOptions}
                                        datetime={true}
                                        disabled={!frequencyData}
                                    />
                                    {errors.numberOfTimes && <p style={{ color: 'red' }}>Choose number 2 or greater than number 2</p>}
                                </div>
                            </div>


                            <div
                                className='mt-25'
                                style={{
                                    display: (isMobile || isLandscape) ? "flex" : "",
                                    justifyContent: (isMobile || isLandscape) ? "center" : ""
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleAddTime}
                                    style={{ marginRight: '10px', backgroundColor: '#10bf63', color: 'white', boxShadow: 'none' }}
                                    disabled={!numberOfTime}
                                >
                                    Add Time
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleCancelTime}
                                    style={{ backgroundColor: '#7f7f7f', color: "white", boxShadow: 'none' }}
                                    disabled={!numberOfTime}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DateAndTimeSelector;