import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { firebase } from "../firebase-config";
import { COLLECTIONS } from "../config";

export async function saveSettings(formData, collectionName) {
    const auth = getAuth();
    try {
        const currentUser = auth.currentUser;
        const currentUserId = currentUser ? currentUser.uid : null;
        if (!currentUser) {
            throw new Error("User is not authenticated.");
        }
        const saveData = {
            ...formData,
            createdAt: serverTimestamp(),
            createdBy: currentUserId,
        }
        const docRef = await addDoc(collection(firebase, collectionName), saveData);
        return { status: true, id: docRef.id };
    } catch (error) {
        console.error("Error saving settings:", error);
        return { status: false, error: error.message };
    }
}


export async function updateSettings(formData, collectionName, docId) {
    const auth = getAuth();
    try {
        const currentUser = auth.currentUser;
        const currentUserId = currentUser ? currentUser.uid : null;

        if (!currentUser) {
            throw new Error("User is not authenticated.");
        }

        const saveData = {
            ...formData,
            updatedAt: serverTimestamp(),
            updatedBy: currentUserId,
        };
        const docRef = doc(firebase, collectionName, docId);
        await setDoc(docRef, saveData, { merge: true });

        return { status: true, id: docRef.id };
    } catch (error) {
        console.error("Error saving settings:", error);
        return { status: false, error: error.message };
    }
}


export async function getSettings(collectionName, onSuccess, onError) {
    try {
        const colRef = collection(firebase, collectionName);

        const querySnapshot = await getDocs(colRef);

        if (querySnapshot.empty) {
            onSuccess && onSuccess({ status: true, data: [] });
        } else {
            const settings = [];
            querySnapshot.forEach(doc => {
                settings.push({ id: doc.id, ...doc.data() });
            });
            onSuccess && onSuccess({ status: true, data: settings });
        }
    } catch (error) {
        onError && onError({ status: false, error });
        onSuccess && onSuccess({ status: true, data: [] });
    }
}


export async function resetSettings(collectionName, docId) {
    const auth = getAuth();
    try {
        const currentUser = auth.currentUser;
        const currentUserId = currentUser ? currentUser.uid : null;

        if (!currentUser) {
            throw new Error("User is not authenticated.");
        }

        const saveData = {
            deleted: true,
            deletedAt: serverTimestamp(),
            deletedBy: currentUserId,
        };

        const docRef = doc(firebase, collectionName, docId);

        await setDoc(docRef, saveData, { merge: true });

        return { status: true, id: docRef.id };
    } catch (error) {
        console.error("Error performing soft delete:", error);
        return { status: false, error: error.message };
    }
}

export async function getSuppliesCompanyCategories(onSuccess, onError) {
    const colRef = collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY);
  
    try {
      const querySnapshot = await getDocs(colRef);
      const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      if (onSuccess) {
        onSuccess(results);
      }
    } catch (error) {
      console.log('Error getting documents: ', error);
      if (onError) {
        onError(error);
      }
    }
}

export async function saveOrUpdateSuppliesCompanyCategories(formDataArray) {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        throw new Error("User is not authenticated.");
    }

    const currentUserId = currentUser.uid;
    const operations = formDataArray.map(async (formData) => {
        const docRef = doc(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, formData.id);
        
        try {
            const docSnap = await getDoc(docRef);
            const saveData = {
                ...formData,
                createdAt: serverTimestamp(),
                createdBy: currentUserId,
            };

            if (docSnap.exists()) {
                await updateDoc(docRef, saveData);
                return { status: true, id: formData.id, message: `Document ${formData.id} updated.` };
            } else {
                await setDoc(docRef, saveData);
                return { status: true, id: formData.id, message: `New document ${formData.id} added.` };
            }
        } catch (error) {
            console.error(`Error processing document ${formData.id}:`, error);
            return { status: false, id: formData.id, error: error.message };
        }
    });

    const results = await Promise.all(operations);
    return { status: true, results };
}

export async function resetSuppliesCompanyCategory(companyId, onSuccess, onError) {
    try {
        const colRef = collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY);
        const q = query(colRef, where("companyId", "==", companyId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            onError && onError({ status: false, error: "No Settings found" });
            return { status: false, error: "No Settings found" };
        }

        const updatePromises = [];
        const updatedSettings = [];

        querySnapshot.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data.deleted) {
                const docRef = doc(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, docSnapshot.id);
                updatePromises.push(updateDoc(docRef, { deleted: true }));
                updatedSettings.push({ id: docSnapshot.id, ...data, deleted: true });
            }
        });

        await Promise.all(updatePromises);

        onSuccess && onSuccess({ status: true, data: updatedSettings });
        return { status: true, data: updatedSettings };

    } catch (error) {
        onError && onError({ status: false, error: error.message });
        return { status: false, error: error.message };
    }
}

export async function getProductDetails(id, onSuccess, onError) {
    try {
        const productDocRef = doc(firebase, COLLECTIONS.STRIPE_PRODUCTS, id);

        const docSnapshot = await getDoc(productDocRef);

        if (!docSnapshot.exists()) {
            onError && onError({ status: false, error: "Product not found" });
        } else {
            const data = docSnapshot.data();
            onSuccess && onSuccess({ status: true, data: { id: docSnapshot.id, ...data } });
        }
    } catch (error) {
        onError && onError({ status: false, error: error });
    }
}

export async function saveSuppliesIndustry(formDataArray, categoryArray) {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        throw new Error("User is not authenticated.");
    }

    const currentUserId = currentUser.uid;
    const batch = writeBatch(firebase);

    const updatedCategories = categoryArray.map((category) => {
        const newCategoryRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY)); 
        const categoryData = {
            ...category,
            id: newCategoryRef.id,
            industry: true,
            createdAt: serverTimestamp(),
            createdBy: currentUserId,
        };

        batch.set(newCategoryRef, categoryData);
        return { ...category, id: newCategoryRef.id };
    });

    formDataArray.forEach((formData, index) => {
        const newSupplyRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY));
        const assignedCategory = updatedCategories[index % updatedCategories.length];
        const supplyData = {
            ...formData,
            id: newSupplyRef.id,
            industry: true,
            parentCategoryId: assignedCategory.id,
            createdAt: serverTimestamp(),
            createdBy: currentUserId,
        };

        batch.set(newSupplyRef, supplyData);
    });

    try {
        await batch.commit();

        return { 
            status: true, 
            message: "All records saved successfully", 
            totalRecords: updatedCategories.length + formDataArray.length,
            categories: updatedCategories 
        };
    } catch (error) {
        console.error("Error saving data:", error);
        return { status: false, error: error.message };
    }
}

export async function getServicesDetails(companyId, onSuccess, onError) {
    try {
        const serviceDocRef = collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY);

        const q = query(serviceDocRef, 
            where("industry", "==", true), 
            where("companyId", "==", companyId)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            onError && onError({ status: false, error: "No matching services found" });
            return { status: false, error: "No matching services found" };
        } else {
            const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            onSuccess && onSuccess({ status: true, data });
            return { status: true, data };
        }
    } catch (error) {
        onError && onError({ status: false, error: error.message });
        return { status: false, error: error.message };
    }
}

export async function saveOrUpdateSuppliesIndustry(formDataArray, categoryArray) {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        throw new Error("User is not authenticated.");
    }

    const currentUserId = currentUser.uid;
    const batch = writeBatch(firebase);

    try {
        const categoryIds = categoryArray.map(cat => cat.id).filter(id => id);
        const supplyIds = formDataArray.map(sup => sup.id).filter(id => id);

        const existingCategories = await getExistingDocs(COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, categoryIds);
        const existingSupplies = await getExistingDocs(COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, supplyIds);

        const updatedCategories = [];
        const categoryMap = {};

        categoryArray.forEach(category => {
            let categoryRef;
            if (existingCategories[category.id]) {
                categoryRef = doc(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, category.id);
                batch.update(categoryRef, { ...category, updatedAt: serverTimestamp(), updatedBy: currentUserId });
            } else {
                categoryRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY));
                const newCategory = { 
                    ...category, 
                    id: categoryRef.id,
                    industry: true, 
                    createdAt: serverTimestamp(), 
                    createdBy: currentUserId 
                };
                batch.set(categoryRef, newCategory);
            }
            categoryMap[category.id] = categoryRef.id;
            updatedCategories.push({ ...category, id: categoryRef.id });
        });

        formDataArray.forEach(formData => {
            let supplyRef;
            if (existingSupplies[formData.id]) {
                supplyRef = doc(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, formData.id);
                batch.update(supplyRef, { 
                    ...formData, 
                    parentCategoryId: categoryMap[formData.parentCategoryId] || formData.parentCategoryId,
                    updatedAt: serverTimestamp(), 
                    updatedBy: currentUserId 
                });
            } else {
                const parentCategoryId = categoryMap[formData.parentCategoryId] || formData.parentCategoryId;

                if (existingCategories[parentCategoryId] || categoryMap[parentCategoryId]) {
                    supplyRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY));
                    const newSupply = { 
                        ...formData, 
                        id: supplyRef.id,
                        industry: true, 
                        parentCategoryId: parentCategoryId,
                        createdAt: serverTimestamp(), 
                        createdBy: currentUserId 
                    };
                    batch.set(supplyRef, newSupply);
                } else {
                    console.warn(`Skipping supply ${formData.title} because parent category does not exist.`);
                }
            }
        });

        await batch.commit();

        return { 
            status: true, 
            message: "Categories and supplies updated successfully", 
            totalRecords: updatedCategories.length + formDataArray.length,
            categories: updatedCategories 
        };
    } catch (error) {
        console.error("Error saving data:", error);
        return { status: false, error: error.message };
    }
}

/**
 * Helper function to get existing Firestore documents
 */
async function getExistingDocs(collectionPath, docIds) {
    if (docIds.length === 0) return {};

    const docRef = collection(firebase, collectionPath);
    const q = query(docRef, where("id", "in", docIds));
    const querySnapshot = await getDocs(q);

    const existingDocs = {};
    querySnapshot.forEach(doc => {
        existingDocs[doc.id] = doc.data();
    });

    return existingDocs;
}

export async function resetSuppliesIndustry(companyId, onSuccess, onError) {
    try {
        const colRef = collection(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY);
        const q = query(colRef, 
            where("industry", "==", true), 
            where("companyId", "==", companyId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            onError && onError({ status: false, error: "No Settings found" });
            return { status: false, error: "No Settings found" };
        }

        const updatePromises = [];
        const updatedSettings = [];

        querySnapshot.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data.deleted) {
                const docRef = doc(firebase, COLLECTIONS.SUPPLIES_COMPANY_CATEGORY, docSnapshot.id);
                updatePromises.push(updateDoc(docRef, { deleted: true }));
                updatedSettings.push({ id: docSnapshot.id, ...data, deleted: true });
            }
        });

        await Promise.all(updatePromises);

        onSuccess && onSuccess({ status: true, data: updatedSettings });
        return { status: true, data: updatedSettings };

    } catch (error) {
        onError && onError({ status: false, error: error.message });
        return { status: false, error: error.message };
    }
}