/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import CommonContext from "../../../hooks/commonContext";
import { Badge, Container, IconButton} from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./suppliesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getSupplyOrderList, UpdateMsgFalse } from "../../../services/apiservices/supplies";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { useNavigate } from "react-router";
import { FormatedDates, groupByKey } from "../../../services/helpers";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import AuthContext from "../../../hooks/authContext";
import { useMediaQuery } from "@mui/material";
import SupplyCommonCard from "../../../components/ResponsiveCardComponent/SupplyComponent/SupplyListPageResponsive";
import {CustomTooltip, SuppplyDataDialog} from "../../../components/DialogComponent/SupplyDataDialog";

const SuppliesManage = (props) => {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const auth = useContext(AuthContext);
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [allOrderStatus, setAllOrderStatus] = React.useState([]);
  const [allClientNames, setAllClientNames] = React.useState([]);
  const [selectedPriority, setSelectedPriority] = useState("all");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("any");
  const [selectedClientNames, setSelectedClientNames] = useState("any");
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = useState(null);
  const [openSupplyDialog, setOpenSupplyDialog] = useState(false);
  const [openData, setOpenData] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [recPerPage, setRecordsPerPage] = React.useState(10);
  const [filter, setFilterValues] = React.useState({
    priority: "all",
    orderStatus: "any",
    clientName: "any",
  });

  const checkFilteredList = () => {
    if (allSupplies) {
      let filteredSuppliesList = allSupplies?.filter((row) => {
        let searchFilter = true,
          filterorderStatus = true,
          filterpriority = true,
          filterclientName = true;
        
        if (filter?.priority !== "" && filter?.priority !== "all") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }
        if (filter?.orderStatus !== "" && filter?.orderStatus !== "any") {
          filterorderStatus =
            String(row.orderStatus).toLowerCase() ===
            String(filter.orderStatus).toLowerCase();
        }
        if (filter?.clientName !== "" && filter?.clientName !== "any") {
          filterclientName =
            String(row.clientName).toLowerCase() ===
            String(filter.clientName).toLowerCase();
        }
        return searchFilter && filterpriority && filterorderStatus && filterclientName;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allSupplies, filter]);

  const manageDataList = (newRows) => {

    let allorderStatus = groupByKey(newRows, "orderStatus");
    let allOrderStatus = Object.keys(allorderStatus)?.map((aacitem) => {
      return { label: (aacitem), value: aacitem };
    });
    let finalSetAllOrderStatus = allOrderStatus ? allOrderStatus : [];
    setAllOrderStatus([...finalSetAllOrderStatus]);

    let allclientName = groupByKey(newRows, "clientName");
    let allClientName = Object.keys(allclientName)?.map((aacitem) => {
      return { label: (aacitem), value: aacitem };
    });
    let finalSetAllClientName = allClientName ? allClientName : [];
    setAllClientNames([...finalSetAllClientName]);
  };

  const fetchSuppliesList = async () => {
    setIsLoading(true);
    await getSupplyOrderList(
      {},
      async (res) => {
        if (res) {
          const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
          let records = res.filter(order => order.companyId === companyId && order.status !== "Pending" && order.status !== "Rejected");
          let filterRecords = records.filter(order => order.status !== "Delivered" || (order.msg === true && !order.archive));
          if (filterRecords.length === 0) {
            auth.resetHighPrioritySupply();
          }
          let clientIds = records.map((ordItem) => ordItem.clientId);
          clientIds = [...new Set(clientIds)]

          let empIds = records.map((orderItem) => orderItem.createdBy);
          empIds = [...new Set(empIds)]

          let clintsData = [];
          if (clientIds.length > 0) {
            // Use Promise to handle async function call
            clintsData = await new Promise((resolve, reject) => {
              getClientsListbasedonIds(clientIds, (result) => {
                const clients = result.map((item) => ({
                  value: item?.id,
                  label: item?.clientName,
                }));
                resolve(clients);
              }, (error) => {
                console.error('Error fetching clients:', error); // Handle errors here
                reject(error);
              });
            });
          }

          let employeeData = [];
          if (empIds.length > 0) {
            await getEmployeesListBasedOnId(empIds, (result) => {
              const employees = result.map((item) => {
                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}`, status: item.status };
              });
              employeeData = employees;
            });
          }
          let newRows = records.map((ritem) => {
            let totalQuantity = 0;

            const client = clintsData.find(client => client?.value === ritem?.clientId);
            const clientName = client ? client.label : 'Unknown Client';

            const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
            const employeeName = employee ? employee.label : 'Unknown employee';

            if (ritem.supplyItems) {
              ritem.supplyItems.forEach((item) => {
                totalQuantity += item.qty || 0;
              });
            }
            return {
              ...ritem,
              orderId: String(ritem.orderNumber).padStart(6, '0'),
              supplies: `(${totalQuantity})`,
              employeeName: employeeName,
              clientName: clientName,
              orderStatus: ritem.status,
              employeeData: employeeData
            };
          });

          newRows = newRows.filter(item => item.archive === false || !item.archive);

          let sortedData = sortingFunction(newRows);
          await manageDataList(sortedData);
          await setAllSupplies(sortedData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (resError) => {
        console.error("reserror", resError);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchSuppliesList();
  }, []);


  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.orderId < b.orderId) return -1;
      if (a.orderId > b.orderId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const tableLeftComponent = () => {
    return (
      <div className="d-flex flex-center">
      </div>
    );
  };

  const priorityOptions = [
    { label: "All", value: "all" },
    { label: "High", value: "1" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "3" }
  ];

  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setSelectedPriority(value);
    setFilterValues(prev => ({ ...prev, priority: value }));
  };

  const handleOrderStatusChange = (e) => {
    const value = e.target.value;
    setSelectedOrderStatus(value);
    setFilterValues(prev => ({ ...prev, orderStatus: value }));
  };

  const handleClientNameChange = (e) => {
    const value = e.target.value;
    setSelectedClientNames(value);
    setFilterValues(prev => ({ ...prev, clientName: value }));
  };


  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center w320 editEmp issueAlign pr-2">
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Priority"
            value={selectedPriority}
            className={`field w-60`}
            name={"Priority"}
            onChange={handlePriorityChange}
            options={priorityOptions}
          />
        </div>
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Client Name"
            value={selectedClientNames}
            className={`field w90 `}
            name={"Client Name"}
            onChange={handleClientNameChange}
            options={
              allClientNames
                ? [{ label: "Any", value: "any" }, ...allClientNames]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Order Status"
            value={selectedOrderStatus}
            className={`field w90 `}
            name={"Order Status"}
            onChange={handleOrderStatusChange}
            options={
              allOrderStatus
                ? [{ label: "Any", value: "any" }, ...allOrderStatus]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
      </div>
    );
  };

  const onHandelClickFunction = async (id) => {
    if (!id) {
      return;
    }
    try {
      await UpdateMsgFalse({ id });
      await redirectToDetails(id);
    } catch (error) {
      console.error("Error in onHandelClickFunction:", error);
    }
  };
  
  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/supplies/cleaning/manage/" + id);
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case "1":
        return "High";
      case "2":
        return "Medium";
      case "3":
        return "Low";
      default:
        return "Unknown";
    }
  };

  const getDeliveryStatus = (supplyItems) => {
    if (!supplyItems || !Array.isArray(supplyItems)) {
      return 'Invalid Supplies';
    }

    const statuses = supplyItems.map(item => item.status);

    const hasDelivered = statuses.includes('Delivered');
    const hasShipped = statuses.includes('Shipped');
    const hasOrdered = statuses.includes('Ordered');
    const hasBackOrdered = statuses.includes('Back Ordered');
    const hasCancelItem = statuses.includes('Cancel Item');

    if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
      return 'Full Delivery';
    }
    if (statuses.every(status => status === 'Cancel Item')) {
      return 'cancelled';
    }
    if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
      return 'Partial Delivery';
    }
    if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
      return 'Full Shipment';
    }
    if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
      return 'Partial Shipment';
    }
    if (hasOrdered && hasCancelItem) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Ordered')) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Back Ordered')) {
      return 'Back Ordered';
    }
    if (hasOrdered && hasBackOrdered) {
      return 'In Process';
    }
    return 'In Process';
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    let boldclasss = rcellItem?.msg === true ? "fw-bold" : "";
    switch (hcellItem.id) {
      case "employeeName":
        const data = rcellItem?.employeeData?.find((item)=> item?.value === rcellItem?.createdBy)
        return (
            <p className={boldclasss} style={{color : data?.status === false ? "#9fa6b3" : ""}}>{rcellItem.employeeName}</p>
        );
      case "supplies":
        return (
          <>
            <CustomTooltip
              listData={rcellItem?.supplyItems}
              title={`${rcellItem?.supplies}`}
            />
          </>
        )
      case "dateOfRequest":
        return (
          <p className={boldclasss}>{FormatedDates(rcellItem?.dateOfRequest)}</p>
        )
      case "deliverStatus":
        const deliverStatus = getDeliveryStatus(rcellItem?.supplyItems)
        return (
          <p className={boldclasss}>
            {deliverStatus !== "Full Delivery" ? (
              <span><Badge color="secondary" variant="dot" className="supplyBadge" /> {deliverStatus}</span>
            ) : (deliverStatus)}
          </p>
        )
      case "priority":
        return (
          <p className={boldclasss}>{getPriorityLabel(rcellItem?.priority)}</p>
        )
      case "action":
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => onHandelClickFunction(rcellItem?.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        );

      default:
        return (
          <p className={boldclasss}>{rcellItem[hcellItem?.id]}</p>
        )
    }
  };

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };
  
  const handleDialogSupplyOpen = async (data) => {
    setOpenSupplyDialog(true);
    setOpenData(data);
  };

  const handleDialogSupplyClose = async () => {
    setOpenSupplyDialog(false);
    setOpenData(null);
  };


  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
          BreadCrumbCurrentPageTitle={"Supplies Manage Request"}
        // showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title=""
            leftComponent={tableLeftComponent()}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) &&
            <ThemeTable
              rows={filteredList}
              headCells={Columns}
              hidePagination={false}
              isLoading={isLoading}
              renderRowCell={renderRowCell}
              sorting={true}
            // selectedRows={selectedRows}
            />
          }
          {(isMobile || isLandscape) && (
            <>
              {filteredList.map((record, index) => {
                const isOpen = expanded === record?.id;
                return (
                  <SupplyCommonCard
                    index={index}
                    record={record}
                    isOpen={isOpen}
                    handleExpandClick={handleExpandClick}
                    getPriorityLabel={getPriorityLabel}
                    redirectToDetails={redirectToDetails}
                    handleDialogSupplyOpen={handleDialogSupplyOpen}
                    columns={ColumnsRes}
                  />
                );
              })}
            </>
          )}
        </div>
      </Container>
      <SuppplyDataDialog open={openSupplyDialog} onClose={handleDialogSupplyClose} listData={openData}/>
    </>
  );
};
export default SuppliesManage;
