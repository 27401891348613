import React, { useContext, useEffect, useState } from "react";
import { Button, Container, TextField } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { useNavigate, useParams } from "react-router";
import { getAllEmployee, getEmployee } from "../../../services/apiservices/employee";
import { deleteUsers, saveOrUpdateUserData } from "../../../services/apiservices/users";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import confirm from "../../../components/DialogComponent/confirm";
import { generateP } from "../../../services/helpers";


const CreateAdmin = () => {
  const commonContext = useContext(CommonContext);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const { adminId } = useParams();
  const [empData, setEmpData] = useState();
  const [editData, setEditData] = useState(null);
  const [companyDetails, setCompanyDetails] = useState([]);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const getCompanyDetails = async () => {
    await getEmployee(adminId, (res) => {
      if (res) {
        setEditData(res?.data);
        setFormData({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          company: res.data.companyId
        })
      } else {
        setFormData({})
      }
    })
  }

  useEffect(() => {
    getCompanyDetails();
  }, [adminId]);


  useEffect(() => {
    getAllEmployee((res) => {
      if (res) {
        const data = res?.data?.filter((item) => item.role === "companyUser")
        const companyNames = data.map(res => ({
          value: res?.id,
          label: `${res?.firstName} ${res?.lastName}`,
          name: `${res?.firstName} ${res?.lastName}`,
          firstName: res?.firstName,
          lastName: res?.lastName,
        }));
        setCompanyDetails(companyNames)
        setEmpData(res?.data);
      } else {
        setEmpData();
      }
    });
  }, []);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormData({ ...formData, [name]: value });
    if (error[name]) {
      setError({ ...error, [name]: "" });
    }
  }

  const validFields = () => {
    const { firstName, lastName, email, phoneNumber, company } = formData;

    let errormessages = {};
    let isValid = true;

    if (!firstName || firstName.trim() === "") {
      isValid = false;
      errormessages.firstName = "First Name is Required";
    }
    if (!lastName || lastName.trim() === "") {
      isValid = false;
      errormessages.lastName = "Last Name is Required";
    }
    if (!email || email.trim() === "") {
      isValid = false;
      errormessages.email = "Email is Required";
    }
    if (!phoneNumber || phoneNumber.trim() === "") {
      isValid = false;
      errormessages.phoneNumber = "Phone Number is Required";
    }
    if (auth.userProfile.role === "superUser") {
      if (!company || company.trim() === "") {
        isValid = false;
        errormessages.company = "Company is Required";
      }
    }
    setError(errormessages);
    return isValid;
  };

  const handelSaveData = async () => {
    commonContext?.setLoader(true);
    if (!validFields()) {
      commonContext?.setLoader(false);
      return;
    }
    const determinedCompanyId =
      auth.userProfile.role === "superUser"
        ? formData.company
        : auth.userProfile.role === "companyUser"
          ? auth.userProfile.id
          : auth.userProfile.companyId;

    const password = generateP();
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      companyId: determinedCompanyId,
      password: password,
      confirmPassword: password,
      role: "admin",
    }

    const newData = await saveOrUpdateUserData(data);

    if (newData.status === true) {
      commonContext?.setLoader(false);
      setFormData({});
      navigate("/userAdmin");
    }
    commonContext?.setLoader(false);
  }

  const handelUpdateData = async () => {
    commonContext?.setLoader(true);
    if (!validFields()) {
      commonContext?.setLoader(false);
      return;
    }
    const determinedCompanyId =
      auth.userProfile.role === "superUser"
        ? formData.company
        : auth.userProfile.role === "companyUser"
          ? auth.userProfile.id
          : auth.userProfile.companyId;

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      companyId: determinedCompanyId,
    }

    const newData = await saveOrUpdateUserData(data, adminId);
    if (newData.status === true) {
      commonContext?.setLoader(false);
      setFormData({});
      navigate("/userAdmin");
    }
    commonContext?.setLoader(false);
  }

  const rundeleteAdmin = async () => {
    try {
      commonContext?.setLoader(true);
      const res = await deleteUsers(adminId);
      if (res?.status === true) {
        commonContext?.setLoader(false);
        setFormData({});
        confirm("Admin is Deleted Successfully", { cancel: false }).then(() => {
          navigate("/userAdmin");
        });
      } else {
        commonContext?.setLoader(false);
        console.error("Failed to delete admin:", res?.error);
      }
    } catch (error) {
      commonContext?.setLoader(false);
      console.error("Unexpected error while deleting admin:", error.message || error);
    }
  };

  const onDeleteAdmin = async () => {
    await confirm(
      "Are you Sure you want to Remove this admin?"
    ).then(() => {
      rundeleteAdmin();
    }, console.log("delete!"));
  };

  const handelCancelData = () => {
    if (adminId) {
      navigate("/userAdmin");
    } else {
      setFormData({});
    }
  }

  return (
    <Container>
      <PageTitle
        pageTitle={"Create Admin"}
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Admin", to: "/userAdmin" },
        ]}
        BreadCrumbCurrentPageTitle={"Create Admin"}
        showLastSeen={true}
      />
      <div className="sectionBox">
        <div className="d-flex flex-center delete flex-space-between p-10">
          <h3 className="sectiontitle">
            {typeof adminId !== "undefined" && adminId !== ""
              ? "Edit Admin"
              : "Create New Admin"}{" "}
            - {`${editData?.firstName ? `${editData?.firstName} ${editData?.lastName}` : "Admin x"}`}{" "}
          </h3>
          {typeof adminId !== "undefined" && adminId !== "" && (
            <Button
              variant="contained"
              className="flatbutton button-danger ph-15"
              onClick={onDeleteAdmin}
            >
              Delete
            </Button>
          )}
        </div>
        <div className={`stepContent w-50`}>
          <div className="stepSectionContent">
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="firstName"
                className="width50"
                id="firstName"
                label="First Name *"
                variant="outlined"
                placeholder="First Name"
                onChange={handleInputChange}
                value={formData?.firstName ? formData?.firstName : ""}
              />
              {typeof error?.firstName !== "undefined" &&
                error?.firstName !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.firstName}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="lastName"
                id="lastName"
                className="width50"
                label="Last Name *"
                variant="outlined"
                placeholder="Last Name"
                onChange={handleInputChange}
                value={formData?.lastName ? formData?.lastName : ""}
              />
              {typeof error?.lastName !== "undefined" &&
                error?.lastName !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.lastName}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="email"
                id="email"
                className="width50"
                label="Email *"
                variant="outlined"
                placeholder="Email"
                onChange={handleInputChange}
                value={formData?.email ? formData?.email : ""}
              />
              {typeof error?.email !== "undefined" &&
                error?.email !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.email}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number *"
                variant="outlined"
                className="width50"
                placeholder="Phone Number"
                onChange={handleInputChange}
                value={formData?.phoneNumber ? formData?.phoneNumber : ""}
              />
              {typeof error?.phoneNumber !== "undefined" &&
                error?.phoneNumber !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.phoneNumber}
                  </span>
                )}
            </div>
            {auth.userProfile.role === "superUser" ?
              <div className="width50 mv-25">
                <SingleSelect
                  label="Company *"
                  variant="outlined"
                  name="company"
                  id="company"
                  placeholder="Company"
                  onChange={handleInputChange}
                  value={formData?.company ? formData?.company : ""}
                  options={companyDetails}
                />
                {typeof error?.company !== "undefined" &&
                  error?.company !== "" && (
                    <span className="d-flex small error color-danger">
                      {error?.company}
                    </span>
                  )}
              </div>
              : null}
          </div>
        </div>
        <div className="mv-15">
          <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={adminId ? handelUpdateData : handelSaveData}>{adminId ? "Update" : "Save"}</Button>
          <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handelCancelData}>Cancel</Button>
        </div>
      </div>
    </Container>
  )
}

export default CreateAdmin;