import React, { useContext, useEffect, useState } from "react";
import { Button, Container, TextField } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useNavigate, useParams } from "react-router";
import { getEmployee } from "../../../services/apiservices/employee";
import { deleteUsers, saveOrUpdateUserData } from "../../../services/apiservices/users";
import CommonContext from "../../../hooks/commonContext";
import confirm from "../../../components/DialogComponent/confirm";
import { generateP } from "../../../services/helpers";


const CreateCompany = () => {
  const commonContext = useContext(CommonContext);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const { companyId } = useParams();

  const getCompanyDetails = async () => {
    await getEmployee(companyId, (res) => {
      if (res) {
        setEditData(res?.data);
        setFormData({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber
        })
      } else {
        setFormData({})
      }
    })
  }

  useEffect(() => {
    getCompanyDetails();
  }, [companyId]);


  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormData({ ...formData, [name]: value });
    if (error[name]) {
      setError({ ...error, [name]: "" });
    }
  }

  const validFields = () => {
    const { firstName, lastName, email, phoneNumber } = formData;

    let errormessages = {};
    let isValid = true;

    if (!firstName || firstName.trim() === "") {
      isValid = false;
      errormessages.firstName = "First Name is Required";
    }
    if (!lastName || lastName.trim() === "") {
      isValid = false;
      errormessages.lastName = "Last Name is Required";
    }
    if (!email || email.trim() === "") {
      isValid = false;
      errormessages.email = "Email is Required";
    }
    if (!phoneNumber || phoneNumber.trim() === "") {
      isValid = false;
      errormessages.phoneNumber = "Phone Number is Required";
    }

    setError(errormessages);
    return isValid;
  };

  const handelSaveData = async () => {
    commonContext?.setLoader(true);
    if (!validFields()) {
      commonContext?.setLoader(false);
      return;
    }
    const password = generateP();
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: password,
      confirmPassword: password,
      role: "companyUser",
    }
    const newData = await saveOrUpdateUserData(data);
    if (newData.status === true) {
      commonContext?.setLoader(false);
      setFormData({});
      navigate("/company");
    }
    commonContext?.setLoader(false);
  }

  const handelUpdateData = async () => {
    commonContext?.setLoader(true);
    if (!validFields()) {
      commonContext?.setLoader(false);
      return;
    }
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber
    }
    const newData = await saveOrUpdateUserData(data, companyId);
    if (newData.status === true) {
      commonContext?.setLoader(false);
      setFormData({});
      navigate("/company");
    }
    commonContext?.setLoader(false);
  }

  const handelCancelData = () => {
    if (companyId) {
      navigate("/company");
    } else {
      setFormData({});
    }
  }

  const rundeleteCompany = async () => {
    try {
      commonContext?.setLoader(true);
      const res = await deleteUsers(companyId);
      if (res?.status === true) {
        commonContext?.setLoader(false);
        setFormData({});
        confirm("Company is Deleted Successfully", { cancel: false }).then(() => {
          navigate("/company");
        });
      } else {
        commonContext?.setLoader(false);
        console.error("Failed to delete company:", res?.error);
      }
    } catch (error) {
      commonContext?.setLoader(false);
      console.error("Unexpected error while deleting company:", error.message || error);
    }
  };
  const onDeleteCompany = async () => {
    await confirm(
      "Are you Sure you want to Remove this company?"
    ).then(() => {
      rundeleteCompany();
    }, console.log("delete!"));
  };

  return (
    <Container>
      <PageTitle
        pageTitle={"Create Company"}
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Company", to: "/company" },
        ]}
        BreadCrumbCurrentPageTitle={"Create Company"}
        showLastSeen={true}
      />
      <div className="sectionBox">
        <div className="d-flex flex-center delete flex-space-between p-10">
          <h3 className="sectiontitle">
            {typeof companyId !== "undefined" && companyId !== ""
              ? "Edit Company"
              : "Create New Company"}{" "}
            - {`${editData?.firstName ? `${editData?.firstName} ${editData?.lastName}` : "Company x"}`}{" "}
          </h3>
          {typeof companyId !== "undefined" && companyId !== "" && (
            <Button
              variant="contained"
              className="flatbutton button-danger ph-15"
              onClick={onDeleteCompany}
            >
              Delete
            </Button>
          )}
        </div>
        <div className={`stepContent w-50`}>
          <div className="stepSectionContent">
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="firstName"
                className="width50"
                id="firstName"
                label="First Name *"
                variant="outlined"
                placeholder="First Name"
                onChange={handleInputChange}
                value={formData?.firstName ? formData?.firstName : ""}
              />
              {typeof error?.firstName !== "undefined" &&
                error?.firstName !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.firstName}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="lastName"
                id="lastName"
                label="Last Name *"
                variant="outlined"
                className="width50"
                placeholder="Last Name"
                onChange={handleInputChange}
                value={formData?.lastName ? formData?.lastName : ""}
              />
              {typeof error?.lastName !== "undefined" &&
                error?.lastName !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.lastName}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="email"
                id="email"
                className="width50"
                label="Email *"
                variant="outlined"
                placeholder="Email"
                onChange={handleInputChange}
                value={formData?.email ? formData?.email : ""}
              />
              {typeof error?.email !== "undefined" &&
                error?.email !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.email}
                  </span>
                )}
            </div>
            <div className="formFieldwrap mv-20">
              <TextField
                size="small"
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number *"
                variant="outlined"
                className="width50"
                placeholder="Phone Number"
                onChange={handleInputChange}
                value={formData?.phoneNumber ? formData?.phoneNumber : ""}
              />
              {typeof error?.phoneNumber !== "undefined" &&
                error?.phoneNumber !== "" && (
                  <span className="d-flex small error color-danger">
                    {error?.phoneNumber}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="mv-15">
          <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={companyId ? handelUpdateData : handelSaveData}>{companyId ? "Update" : "Save"}</Button>
          <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handelCancelData}>Cancel</Button>
        </div>
      </div>
    </Container>
  )
}

export default CreateCompany;