import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, Popover, Typography } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import moment from "moment";

export function SuppplyDataDialog({ open, onClose, listData }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        SUPPLIES
      </Typography>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {Array.isArray(listData) ? (
          <div>
            {listData.map((item, index) => (
              <div key={index} className="mv-10">
                <Typography variant="body" style={{ color: "#606060", fontSize: "16px" }}>{item?.title} ({item?.qty})</Typography>
              </div>
            ))}
          </div>
        ) : (
          <div>{listData}</div>
        )}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessDialog({ open, onClose, Archive }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        SUCCESS
      </Typography>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {`${Archive === true ? 'The order has been UnArchived and is in process. Please review in Manage Supplies.' : 'The order has been approved and is in process. Please review in Manage Supplies.'}`}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessGroupDeleteDialog({ open, onClose, group }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-direction-column">
        <DialogContent className="mb-10 mt-30" style={{ fontSize: "18px", lineHeight: "22px" }}>
          You have successfully deleted the {group?.title} Group
        </DialogContent>
        <DialogActions className="mb-20">
          <Button onClick={onClose} variant="contained" className="ph-35" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export function RejectDailog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-direction-column">
        <DialogContent className="mb-10 mt-30" style={{ fontSize: "18px", lineHeight: "22px" }}>
          The order has been rejected and has been added to Archived Orders.
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" className="ph-35" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export function ConfirmationsDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogTitle">
        Confirm Approval
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">
        Are you sure you want to approve this request?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationsUnArchiveDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogCancelTitle">
        Unarchive
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">
        Are you sure you want to "UnArchive" this item?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationDeleteChannelDialog({ open, onClose, onConfirm, chItem }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogCancelTitle">
        Remove Channel
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">
        Are you Sure you want to permanent remove "{chItem ? chItem['groupName'] : ""}" channel?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function CustomTooltip({ listData, title }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="color-Darkblue"
        style={{
          cursor: "pointer",
        }}
      >{title}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export function SuccessApptDialog({ open, onClose, modal }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        CONFIRMATION
      </Typography>
      <Divider className="mt-10" />
      <DialogContent className="pv-5">
        {modal ? 'Appoinment successfully Updated! Thank You for your prompt scheduling. ' : 'Appoinment successfully Created! Thank You for your prompt scheduling.'}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessDeleteApptDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        CONFIRMATION
      </Typography>
      <Divider className="mt-10" />
      <DialogContent className="pv-5">
        Appoinment successfully Deleted! Thank You for your prompt scheduling.
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationApptDialog({ open, onSingleAppt, onConfirm, modal, auth, update, onClose, client, data }) {
  // const dates = update.map(item => item.date).join(", ");
  const oldClient = client.find(cli => cli.value === auth.clientId);
  const newClient = client.find(cli => cli.value === data.clientId);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div className="d-flex flex-space-between flex-center">
        <Typography variant="body" className="mt-20 dialogTitle">
          CONFIRMATION
        </Typography>
        <IconButton style={{ color: "red", marginRight: "20px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-10" />
      {/* <span>
         This is the series of appointments. Do you want to update the single appointment on{" "}
          <strong style={{ fontWeight: 'bold' }}>{auth.date}</strong>, or the series of appointments on{" "}
         <strong style={{ fontWeight: 'bold' }}>{dates}</strong>?
         </span> */}
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {modal && (
          <div style={{ lineHeight: "1.8", fontFamily: "Arial, sans-serif", fontSize: "1rem" }}>
            <div className="mh-10 mb-15">
              <span style={{ fontWeight: "800", fontSize: "16px" }}>
                You are rescheduling:
              </span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Client Name:</span>
              <span className="updateDialogLabel">{oldClient?.label}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Date:</span>
              <span className="updateDialogLabel">{auth.apoointmentDetails.date}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Start Time:</span>
              <span className="updateDialogLabel">{auth.apoointmentDetails.time}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">End Time:</span>
              <span className="updateDialogLabel">{auth.apoointmentDetails.endTime}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Employee Name:</span>
              <span className="updateDialogLabel">{auth.apoointmentDetails.calendar}</span>
            </div>

            <div style={{ margin: "10px 10px", fontWeight: "bold", fontSize: "1rem" }}>
              To:
            </div>

            <div className="updateDialog">
              <span className="updateDialogName">Client Name:</span>
              <span className="updateDialogLabel">{newClient?.label}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Date:</span>
              <span className="updateDialogLabel">{data.date}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Start Time:</span>
              <span className="updateDialogLabel">{data.time}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">End Time:</span>
              <span className="updateDialogLabel">{data.endTime}</span>
            </div>
            <div className="updateDialog">
              <span className="updateDialogName">Employee Name:</span>
              <span className="updateDialogLabel">{data.calendar}</span>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onSingleAppt} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Update Appointment
        </Button>
        {/* <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          Series Of Appointment
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export function DeleteApptDialog({ open, client, onConfirm, auth, onClose }) {
  const clients = client.find(cli => cli.id === auth.clientId);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-space-between">
        <Typography variant="body" className="mt-20 dialogCancelTitle">
          Cancel Appointment
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-10" />
      <DialogContent className="pv-5">
        <div style={{ lineHeight: "1.8", fontFamily: "Arial, sans-serif", fontSize: "1rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
            Are you sure you want to cancel this appointment
          </span>
          <div className="updateDialog">
            <span className="updateDialogName">Client Name:</span>
            <span className="updateDialogLabel">{clients?.clientName}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Date:</span>
            <span className="updateDialogLabel">{auth.apoointmentDetails.date}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Time:</span>
            <span className={`updateDialogLabel`}>{auth.apoointmentDetails.time} </span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">EndTime:</span>
            <span className={`updateDialogLabel`}>{auth.apoointmentDetails.endTime}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Employee Name:</span>
            <span className="updateDialogLabel">{auth.apoointmentDetails.calendar}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">TimeZone:</span>
            <span className={`updateDialogLabel`}>{auth.apoointmentDetails.calendarTimezone}</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function CancelApptDailog({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogCancelTitle">
        Cancel Appoinment
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">Are You Sure You Want To Cancel This Appoinment ?</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function DragReschduleConfirm({ open, onSingleAppt, auth, onClose, client, data, timeZones, settingsData }) {
  const clients = client.find(cli => cli.id === auth.clientId);
  const oldTimezone = timeZones.find((item) => item.value === auth.calendarTimezone)?.label
  const newTimezone = timeZones.find((item) => item.value === data.calendarTimezone)?.label
  const timeZoneConflict = oldTimezone !== newTimezone;
  console.log("asdfasfd", auth.time)
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div className="d-flex flex-space-between flex-center">
        <Typography variant="body" className="mt-20 dialogTitle">
          CONFIRMATION
        </Typography>
        <IconButton style={{ color: "red", marginRight: "20px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-10" />
      <DialogContent className="pv-5">
        <div style={{ lineHeight: "1.8", fontFamily: "Arial, sans-serif", fontSize: "1rem" }}>
          {timeZoneConflict ?
            <div className="d-flex flex-justify-center mb-15" style={{ fontWeight: "800", fontSize: "16px", color: "red" }}>
              TimeZone Conflict
            </div>
            : null}
          <div className="d-flex mh-10 mb-15">
            <span style={{ fontWeight: "800", fontSize: "16px" }}>
              You are rescheduling:
            </span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Client Name:</span>
            <span className="updateDialogLabel">{clients?.clientName}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Date:</span>
            <span className="updateDialogLabel">{auth.date}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Time:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{settingsData?.timeFormat === '24hrs' ? moment(auth.time, 'h:mm A').format('HH:mm') : auth.time} {" "} {oldTimezone.split(" ")[0]}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">EndTime:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{settingsData?.timeFormat === '24hrs' ? moment(auth.endTime, 'h:mm A').format('HH:mm') : auth.endTime} {" "} {oldTimezone.split(" ")[0]}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Employee Name:</span>
            <span className="updateDialogLabel">{auth.calendar}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">TimeZone:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{oldTimezone}</span>
          </div>

          <div style={{ margin: "10px 10px", fontWeight: "bold", fontSize: "1rem" }}>
            To:
          </div>

          <div className="updateDialog">
            <span className="updateDialogName">Client Name:</span>
            <span className="updateDialogLabel">{clients?.clientName}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Date:</span>
            <span className="updateDialogLabel">{data.date}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Time:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{settingsData?.timeFormat === '24hrs' ? moment(data.time, 'h:mm A').format('HH:mm') : data.time} {" "} {newTimezone.split(" ")[0]}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">EndTime:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{settingsData?.timeFormat === '24hrs' ? moment(data.endTime, 'h:mm A').format('HH:mm') : data.endTime} {" "} {newTimezone.split(" ")[0]}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">Employee Name:</span>
            <span className="updateDialogLabel">{data.calendar}</span>
          </div>
          <div className="updateDialog">
            <span className="updateDialogName">TimeZone:</span>
            <span className={`updateDialogLabel ${timeZoneConflict ? "color-red" : ""}`}>{newTimezone}</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onSingleAppt} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Update Appointment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function TimeZoneConflictsDialog({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogCancelTitle">
        TIMEZONE CONFLICT
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>Alert, you are rescheduling this appointment in a different Time Zone. Do you wish continue?</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "red", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function AlertOpen({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogCancelTitle">
        APPOINTMENT ALERT
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>You cannot reschedule past appointments or those that are not from our system.</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "red", color: "white", borderRadius: "10px" }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function OutsideWorkAlertOpen({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25 dialogCancelTitle">
        APPOINTMENT WORK TIME ALERT
      </Typography>
      <Divider className="mt-15" />
      <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>Appointments cannot be rescheduled outside of standard work hours unless the setting is configured to allow it</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "red", color: "white", borderRadius: "10px" }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ClientAddressUpdate({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-space-between flex-center">
        <Typography variant="body" className="mt-25 dialogTitle">
          Address Update
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-15" />
      <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>Please note that changing this address will update the address for this client across all past, current, and future appointments.</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function SuccessSettingsDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        SUCCESS
      </Typography>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        Your settings have been successfully saved.
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function UpdateSettingsDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        SUCCESS
      </Typography>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        Your settings have been successfully update.
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ResetSettingDialog({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-space-between flex-center">
        <Typography variant="body" className="mt-25 dialogTitle">
          Setting Reset
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-15" />
      <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}> Are you sure you want to reset the settings? </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ResetSettingsSuccessDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20 dialogTitle">
        SUCCESS
      </Typography>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        Your settings have been successfully reset.
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};