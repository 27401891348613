import React from "react";
import { Container, Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle/PageTitle";
import styles from "./Accounts.module.scss";
import TableToolbar from "../../components/Table/TableToolbar";

const cards = [
  { title: "Appointments", description: "Manage appointments", route: "/appointmentPageSetting" },
  { title: "Clients", description: "View and manage clients", route: "/clientPageSetting" },
  { title: "Supplies", description: "Track and order supplies", route: "/suppliePageSetting" },
  { title: "Notifications", description: "Manage notifications", route: "/notificationPageSetting" },
  { title: "Alerts", description: "View alerts and notifications", route: "/" },
  { title: "Issues", description: "Report and resolve issues", route: "/issuePageSetting" },
  { title: "Reports", description: "Generate and view reports", route: "/" },
  { title: "Chat", description: "Access chat system", route: "/" },
  { title: "Help", description: "Access help and support", route: "/" },
  { title: "Dashboard Settings", description: "Dashboard Settings", route: "/" },
];

const Settings = () => {
  const navigate = useNavigate();

  const handleCardClick = (route) => {
    navigate(route);
  };


  return (
    <Container>
      <PageTitle
        pageTitle="Settings"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Accounts" },
        ]}
        BreadCrumbCurrentPageTitle={"Settings"}
        showLastSeen={true}
      />
    <div className="sectionBox">
        <TableToolbar
            title={"Settings Page"}
        />
      <Grid container spacing={6} className={styles.grid}>
        {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => handleCardClick(card.route)}
              className={styles.card}
              >
              <CardContent className="d-flex flex-direction-column">
                <Typography variant="body" className={`${styles.title}`}>
                  {card.title}
                </Typography>
                <Typography variant="body" className={styles.description}>
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
    </Container>
  );
};

export default Settings;
