import { Button, Container, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useNavigate, useParams } from "react-router";
import { deleteUsers, saveOrUpdateUserData } from "../../services/apiservices/users";
import { getEmployee } from "../../services/apiservices/employee";
import CommonContext from "../../hooks/commonContext";
import confirm from "../../components/DialogComponent/confirm";
import { generateP } from "../../services/helpers";


const CreateSuperUser = () => {
    const commonContext = useContext(CommonContext);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const [editData, setEditData] = useState(null);
    const navigate = useNavigate();
    const { superUserId } = useParams();


    const getUserDetails = async () => {
        await getEmployee(superUserId, (res) => {
            if (res) {
                setEditData(res?.data);
                setFormData({
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    phoneNumber: res.data.phoneNumber
                })
            } else {
                setFormData({})
            }
        })
    }

    useEffect(() => {
        getUserDetails();
    }, [superUserId]);


    const handleInputChange = (event) => {
        const {
            target: { name, value },
        } = event;

        setFormData({ ...formData, [name]: value });

        if (error[name]) {
            setError({ ...error, [name]: "" });
        }
    };

    const validFields = () => {
        const { firstName, lastName, email } = formData;

        let errormessages = {};
        let isValid = true;

        if (!firstName || firstName.trim() === "") {
            isValid = false;
            errormessages.firstName = "First Name is Required";
        }
        if (!lastName || lastName.trim() === "") {
            isValid = false;
            errormessages.lastName = "Last Name is Required";
        }
        if (!email || email.trim() === "") {
            isValid = false;
            errormessages.email = "Email is Required";
        }
        setError(errormessages);
        return isValid;
    };

    const handelSaveData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        }
        const password = generateP();
        const data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            password: password,
            confirmPassword: password,
            role: "superUser",
        }
        const newData = await saveOrUpdateUserData(data);
        if (newData?.status === true) {
            commonContext?.setLoader(false);
            setFormData({});
            navigate("/superUser");
        }
        commonContext?.setLoader(false);
    }

    const handelUpdateData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        }
        const data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
        }

        const newData = await saveOrUpdateUserData(data, superUserId);

        if (newData?.status === true) {
            commonContext?.setLoader(false);
            setFormData({});
            navigate("/superUser");
        }
        commonContext?.setLoader(false);
    }

    const handelCancelData = () => {
        if (superUserId) {
            navigate("/superUser");
        } else {
            setFormData({});
        }
    }

    const rundeleteSuperUser = async () => {
        try {
            commonContext?.setLoader(true);
            const res = await deleteUsers(superUserId);
            if (res?.status === true) {
                commonContext?.setLoader(false);
                setFormData({});
                confirm("SuperUser is Deleted Successfully", { cancel: false }).then(() => {
                    navigate("/superUser");
                });
            } else {
                commonContext?.setLoader(false);
                console.error("Failed to delete superUser:", res?.error);
            }
        } catch (error) {
            commonContext?.setLoader(false);
            console.error("Unexpected error while deleting superUser:", error.message || error);
        }
    };

    const onDeleteSuperUser = async () => {
        await confirm(
            "Are you Sure you want to Remove this SuperUser?"
        ).then(() => {
            rundeleteSuperUser();
        }, console.log("delete!"));
    };

    return (
        <Container>
            <PageTitle
                pageTitle={"Create SuperUser"}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "SuperUser", to: "/superUser" },
                ]}
                BreadCrumbCurrentPageTitle={"Create SuperUser"}
                showLastSeen={true}
            />

            <div className="sectionBox">
                <div className="d-flex flex-center delete flex-space-between p-10">
                    <h3 className="sectiontitle">
                        {typeof superUserId !== "undefined" && superUserId !== ""
                            ? "Edit SuperUser"
                            : "Create New SuperUser"}{" "}
                        - {`${editData?.firstName ? `${editData?.firstName} ${editData?.lastName}` : "SuperUser x"}`}{" "}
                    </h3>
                    {typeof superUserId !== "undefined" && superUserId !== "" && (
                        <Button
                            variant="contained"
                            className="flatbutton button-danger ph-15"
                            onClick={onDeleteSuperUser}
                        >
                            Delete
                        </Button>
                    )}
                </div>
                <div className="stepSectionContent">
                    <div className="formFieldwrap mv-20">
                        <TextField
                            size="small"
                            name="firstName"
                            className="width50"
                            id="firstName"
                            label="First Name *"
                            variant="outlined"
                            placeholder="First Name"
                            onChange={handleInputChange}
                            value={formData?.firstName ? formData?.firstName : ""}
                        />
                        {typeof error?.firstName !== "undefined" &&
                            error?.firstName !== "" && (
                                <span className="d-flex small error color-danger">
                                    {error?.firstName}
                                </span>
                            )}
                    </div>
                    <div className="formFieldwrap mv-20">
                        <TextField
                            size="small"
                            name="lastName"
                            id="lastName"
                            label="Last Name *"
                            variant="outlined"
                            className="width50"
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            value={formData?.lastName ? formData?.lastName : ""}
                        />
                        {typeof error?.lastName !== "undefined" &&
                            error?.lastName !== "" && (
                                <span className="d-flex small error color-danger">
                                    {error?.lastName}
                                </span>
                            )}
                    </div>
                    <div className="formFieldwrap mv-20">
                        <TextField
                            size="small"
                            name="email"
                            id="email"
                            className="width50"
                            label="Email *"
                            variant="outlined"
                            placeholder="Email"
                            onChange={handleInputChange}
                            value={formData?.email ? formData?.email : ""}
                        />
                        {typeof error?.email !== "undefined" &&
                            error?.email !== "" && (
                                <span className="d-flex small error color-danger">
                                    {error?.email}
                                </span>
                            )}
                    </div>
                    <div className="formFieldwrap mv-20">
                        <TextField
                            size="small"
                            name="phoneNumber"
                            id="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            className="width50"
                            placeholder="Phone Number"
                            onChange={handleInputChange}
                            value={formData?.phoneNumber ? formData?.phoneNumber : ""}
                        />
                    </div>
                </div>
                <div className="mv-15">
                    <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={superUserId ? handelUpdateData : handelSaveData}>{superUserId ? "Update" : "Save"}</Button>
                    <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handelCancelData}>Cancel</Button>
                </div>
            </div>
        </Container>
    )
}

export default CreateSuperUser;