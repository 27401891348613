import React, { useContext, useEffect, useState } from "react";
import { Button, Container, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import { formatTime, generateTimes } from "../../../services/helpers";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../Accounts.module.scss";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useMediaQuery } from "@mui/material";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import { getSettings, resetSettings, saveSettings, updateSettings } from "../../../services/apiservices/settings";
import { ResetSettingDialog, ResetSettingsSuccessDialog, SuccessSettingsDialog, UpdateSettingsDialog } from "../../../components/DialogComponent/SupplyDataDialog";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    selectinput: {
        padding: "10px 10px"
    },
    selectlabel: {
        transform: "translate(14px, 15px) scale(1)"
    },
    selectoption: {
        margin: 0
    }
}));

const AllowWorkOutsideWorkWeek = ({ isMobile, isLandscape, workType, setWorkType, setWorkWeek, weekdays }) => {

    const handleToggle = (type) => {
        setWorkType(type);

        if (type === "Disabled") {
            setWorkWeek((prevWorkWeek) =>
                prevWorkWeek.filter((day) =>
                    weekdays.some((weekday) => weekday.value === day)
                )
            );
        }
    };

    return (
        <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
            <Button
                variant={workType === 'Enabled' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('Enabled')}
                style={{
                    backgroundColor:
                        workType === 'Enabled' ? '#27ae60' : '#E0E0E0',
                    color: workType === 'Enabled' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '5px 0 0 5px',
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    border: 0,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Enabled
            </Button>
            <Button
                variant={
                    workType === 'Disabled' ? 'contained' : 'outlined'
                }
                onClick={() => handleToggle('Disabled')}
                style={{
                    backgroundColor:
                        workType === 'Disabled' ? '#27ae60' : '#E0E0E0',
                    color: workType === 'Disabled' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '0 5px 5px 0',
                    border: 0,
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Disabled
            </Button>
        </div>
    );
};

const AppointmentPageSetting = () => {
    const auth = useContext(AuthContext);
    const commonContext = useContext(CommonContext);
    const [schedulingSystem, setSchedulingSystem] = useState("proteams");
    const schedulingSytemsOptions = [
        { label: "Proteams.io", value: "proteams" },
        { label: "Acuity", value: "acuity" }
    ];
    const [workWeek, setWorkWeek] = useState([]);
    const [workType, setWorkType] = useState('Enabled');
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const timeOptions = generateTimes();
    const [standardWorkTime, setStandardWorkTime] = useState();
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [startTimeInterval, setStartTimeInterval] = useState();
    const [endTimeInterval, setEndTimeInterval] = useState();
    const [earlyCheckIn, setEarlyCheckIn] = useState();
    const [timeFormat, setTimeFormat] = useState();
    const [error, setError] = useState({});
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
    const navigate = useNavigate();
    const classes = useStyles();

    const workWeeks = [
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednsday", value: "wednesday" },
        { label: "ThursDay", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" }
    ];

    const weekdays = workWeeks.slice(0, 5);
    const filteredWorkWeeks = workType === "Enabled" ? workWeeks : weekdays;

    const standardWorkTimes = [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" }
    ];

    const timeIntervalOptions = [
        { label: "5 Min", value: "5 min" },
        { label: "10 Min", value: "10 min" },
        { label: "15 Min", value: "15 min" },
        { label: "20 Min", value: "20 min" },
        { label: "30 Min", value: "30 min" },
    ];

    const earlyCheckInOptions = [
        { label: "Not Allowed", value: "notAllowed" },
        { label: "5 Min", value: "5 min" },
        { label: "10 Min", value: "10 min" },
        { label: "15 Min", value: "15 min" },
        { label: "30 Min", value: "30 min" },
    ];

    const TimeFormatsOptions = [
        { label: "12 Hrs", value: "12hrs" },
        { label: "24 Hrs", value: "24hrs" }
    ];

    const resetAll = () => {
        setData();
        setSchedulingSystem("proteams");
        setWorkType('Enabled');
        setWorkWeek([]);
        setStartTime('');
        setEndTime('');
        setStandardWorkTime();
        setStartTimeInterval();
        setEndTimeInterval();
        setEarlyCheckIn();
        setTimeFormat();
        setError({});
    }

    const fetchSettings = () => {
        getSettings('appointment_settings', (res) => {
            const companyId = auth?.userProfile?.role === "companyUser"
                ? auth?.userProfile?.id
                : auth?.userProfile?.companyId;

            if (res?.data) {
                const filteredData = res.data.filter(
                    (item) => item.companyId === companyId && !item.deleted
                );

                if (filteredData.length > 0) {
                    const [firstItem] = filteredData;
                    setData(filteredData);
                    setSchedulingSystem(firstItem?.schedulingSystem);
                    setWorkType(firstItem?.workType);
                    setWorkWeek(firstItem?.workWeek);
                    setStartTime(formatTime(firstItem?.startTime));
                    setEndTime(formatTime(firstItem?.endTime));
                    setStandardWorkTime(firstItem?.standardWorkTime);
                    setStartTimeInterval(firstItem?.startTimeInterval);
                    setEndTimeInterval(firstItem?.endTimeInterval);
                    setEarlyCheckIn(firstItem?.earlyCheckIn);
                    setTimeFormat(firstItem?.timeFormat);
                } else {
                    resetAll();
                }
            } else {
                resetAll();
            }
        });
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const validFields = () => {
        const errorMessages = {};
        let isValid = true;

        const fields = {
            schedulingSystem: "Scheduling System is Required",
            workType: "Work Type is Required",
            workWeek: "Work Week is Required",
            startTime: "Start Time is Required",
            endTime: "End Time is Required",
            standardWorkTime: "Standard Work Time is Required",
            startTimeInterval: "Start Time Interval is Required",
            endTimeInterval: "End Time Interval is Required",
            earlyCheckIn: "Early Check-In is Required",
            timeFormat: "Time Format is Required",
        };

        Object.entries(fields).forEach(([key, errorMessage]) => {
            if (!eval(key) || (key === "workWeek" && !workWeek.length)) {
                isValid = false;
                errorMessages[key] = errorMessage;
            }
        });

        setError(errorMessages);
        return isValid;
    };


    const handelSchedulingSystem = (e) => {
        const system = e.target.value;
        setSchedulingSystem(system);
    };

    const handelChangeWorkWeek = (e) => {
        setWorkWeek(e);
    };

    const handleSaveData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

            const dataToSave = {
                schedulingSystem: schedulingSystem,
                workType: workType,
                workWeek: workWeek,
                startTime: startTime,
                endTime: endTime,
                standardWorkTime: standardWorkTime,
                startTimeInterval: startTimeInterval,
                endTimeInterval: endTimeInterval,
                earlyCheckIn: earlyCheckIn,
                timeFormat: timeFormat,
                companyId: companyId
            };

            const data = await saveSettings(dataToSave, 'appointment_settings');
            if (data.status === true) {
                commonContext?.setLoader(false);
                setOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };

    const handleUpdateData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const dataToSave = {
                schedulingSystem: schedulingSystem,
                workType: workType,
                workWeek: workWeek,
                startTime: startTime,
                endTime: endTime,
                standardWorkTime: standardWorkTime,
                startTimeInterval: startTimeInterval,
                endTimeInterval: endTimeInterval,
                earlyCheckIn: earlyCheckIn,
                timeFormat: timeFormat,
            };
            const id = data[0]?.id;

            const details = await updateSettings(dataToSave, 'appointment_settings', id);
            if (details.status === true) {
                commonContext?.setLoader(false);
                setUpdateOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };

    const handleResetConfirm = async () => {
        setResetOpen(false);
        commonContext?.setLoader(true);
        const id = data[0]?.id;
        const details = await resetSettings('appointment_settings', id);
        if (details.status === true) {
            commonContext?.setLoader(false);
            setResetSuccessOpen(true);
        }
        commonContext?.setLoader(false);
    }

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleResetSuccessClose = () => {
        setResetSuccessOpen(false);
        fetchSettings();
    }

    const handleColse = () => {
        setOpen(false);
        navigate("/settings");
    }

    const handleUpdateClose = () => {
        setUpdateOpen(false);
        navigate("/settings");
    }

    const handleReset = () => {
        if (data) {
            setResetOpen(true);
        } else {
            resetAll();
        }
    };

    const handleCancel = () => {
        if (data) {
            navigate("/settings");
        } else {
            resetAll();
        }
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle={`Appointment Settings`}
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Settings", to: "/settings" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Appointment"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                        <div className={styles.widths}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Scheduling System</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={schedulingSystem}
                                        name={"schedulingSystem"}
                                        label={"Scheduling System"}
                                        onChange={(e) => handelSchedulingSystem(e)}
                                        options={schedulingSytemsOptions}
                                    />
                                    {error.schedulingSystem && (
                                        <span className="d-flex small error color-danger">{error.schedulingSystem}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Allow Work Outside Week</div>
                                <AllowWorkOutsideWorkWeek
                                    isMobile={isMobile}
                                    isLandscape={isLandscape}
                                    workType={workType}
                                    setWorkType={setWorkType}
                                    styles={styles}
                                    setWorkWeek={setWorkWeek}
                                    weekdays={weekdays}
                                />
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Work Week</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={workWeek}
                                        name={"workweek"}
                                        label={"Work Week"}
                                        onChange={(e) => handelChangeWorkWeek(e)}
                                        options={filteredWorkWeeks}
                                    />
                                    {error.workWeek && (
                                        <span className="d-flex small error color-danger">{error.workWeek}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Standard Start Time</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel className={classes.selectlabel}>Standard Start Time</InputLabel>
                                        <Select
                                            value={startTime}
                                            onChange={(event) => {
                                                setStartTime(event.target.value);
                                            }}
                                            label="Standard Start Time"
                                            inputProps={{ className: classes.selectinput }}
                                        >
                                            {timeOptions.map((time, index) => (
                                                <MenuItem key={index} value={time}>
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {error.startTime && (
                                        <span className="d-flex small error color-danger">{error.startTime}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> Standard End Time </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel className={classes.selectlabel}>Standard End Time</InputLabel>
                                        <Select
                                            value={endTime}
                                            onChange={(event) => {
                                                setEndTime(event.target.value);
                                            }}
                                            label="Standard End Time"
                                            inputProps={{ className: classes.selectinput }}
                                        >
                                            {timeOptions.map((time, index) => (
                                                <MenuItem key={index} value={time}>
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {error.endTime && (
                                        <span className="d-flex small error color-danger">{error.endTime}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${isMobile ? `${styles.widths}` : `${styles.widths} ml-20`}`}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> OutSide Standard Work Time </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={standardWorkTime}
                                        name={"standardworktime"}
                                        label={"OutSide Standard Work Time"}
                                        onChange={(e) => setStandardWorkTime(e.target.value)}
                                        options={standardWorkTimes}
                                    />
                                    {error.standardWorkTime && (
                                        <span className="d-flex small error color-danger">{error.standardWorkTime}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> Start Time Interval </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={startTimeInterval}
                                        name={"starttimeinterval"}
                                        label={"Start Time Interval"}
                                        onChange={(e) => setStartTimeInterval(e.target.value)}
                                        options={timeIntervalOptions}
                                    />
                                    {error.startTimeInterval && (
                                        <span className="d-flex small error color-danger">{error.startTimeInterval}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> End Time Interval </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={endTimeInterval}
                                        name={"endtimeinterval"}
                                        label={"End Time Interval"}
                                        onChange={(e) => setEndTimeInterval(e.target.value)}
                                        options={timeIntervalOptions}
                                    />
                                    {error.endTimeInterval && (
                                        <span className="d-flex small error color-danger">{error.endTimeInterval}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> Early Check In </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={earlyCheckIn}
                                        name={"earlyCheckIn"}
                                        label={"Early Check In"}
                                        onChange={(e) => setEarlyCheckIn(e.target.value)}
                                        options={earlyCheckInOptions}
                                    />
                                    {error.earlyCheckIn && (
                                        <span className="d-flex small error color-danger">{error.earlyCheckIn}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}> Time Formats </div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={timeFormat}
                                        name={"timeFormat"}
                                        label={"Time Formats"}
                                        onChange={(e) => setTimeFormat(e.target.value)}
                                        options={TimeFormatsOptions}
                                    />
                                    {error.timeFormat && (
                                        <span className="d-flex small error color-danger">{error.timeFormat}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mv-15">
                        <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={data ? handleUpdateData : handleSaveData}>{data ? "Update" : "Save"}</Button>
                        <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                    </div>
                </div>
            </Container>
            {open && <SuccessSettingsDialog open={open} onClose={handleColse} />}
            {updateOpen && <UpdateSettingsDialog open={updateOpen} onClose={handleUpdateClose} />}
            {resetOpen && <ResetSettingDialog open={resetOpen} onConfirm={handleResetConfirm} onClose={handleResetClose} />}
            {resetSuccessOpen && <ResetSettingsSuccessDialog open={resetSuccessOpen} onClose={handleResetSuccessClose} />}
        </>
    );
}

export default AppointmentPageSetting;