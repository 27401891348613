import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AuthContext from '../../../hooks/authContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CommonContext from '../../../hooks/commonContext';
import { getOneAppointment, updateAppointments } from '../../../services/apiservices/appoinments';
import { AlertOpen, DragReschduleConfirm, OutsideWorkAlertOpen, TimeZoneConflictsDialog } from '../../../components/DialogComponent/SupplyDataDialog';
import { calculateDurationInMinutes, filterAppointments, formatSelectedDateTime } from '../../../services/helpers';
import { Switch } from '@material-ui/core';


const ITEM_TYPE = "APPOINTMENT";

const DraggableAppointment = ({
    index,
    filteredAppointments,
    appointment,
    client,
    boxHeight,
    setIsModalOpen,
    setSelectedAppointment,
    settingsData
}) => {
    const [, drag] = useDrag({
        type: ITEM_TYPE,
        item: { appointment },
    });

    return (
        <div
            ref={drag}
            className="appointmentView"
            style={{
                height: `${boxHeight}px`,
                width: `calc(100% / ${filteredAppointments.length})`,
                left: `${(index % filteredAppointments.length) * (100 / filteredAppointments.length)}%`,
            }}
            onClick={() => {
                setIsModalOpen(true);
                setSelectedAppointment(appointment);
            }}
        >
            <div className="appointmentView-title">{client?.clientName}</div>
            <div className="appointmentView-details2 mv-10">
                {appointment.apoointmentDetails.forms[0]?.values[1]?.value} - {appointment.apoointmentDetails.forms[0]?.values[2]?.value}
            </div>
            <div className="appointmentView-details mv-10">{appointment.apoointmentDetails.shiftName}</div>
            <div className="appointmentView-details2 mv-10">
                {settingsData?.timeFormat === '24hrs' ? moment(appointment.apoointmentDetails.time, 'h:mm A').format('HH:mm') : appointment.apoointmentDetails.time} - {settingsData?.timeFormat === '24hrs' ? moment(appointment.apoointmentDetails.endTime, 'h:mm A').format('HH:mm') : appointment.apoointmentDetails.endTime}
            </div>
        </div>
    );
};

const DroppableTimeSlot = ({ hour, day, employee, onDrop, children }) => {
    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        drop: (item) => {
            const { appointment } = item;
            onDrop(appointment, hour, day, employee);
        },
    });

    return <div ref={drop} className="time-slot">{children}</div>;
};

const DayView = ({ allDays, clientDetails, empNames, employeeDetails, ManageAppointmentsData, clientName, settingsData }) => {
    // const timeSlots = Array.from({ length: 13 }, (_, i) => i + 7);
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];
    const auth = useContext(AuthContext);
    const appointmentData = auth.appointmentsData;
    const setIsModalOpen = auth.setIsModalOpen;
    const setSelectedAppointment = auth.setSelectedAppointment;
    const [widths, setWidths] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);
    const [timeZoneDialog, setTimeZoneDialog] = useState(false);
    const [showEarlySlots, setShowEarlySlots] = useState(false);
    const [showLateSlots, setShowLateSlots] = useState(false);
    const [showEarlyAppt, setShowEarlyAppt] = useState(false);
    const [showLateAppt, setShowLateAppt] = useState(false);
    const [outsideWorkAlert, setOutsideWorkAlert] = useState(false);
    const [selectData, setSelectData] = useState();
    const [appointmentsDataState, setAppointmentsDataState] = useState();
    const commonContext = useContext(CommonContext);

    const formatTime = (hour) => {
        if (settingsData?.timeFormat === '24hrs') {
            return `${hour}`;
        } else {
            const ampm = hour >= 12 ? 'pm' : 'am';
            const formattedHour = hour % 12 || 12;
            return `${formattedHour}${ampm}`;
        }
    };

    function convertTo24Hour(timeString) {
        const [time, modifier] = timeString.split(" ");
        let [hours] = time.split(":");

        const normalizedModifier = modifier.toUpperCase();

        hours = parseInt(hours);

        if (normalizedModifier === "PM" && hours !== 12) {
            hours += 12;
        }
        if (normalizedModifier === "AM" && hours === 12) {
            hours = 0;
        }

        return hours;
    }

    const standardStartHour = convertTo24Hour(settingsData?.startTime);
    const standardEndHour = convertTo24Hour(settingsData?.endTime);

    const generateTimeSlots = () => {
        const slots = [];

        if (showEarlySlots && !showLateSlots) {
            for (let i = 1; i <= standardEndHour; i++) {
                slots.push(i);
            }
        }

        if (!showEarlySlots && !showLateSlots) {
            for (let i = standardStartHour; i <= standardEndHour; i++) {
                slots.push(i);
            }
        }

        if (!showEarlySlots && showLateSlots) {
            for (let i = standardStartHour; i <= 23; i++) {
                slots.push(i);
            }
        }

        if (showEarlySlots && showLateSlots) {
            for (let i = 1; i <= 23; i++) {
                slots.push(i);
            }
        }

        return slots;
    };

    const employeeList = employeeDetails && employeeDetails?.filter((item) => item.role === "Employee")

    const gruopData = employeeList ? Object.entries(
        employeeList.reduce((map, employee) => {
            if (!map[employee.timeZone]) {
                map[employee.timeZone] = [];
            }
            map[employee.timeZone].push(employee);
            return map;
        }, {})
    )
        : [];

    useEffect(() => {
        setLoading(true);
        let hasEarlyAppointments = false;
        let hasLateAppointments = false;

        const standardStartTime = moment({ hour: standardStartHour, minute: 0 });
        const standardEndTime = moment({ hour: standardEndHour, minute: 0 });

        const width = gruopData.flatMap(([timeZone, employees]) =>
            employees.map((employee) => {
                const employeeAppointments = appointmentData.filter((item) => {
                    const appointmentTime = moment(item.apoointmentDetails.time, "hh:mm A");
                    const appointmentDate = moment(item.apoointmentDetails.date).format("MMMM DD YYYY");
                    const currentDate = moment(allDays).format("MMMM DD YYYY");

                    if (appointmentDate === currentDate && appointmentTime.isBefore(standardStartTime)) {
                        hasEarlyAppointments = true;
                    }

                    if (appointmentDate === currentDate && appointmentTime.isAfter(standardEndTime)) {
                        hasLateAppointments = true;
                    }

                    return (
                        item.userId === employee.userId &&
                        appointmentDate === currentDate
                    );
                });

                return employeeAppointments.length ? employeeAppointments.length * 300 : 400;
            })
        );

        setWidths(width);
        setShowEarlyAppt(hasEarlyAppointments);
        setShowLateAppt(hasLateAppointments);
        setShowEarlySlots(hasEarlyAppointments);
        setShowLateSlots(hasLateAppointments);
        setLoading(false);
    }, [allDays]);

    useEffect(() => {
        if (widths?.length > 0) {
            setLoading(false);
        }
    }, [widths]);

    const handleDrop = async (appointment, newHour, day, employee) => {
        const inactiveEmp = employeeDetails?.find((emp) => emp?.userId === appointment?.userId);
        const currentDateTime = moment().utc();
        const appointmentDateTime = moment.utc(
            `${appointment?.apoointmentDetails?.date} ${appointment?.apoointmentDetails?.time}`,
            "MMMM DD, YYYY hh:mm A"
        );

        const isDateValid = appointmentDateTime.isSameOrAfter(currentDateTime);
        if (appointment.source !== "proteams" || inactiveEmp.status === false || !isDateValid) {
            setAlertOpen(true);
            return;
        } else {
            const startHour = moment({ hour: newHour });
            const duration = appointment.apoointmentDetails.duration;
            const endHour = startHour.clone().add(duration, "minutes");

            const workStartTime = moment({ hour: standardStartHour, minute: 0 });
            const workEndTime = moment({ hour: standardEndHour, minute: 0 });

            const isOutsideWorkHours = startHour.isBefore(workStartTime) || endHour.isAfter(workEndTime);

            if (settingsData?.standardWorkTime === 'no' && isOutsideWorkHours) {
                setOutsideWorkAlert(true);
            } else {
                const data = await getOneAppointment(appointment?.id, 'appoinments')
                setSelectData(data.appointment);

                const formattedStartTime = startHour.format('hh:mm A');
                const formattedEndTime = endHour.format('hh:mm A');
                const date = moment(appointment?.apoointmentDetails?.date).format('MM/DD/YYYY');
                const appointmentDateTime = formatSelectedDateTime(date, formattedStartTime, employee.timeZone);

                const updatedAppointment = {
                    ...data.appointment,
                    time: formattedStartTime,
                    endTime: formattedEndTime,
                    type: "One Time",
                    userId: employee.userId,
                    calendar: `${employee.firstName} ${employee.lastName}`,
                    calendarTimezone: employee.timeZone,
                    appointmentDateTime: appointmentDateTime,
                };

                setAppointmentsDataState(updatedAppointment);
                setDialogOpen(true);
            }

        }
    };

    const handleConfirmCloseDialog = () => {
        setDialogOpen(false);
        setAppointmentsDataState();
        setSelectData(null);
    }

    const handelTimezoneConfirmcancel = () => {
        setTimeZoneDialog(false);
        setAppointmentsDataState();
        setSelectData(null);
    }

    const handelAlertClose = () => {
        setAlertOpen(false);
        setAppointmentsDataState();
        setSelectData();
    }

    const handelOutsideWorkAlertClose = () => {
        setOutsideWorkAlert(false);
        setAppointmentsDataState();
        setSelectData();
    }

    const handleConfirmUpdateDialog = async () => {
        const oldTimezone = timeZones.find((item) => item.value === selectData.calendarTimezone)?.label;
        const newTimezone = timeZones.find((item) => item.value === appointmentsDataState.calendarTimezone)?.label;
        const timeZoneConflict = oldTimezone !== newTimezone;

        setDialogOpen(false);
        if (timeZoneConflict) {
            setTimeZoneDialog(true);
        } else {
            commonContext?.setLoader(true);
            const ids = appointmentsDataState?.id;
            const response = await updateAppointments(appointmentsDataState, null, ids);
            if (response.success) {
                commonContext?.setLoader(false);
                setAppointmentsDataState();
                setSelectData(null);
                await ManageAppointmentsData();
            } else {
                console.error("Failed to update appointment:", response.error);
                commonContext?.setLoader(false);
                setDialogOpen(false);
                setAppointmentsDataState();
                setSelectData(null);
            }
        }
    }

    const handelClickTimezoneConfirm = async () => {
        setTimeZoneDialog(false);
        commonContext?.setLoader(true);
        const ids = appointmentsDataState?.id;
        const response = await updateAppointments(appointmentsDataState, null, ids);
        if (response.success) {
            commonContext?.setLoader(false);
            setTimeZoneDialog(false);
            setAppointmentsDataState();
            setSelectData(null);
            await ManageAppointmentsData();
        } else {
            console.error("Failed to update appointment:", response.error);
            commonContext?.setLoader(false);
            setTimeZoneDialog(false);
            setAppointmentsDataState();
            setSelectData(null);
        }
    }

    const timeSlots = generateTimeSlots();
    const TimeColumn = () => {
        return (
            timeSlots.map((hour, index) => (
                <div key={`time-slot-${index}`} className={`time-column ${hour < standardStartHour ? 'early-slot' : ''} ${hour > standardEndHour ? 'late-slot' : ''}`}>
                    {formatTime(hour)}
                </div>
            ))
        );
    };

    return (
        <>
            {loading ? (
                null
            ) : (
                <>
                    {standardStartHour > 1 && (
                        <div style={{ position: "relative", top:"-40px" }}>
                            <div className="toggle-container" style={{ position: "sticky", left: 0, width: "fit-content" }}>
                                <Switch
                                    checked={showEarlySlots}
                                    onChange={(e) => setShowEarlySlots(e.target.checked)}
                                    color='primary'
                                />
                                <span className="toggle-label" style={{ backgroundColor: showEarlySlots ? "#f97703" : "gray" }}>{showEarlySlots ? "View Early Appointments" : "No Early Appointments"}</span>
                            </div>
                        </div>)}
                    <DndProvider backend={HTML5Backend}>
                        <div className='mainCalendarContair'>
                            <div className="mainContainer">
                                <div className='newTimeColumn2' style={{ top: auth.isExpanded ? "-1px" : 0 }}>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}`,
                                        }}
                                    >
                                        <div className="time-column newTimeColumn3"><AccessTimeIcon fontSize="small" /></div>
                                        {employeeDetails && gruopData?.map(([timeZone, employees], index) => (
                                            employees.map((employee) => (
                                                <div key={employee.userId} className="daily-header">
                                                    {employee.firstName} {employee.lastName} - {timeZones.find((tz) => tz.value === employee.timeZone)?.label.split(" ")[0]}
                                                </div>
                                            ))
                                        ))}
                                    </div>
                                </div>
                                {showEarlyAppt && (<div className="early-border" />)}
                                <div className="calendar-container-day d-flex">
                                    <div className='newTimeColumn'>
                                        <TimeColumn />
                                    </div>
                                    <div className={`${auth.isExpanded ? "dayExtraWidth" : ""}`}>
                                        {timeSlots.map((hour) => (
                                            <div
                                                key={hour}
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: `${widths?.map((width) => `${width}px`).join(" ")}`,
                                                }}
                                            >
                                                {gruopData?.map(([timeZone, employees]) => (
                                                    employees.map((employee) => {
                                                        const appointmentsForEmployee = appointmentData.filter(
                                                            (appointment) =>
                                                                appointment.userId === employee.userId &&
                                                                moment(appointment.apoointmentDetails.date).format("MMMM DD YYYY") === moment(allDays).format("MMMM DD YYYY")
                                                        );

                                                        const filteredAppointments = filterAppointments(appointmentsForEmployee, clientName, empNames);

                                                        return (
                                                            <DroppableTimeSlot
                                                                key={`${hour}-${employee.userId}`}
                                                                hour={hour}
                                                                day={allDays}
                                                                employee={employee}
                                                                onDrop={handleDrop}
                                                            >
                                                                {filteredAppointments.map((appointment, index) => {
                                                                    const appointmentTimeSlot = settingsData?.timeFormat === '24hrs' ? moment(appointment.apoointmentDetails.time, 'h:mm A').hour() : moment(appointment.apoointmentDetails.time, "hh:mm A").hour();
                                                                    const clientId = appointment.apoointmentDetails.clientId ? appointment.apoointmentDetails.clientId : appointment.clientId;
                                                                    const client = clientDetails.find((client) => client.id === clientId);
                                                                    const appointmentStartTime = appointment.apoointmentDetails.time;
                                                                    let appointmentEndTime = appointment.apoointmentDetails.endTime;
                                                                    const cutoffTime = moment(showLateSlots ? "11:00 pm" : standardEndHour, "h:mm a");
                                                                    const endTimeMoment = moment(appointmentEndTime, "h:mm a");
                                                                    if (endTimeMoment.isAfter(cutoffTime)) {
                                                                        appointmentEndTime = cutoffTime.format("h:mm a");
                                                                    }
                                                                    const duration = calculateDurationInMinutes(appointmentStartTime, appointmentEndTime)
                                                                    const boxHeight = (duration / 60) * 100;

                                                                    return appointmentTimeSlot === hour ? (
                                                                        <DraggableAppointment
                                                                            key={`appointment-${appointment.id}`}
                                                                            index={index}
                                                                            filteredAppointments={filteredAppointments}
                                                                            appointment={appointment}
                                                                            client={client}
                                                                            boxHeight={boxHeight}
                                                                            setIsModalOpen={setIsModalOpen}
                                                                            setSelectedAppointment={setSelectedAppointment}
                                                                            settingsData={settingsData}
                                                                        />
                                                                    ) : null;
                                                                })}
                                                            </DroppableTimeSlot>
                                                        );
                                                    })
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {showLateAppt && (<div className="late-border" />)}
                            </div>
                        </div>
                    </DndProvider>
                    {standardEndHour < 23 && (
                        <div style={{ position: "relative" }}>
                            <div className="toggle-container bottom" style={{ position: "sticky", left: 0, width: "fit-content" }}>
                                <Switch
                                    checked={showLateSlots}
                                    onChange={(e) => setShowLateSlots(e.target.checked)}
                                    color='primary'
                                />
                                <span className="toggle-label" style={{ backgroundColor: showLateSlots ? "#f97703" : "gray" }}>{showLateSlots ? "View Late Appointments" : "No Late Appointments"}</span>
                            </div>
                        </div>)}
                </>
            )}
            {dialogOpen && (
                <DragReschduleConfirm
                    open={dialogOpen}
                    onSingleAppt={handleConfirmUpdateDialog}
                    auth={selectData}
                    onClose={handleConfirmCloseDialog}
                    client={clientDetails}
                    data={appointmentsDataState}
                    timeZones={timeZones}
                    settingsData={settingsData}
                />
            )}
            {timeZoneDialog && (
                <TimeZoneConflictsDialog
                    open={timeZoneDialog}
                    onConfirm={handelClickTimezoneConfirm}
                    onClose={handelTimezoneConfirmcancel}
                />
            )}
            {alertOpen && (
                <AlertOpen
                    open={alertOpen}
                    onClose={handelAlertClose}
                />
            )}
            {outsideWorkAlert && (
                <OutsideWorkAlertOpen
                    open={outsideWorkAlert}
                    onClose={handelOutsideWorkAlertClose}
                />
            )}
        </>
    );


};

export default DayView;