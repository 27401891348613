
import React, { useContext } from 'react';
import { Card, Divider, List, Typography, Collapse, IconButton } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormatedDates } from '../../../services/helpers';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AuthContext from '../../../hooks/authContext';

const SupplyLable = ({ orderId }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        Request# : {orderId}
    </Typography>
);

const SupplySummaryLable = ({ name }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        {name}
    </Typography>
);

const SupplyCommonCard = ({ index, record, isOpen, handleExpandClick, getPriorityLabel, redirectToDetails,
    handleDialogSupplyOpen, columns, supplyUnarchive, handelUnArchive, handelTooltipClicks, opens, handelApproveClick, handleDialogOpen,
    setRecordID, setData, Suppies, getDeliveryStatus, SupplySummary, getStatusTag, settingsData }) => {

    const auth = useContext(AuthContext);
    return (
        <React.Fragment key={`record-${index}`}>
            <Card style={{ boxShadow: "none", borderRadius: "0px", border: "1px solid #a9a9a9", width: "97%" }} className="mb-20">
                <div className="d-flex flex-center flex-space-between pv-5 pl-15 pr-8" style={{ backgroundColor: "#efefef" }}>
                    {SupplySummary ? <SupplySummaryLable name={record?.employeeName} /> : <SupplyLable orderId={record?.orderId} />}
                    <div>
                        {Suppies === true ?
                            <IconButton onClick={() => handelTooltipClicks(record?.id)} disabled={!settingsData?.approveRequestRole?.includes(auth?.userProfile?.role)}
                                style={{
                                    color: !settingsData?.approveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green"
                                }}>
                                <MoreHorizRoundedIcon fontSize="large" />
                            </IconButton>
                            : SupplySummary === true && record?.status === "Pending" ?
                                <IconButton onClick={() => handelTooltipClicks(record?.id)} disabled={!settingsData?.approveRequestRole?.includes(auth?.userProfile?.role)}
                                    style={{
                                        color: !settingsData?.approveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green"
                                    }}>
                                    <MoreHorizRoundedIcon fontSize="large" />
                                </IconButton>
                                : <MoreHorizRoundedIcon className="color-primary" fontSize="large" onClick={() => redirectToDetails(record?.id)} />
                        }
                    </div>
                </div>
                <Divider />
                {opens === record?.id && (Suppies === true || SupplySummary === true) && (
                    <>
                        <div style={{ marginTop: "-10px", position: 'absolute', right: '8px', background: '#fff', border: '1px solid #ccc', borderRadius: '5px', zIndex: '1000' }}>
                            <svg
                                style={{ position: 'absolute', top: '-10px', right: '35px' }}
                                width="20" height="10"
                                viewBox="0 0 20 10"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polygon points="0,10 10,0 20,10" fill="white" />

                                <line x1="0" y1="10" x2="10" y2="0" stroke="#ccc" strokeWidth="1" />

                                <line x1="10" y1="0" x2="20" y2="10" stroke="#ccc" strokeWidth="1" />
                            </svg>
                            <div style={{ cursor: 'pointer' }}>
                                <div style={{ fontSize: "16px", color: 'green', fontWeight: 'bold', marginBottom: '5px', padding: '10px 20px' }} onClick={() => handelApproveClick(record?.id)}>
                                    Approve
                                </div>
                                <Divider />
                                <div style={{ fontSize: "16px", color: 'darkred', fontWeight: 'bold', padding: '10px 20px' }}
                                    onClick={() => {
                                        handleDialogOpen(record?.id);
                                        setRecordID(record?.id);
                                        setData(record);
                                    }}>
                                    Reject
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <List className="mv-15">
                    {columns.filter(column => !column?.isCollapsible).map((column, columnIndex) => (
                        <div className="d-flex flex-space-between ph-25 pv-10" key={`column-${columnIndex}`}>
                            {columnIndex === 0 ? (
                                <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#3d3d3d" }}>
                                    {column?.label}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body"
                                    style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}
                                >
                                    {column?.label}
                                </Typography>
                            )}

                            <div className="w-45">
                                {columnIndex === 0 ? (
                                    <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#606060" }}>
                                        {column?.id === "priority" && SupplySummary ? (
                                            <span style={{ marginLeft: "-5px" }}>{getStatusTag(record)}</span>
                                        ) : record[column?.id]}
                                    </Typography>
                                ) : (
                                    <Typography variant="body" style={{ color: "#606060" }}>
                                        {column?.id === "priority" ? (
                                            getPriorityLabel(record[column?.id])
                                        ) : column?.id === "item" && SupplySummary ? (
                                            <div style={{ color: "blue" }} onClick={() => handleDialogSupplyOpen(record?.supplyItems)}>
                                                {`${record[column?.id]} ${record.totalQuantity}`}
                                            </div>
                                        ) : (
                                            record[column?.id]
                                        )}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ))}

                    {!isOpen && supplyUnarchive ? <div className="d-flex flex-space-between flex-center ph-25 pv-10">
                        <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}> UnArchive </Typography>
                        <Typography variant="body" style={{ color: "#606060" }} className="w-45 d-flex flex-center">
                            <IconButton onClick={() => handelUnArchive(record)} disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                                style={{
                                    color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green",
                                    padding: "0%"
                                }}>
                                <UnarchiveIcon />
                            </IconButton>
                            <span
                                className={`link ${!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "disabled" : ""}`}
                                onClick={() => {
                                    if (settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)) {
                                        handelUnArchive(record);
                                    }
                                }}
                                style={{
                                    pointerEvents: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "none" : "auto",
                                    color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "blue",
                                    cursor: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "not-allowed" : "pointer"
                                }}
                            >
                                Unarchive
                            </span>
                        </Typography>
                    </div> : null}

                    <Collapse in={isOpen} timeout="auto" unmountOnExit className="w-100">
                        {columns.filter(col => col?.isCollapsible).map((column, columnIndex) => (
                            <div className="d-flex flex-space-between ph-25 pv-10" key={`collapsible-column-${columnIndex}`}>
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column?.label}
                                </Typography>
                                <Typography variant="body" style={{ color: "#606060" }} className="w-45">
                                    {column?.id === "dateOfRequest" ? (
                                        FormatedDates(record?.dateOfRequest)
                                    ) : column?.id === "date" && SupplySummary ? (
                                        FormatedDates(record?.dateOfRequest)
                                    ) : column?.id === "deliverStatus" ? (
                                        getDeliveryStatus(record?.supplyItems)
                                    ) : column?.id === "supplies" ? (
                                        <>
                                            <div style={{ color: "blue" }} onClick={() => handleDialogSupplyOpen(record?.supplyItems)}>
                                                {record?.supplies}
                                            </div>
                                        </>
                                    ) : column?.id === "unArchive" && supplyUnarchive === true ? (
                                        <>
                                            <div className='d-flex flex-center'>
                                                <IconButton onClick={() => handelUnArchive(record)} disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                                                    style={{
                                                        color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green",
                                                        padding: "0%"
                                                    }}>
                                                    <UnarchiveIcon />
                                                </IconButton>
                                                <span
                                                    className={`link ${!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        if (settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)) {
                                                            handelUnArchive(record);
                                                        }
                                                    }}
                                                    style={{
                                                        pointerEvents: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "none" : "auto",
                                                        color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "blue",
                                                        cursor: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "not-allowed" : "pointer"
                                                    }}
                                                >
                                                    Unarchive
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        record[column.id]
                                    )}
                                </Typography>
                            </div>
                        ))}
                    </Collapse>
                </List>
                <Divider />
                <div className="d-flex flex-justify-center">
                    <IconButton className="p-0" onClick={() => handleExpandClick(record?.id)}>
                        <ExpandMoreIcon
                            fontSize="large"
                            style={{
                                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                        />
                    </IconButton>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default SupplyCommonCard;