import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
import { BaseUrl, COLLECTIONS, ROLES, EMP_ROLES, DOMAINURL } from "../config";
import { firebase } from "../firebase-config";
import { query, where, getDocs, collection, documentId, getDoc, doc, setDoc, arrayRemove, arrayUnion, serverTimestamp, updateDoc } from 'firebase/firestore';
import { generateP } from "../../services/helpers";
import { getAuth } from "firebase/auth";
const defaultHeaders = {
  "Content-Type": "application/json",
  withCredentials: true,
};

/**
 * get list of all employees
 * @param {*} formData  : may be used for filter data by fields
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeesList(formData, onSuccess, onError) {
  try {
    const empQuery = query(
      collection(firebase, COLLECTIONS.USERS),
      where("role", "==", ROLES.EMPLOYEE)
    );

    const querySnapshot = await getDocs(empQuery);
    const results = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      results.push({ id: doc.id, ...data });
    });

    onSuccess && onSuccess(results);
  } catch (error) {
    console.log("Error getting documents: ", error);
    onError && onError(error);
  }
}

/**
 * get list of all employees
 * @param {*} formData  : may be used for array ids
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeesListBasedOnId(formData, onSuccess, onError) {
  try {
    const colRef = collection(firebase, COLLECTIONS.USERS);

    const empQuery = query(colRef, where(documentId(), 'in', formData));

    const querySnapshot = await getDocs(empQuery);
    const results = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    onSuccess && onSuccess(results);
  } catch (error) {
    console.log('Error getting documents: ', error);
    onError && onError(error);
  }
}

/**
 * Get details of perticular employee
 * @param {*} id : id of employee
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployee(id, onSuccess, onError) {
  try {
    const empDocRef = doc(firebase, COLLECTIONS.USERS, id);

    const docSnapshot = await getDoc(empDocRef);

    if (!docSnapshot.exists()) {
      onError && onError({ status: false, error: "Employee not found" });
    } else {
      const data = docSnapshot.data();
      onSuccess && onSuccess({ status: true, data: { id: docSnapshot.id, ...data } });
    }
  } catch (error) {
    onError && onError({ status: false, error: error });
  }
}

/**
 * Get details of All employee
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getAllEmployee(onSuccess, onError) {
  try {
    const colRef = collection(firebase, COLLECTIONS.USERS);

    const querySnapshot = await getDocs(colRef);

    if (querySnapshot.empty) {
      onError && onError({ status: false, error: "No employees found" });
    } else {
      const employees = [];
      querySnapshot.forEach(doc => {
        employees.push({ id: doc.id, ...doc.data() });
      });
      onSuccess && onSuccess({ status: true, data: employees });
    }
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * update group collection record for employess
 * add or remove employee from perticular group
 * @param {*} type [add or remove]
 * @param {*} groupId
 * @param {*} EmployeeId
 * @returns
 */
export async function updateEmployeetoGroup(type, groupId, EmployeeId) {
  const documentRef = doc(firebase, COLLECTIONS.GROUPS, groupId);

  try {
    if (type === "add") {
      await setDoc(documentRef, {
        employeeId: arrayUnion(EmployeeId),
      }, { merge: true });
      return { status: true, message: "Updated successfully" };

    } else if (type === "remove") {
      await setDoc(documentRef, {
        employeeId: arrayRemove(EmployeeId),
      }, { merge: true });
      return { status: true, message: "Updated successfully" };

    } else {
      throw new Error("Invalid operation type");
    }
  } catch (error) {
    return { status: false, error: error.message || error };
  }
}

/**
 * Create User with employee role in users collection
 * called from addemployee function
 * @param {*} formData
 * @returns
 */
async function createEmployeeUser(formData) {
  const currentUserId = getAuth().currentUser.uid;

  try {
    const userDocRef = doc(firebase, COLLECTIONS.USERS, formData?.userId);

    await setDoc(userDocRef, {
      ...formData,
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    });

    const responseItem = {
      ...formData,
      id: formData?.userId,
    };
    return { status: true, data: responseItem };

  } catch (error) {
    return { status: false, error: error.message || error };
  }
}

/**
 * Update data of user
 * @param {*} id
 * @param {*} formData
 * @returns
 */
export async function updateUserPassword(id, formData) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const response = await axiosInstance.post(
      `${BaseUrl}updateUserPass`,
      { email: formData.email, password: formData.password },
      { headers: { ...defaultHeaders, ...authHeaders } }
    );
    return {
      status: true,
      message: "Updated successfully",
      response: response.data || formData,
    };
  } catch (error) {
    console.error("Error updating user password:", error);
    return { status: false, error: error.response?.data || error.message || error };
  }
}
export async function updateUser(id, formData) {
  try {
    const auth = getAuth();
    const currentUserId = auth.currentUser.uid;
    const documentRef = doc(firebase, COLLECTIONS.USERS, id);

    await setDoc(
      documentRef,
      {
        ...formData,
        updatedAt: serverTimestamp(),
        updatedBy: currentUserId,
      },
      { merge: true }
    );

    return {
      status: true,
      message: "Updated successfully",
      response: { id, ...formData },
    };
  } catch (error) {
    console.error("Error updating user:", error);
    return { status: false, error: error.message || error };
  }
}

/**
 * Add employee to user
 * First create user to firebase auth user then add employee data to users collection
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addEmployee(formData, onSuccess, onError) {
  try {
    let NewUserData = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
      companyId: formData.companyId,
    };

    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
    const res = await axiosInstance
      .post(
        `${BaseUrl}createUser`,
        { ...NewUserData },
        { headers: { ...defaultHeaders, ...authHeaders } }
      )
      .then(async (CreatedUser) => {
        if (CreatedUser?.data?.status === true) {
          // if (formData.employeerole === EMP_ROLES.CLIENT_TEAM) {
          //   await axiosInstance.post(
          //     `${BaseUrl}sendEmailData`,
          //     {
          //       email: NewUserData.email,
          //       subject: "Your ProTeams account was successfully created",
          //       contenttxt:
          //         `<p>Hello ` +
          //         NewUserData.firstname +
          //         `,</p>
          //         <p></p>
          //     <p>You've been invited to the ProTeams mobile app. Please click on the
          //     https://${DOMAINURL}/apps/ to download the app from iOS or Android stores.
          //     Once you have installed the app you can you login by using the following details:</p>
          //     <p>User name:<br />` +
          //         NewUserData.email +
          //         `</p> 
          //      <p>Password:<br />` +
          //         NewUserData.password +
          //         `</p>
          //         <p></p>
          //         <p></p>
          //         <p>Proteams Support Team</p>
          //         `,
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         withCredentials: true,
          //         ...authHeaders,
          //       },
          //     }
          //   );

          //   await axiosInstance.post(
          //     `${BaseUrl}sendSmsText`,
          //     {
          //       phoneNumber: [NewUserData.phoneNumber],
          //       textMessage: `Hello ${NewUserData.firstname},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${NewUserData.email}`,
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         withCredentials: true,
          //         ...authHeaders,
          //       },
          //     }
          //   );
          // } else if (
          //   formData.employeerole === EMP_ROLES.ADMIN ||
          //   formData.employeerole === EMP_ROLES.GUEST ||
          //   formData.employeerole === EMP_ROLES.SUPPORT
          // ) {
          //   await axiosInstance.post(
          //     `${BaseUrl}sendEmailData`,
          //     {
          //       email: NewUserData.email,
          //       subject: "Your ProTeams account was successfully created",
          //       contenttxt:
          //         `<p>Hello ` +
          //         NewUserData.firstname +
          //         `,</p>
          //         <p></p>
          //     <p>You have been invited to the ProTeams dashboard. To login and access your account please
          //     use the following credentials:</p>
          //     <p>Website: ${DOMAINURL}</p> 
          //     <p>Email Address: ` +
          //         NewUserData.email +
          //         `</p> 
          //      <p>Password :` +
          //         NewUserData.password +
          //         `</p>
          //       <p>Role :` +
          //         formData.employeerole +
          //         `</p>
          //         <p></p>
          //         <p></p>
          //         <p>The Proteams Support Team</p>
          //         <p></p>
          //         <p>*Note this app is best used on a desktop or laptop computer</p>`,
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         withCredentials: true,
          //         ...authHeaders,
          //       },
          //     }
          //   );

          //   await axiosInstance.post(
          //     `${BaseUrl}sendSmsText`,
          //     {
          //       phoneNumber: [NewUserData.phoneNumber],
          //       textMessage: `Hello ${NewUserData.firstname},\n\nYou are invited to the ProTeams Dashboard!\n\nPlease check ${NewUserData.email} for the URL, your ID & password`,
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         withCredentials: true,
          //         ...authHeaders,
          //       },
          //     }
          //   );
          // }
          const newEmployeeUser = {
            role: ROLES.EMPLOYEE,
            userId: CreatedUser?.data?.uid, // userid from firebase auth user
            firstName: formData?.firstName ? formData?.firstName : "",
            lastName: formData.lastName ? formData.lastName : "",
            email: formData.email ? formData.email : "",
            userName: formData.email ? formData.email : "",
            phoneNumber: formData.phoneNumber ? formData.phoneNumber : "",
            timeZone: formData.timeZone ? formData.timeZone : "",
            companyId: formData.companyId ? formData.companyId : "",
            // clients: formData.clients ? formData.clients : [],
            // employeerole: formData.employeerole ? formData.employeerole : "",
            // group: formData.group ? formData.group : [],
            // manualAcuityId: formData.manualAcuityId
            //   ? formData.manualAcuityId
            //   : "",
            // acuityId: formData.acuityId ? formData.acuityId : "",
            status: true,
          };
          let create_user_res = await createEmployeeUser(newEmployeeUser);
          if (create_user_res?.status === true) {
            onSuccess(create_user_res);
          } else {
            onError(create_user_res);
          }
        }
      });
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Update Employee user data
 * @param {*} id : employee id
 * @param {*} formData : only pass data to be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateEmployee(id, formData, onSuccess, onError) {
  const currentUserId = getAuth().currentUser.uid; 
  delete formData.employeeId;
  delete formData.id;

  let employeeData = {};
  let password = generateP();

  const docRef = doc(firebase, COLLECTIONS.USERS, id);
  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      employeeData = docSnap.data();
    } else {
      console.log("No such document!");
      throw new Error("No such document!");
    }
  } catch (e) {
    console.log("Error getting document:", e);
    onError && onError({ status: false, message: "Error fetching employee data" });
    return; 
  }

  const NewUserData = {
    firstname: formData.firstName ? formData.firstName : employeeData.firstName,
    lastname: formData.lastName ? formData.lastName : employeeData.lastName,
    email: formData.email ? formData.email : employeeData.email,
    phoneNumber: formData.phoneNumber ? formData.phoneNumber : employeeData.phoneNumber,
    password: password,
    isUpdate: true,
  };

  if (formData.email) {
    if (formData.userName !== formData.email) {
      formData.userName = formData.email;

      const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
      await axiosInstance.post(
        `${BaseUrl}createUser`,
        { ...NewUserData },
        { headers: { ...defaultHeaders, ...authHeaders } }
      );
    }
  }

  try {
    await setDoc(
      docRef,
      {
        ...formData,
        updatedAt: new Date().getTime(),
        updatedBy: currentUserId,
      },
      { merge: true }
    );

    const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
    if (formData.employeerole) {
      if (employeeData.employeerole !== formData.employeerole) {
        await axiosInstance.post(
          `${BaseUrl}updateUserPass`,
          { email: NewUserData.email, password: NewUserData.password },
          { headers: { ...defaultHeaders, ...authHeaders } }
        );

        if (formData.employeerole === EMP_ROLES.CLIENT_TEAM) {
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: NewUserData.email,
              subject: "Your ProTeams account was successfully created",
              contenttxt: `<p>Hello ${NewUserData.firstname},</p>
                <p>You've been invited to the ProTeams mobile app. Please click on the
                https://${DOMAINURL}/apps/ to download the app from iOS or Android stores.
                Once you have installed the app, you can login using the following details:</p>
                <p>User name:<br />${NewUserData.email}</p> 
                <p>Password:<br />${password}</p>
                <p></p>
                <p></p>
                <p>Proteams Support Team</p>`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: [NewUserData.phoneNumber],
              textMessage: `Hello ${NewUserData.firstname},\n\nYou're invited to the ProTeams App! Download it - https://${DOMAINURL}/apps/\n\nYour ID & password were sent to ${NewUserData.email}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        } else if (
          formData.employeerole === EMP_ROLES.ADMIN ||
          formData.employeerole === EMP_ROLES.GUEST ||
          formData.employeerole === EMP_ROLES.SUPPORT
        ) {
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: NewUserData.email,
              subject: "Your ProTeams account was successfully created",
              contenttxt: `<p>Hello ${NewUserData.firstname},</p>
                <p>You have been invited to the ProTeams dashboard. To login and access your account please
                use the following credentials:</p>
                <p>Website: ${DOMAINURL}</p> 
                <p>Email Address: ${NewUserData.email}</p> 
                <p>Password: ${password}</p>
                <p>Role: ${formData.employeerole}</p>
                  <p></p>
                  <p></p>
                <p>The Proteams Support Team</p>
                  <p></p>
                <p>*Note this app is best used on a desktop or laptop computer</p>`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: [NewUserData.phoneNumber],
              textMessage: `Hello ${NewUserData.firstname},\n\nYou are invited to the ProTeams Dashboard!\n\nPlease check ${NewUserData.email} for the URL, your ID & password`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
      }
    }
    onSuccess &&
      onSuccess({
        status: true,
        message: "Updated successfully",
        response: { status: "success" },
      });
  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, error: error.message });
  }
}

/**
 * Remove Employee user from system
 * First remove from firebase auth
 * then remove from users collection
 * @param {*} id
 * @param {*} empData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteEmployee(id, empData, onSuccess, onError) {
  if (id && id !== "") {
    const documentRef = doc(firebase, COLLECTIONS.USERS, id);

    try {
      const docSnapshot = await getDoc(documentRef);
      
      if (!docSnapshot.exists()) {
        onError && onError({ status: false, error: "Document Not found" });
        return;
      }

      const data = docSnapshot.data();
      const toRemove = data?.group || [];

      await Promise.all(toRemove.map(async (groupItem) => {
        await updateEmployeetoGroup("remove", groupItem, id);
      }));

      const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
      const res = await axiosInstance.post(
        `${BaseUrl}removeUser`,
        { id: id },
        { headers: { ...defaultHeaders, ...authHeaders } }
      );
  
      if (!res.data.status) {
        throw new Error("Failed to delete user from Firebase Authentication");
      }
      
      await updateDoc(documentRef, {
        deleted: true,
        status: false,
        deletedAt: new Date().toISOString(),
      });

      onSuccess && onSuccess({ status: true, message: "Delete successful", res });

      const emailtext = `<p>Hello,</p>
        <p>A user with an email address ${empData.email} has been deleted from the CSM app.
        Please contact management if this was an error. Thank you,</p>
        <p></p>
        <p>Proteams Support Team</p>`;
      
      await axiosInstance.post(
        `${BaseUrl}sendEmailData`,
        {
          email: "support@procleanings.com",
          subject: `A user was removed from the Proteams App`,
          contenttxt: emailtext,
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            ...authHeaders,
          },
        }
      );
    } catch (error) {
      console.error("Error while deleting user:", error);
      onError && onError({ status: false, error: error.message || "An error occurred" });
    }
  } else {
    onError && onError({ status: false, message: "Invalid input" });
  }
}

/**
 * Returns summary data for employee collection
 * @param {*} formData : may be used for filter by different filed
 * @param {*} onSuccess
 * @param {*} onError
 */
// export async function getEmployeeSummary(formData, onSuccess, onError) {
//   firebase
//     .firestore()
//     .collection(COLLECTIONS.USERS)
//     .where("role", "==", ROLES.EMPLOYEE)
//     .limit(4)
//     .get()
//     .then((querySnapshot) => {
//       let results = [];
//       querySnapshot.forEach((doc) => {
//         let data = doc.data();
//         results.push({ id: doc.id, ...data });
//       });

//       onSuccess && onSuccess(results);
//     })
//     .catch((error) => {
//       console.log("Error getting documents: ", error);
//       onError && onError(error);
//     });
// }

/**
 * Returns summary data for employee collection
 * @param {*} formData : may be used for filter by different filed
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getEmployeeByFilter(formData, onSuccess, onError) {
  if (formData.clients || formData.groups) {
    let refUsers = query(
      collection(firebase, COLLECTIONS.USERS),
      where("role", "==", ROLES.EMPLOYEE)
    );

    if (formData.clients && formData.clients !== 'All') {
      refUsers = query(refUsers, where('clients', 'array-contains', formData?.clients?.id));
    }

    if (formData.groups && formData.groups !== 'All') {
      refUsers = query(refUsers, where('group', 'array-contains', formData?.groups?.groupId));
    }

    try {
      const querySnapshot = await getDocs(refUsers);
      const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      onSuccess && onSuccess(results);
    } catch (error) {
      console.error("Error getting documents: ", error);
      onError && onError(error);
    }
  }
}

export const setUserInformation = async (userId, data) => {
  const userRef = doc(firebase, "users", userId);
  return updateDoc(userRef, data);
};