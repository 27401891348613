/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container, Typography } from "@material-ui/core";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { getClientsList } from "../../services/apiservices/client";
import { getAllEmployee } from "../../services/apiservices/employee";
import DateAndTimeSelector from "./DateTimeSelector";
import { useMediaQuery } from "@mui/material";
import dayjs from 'dayjs';
import moment from "moment";
import { calculateDurationInMinutes, filterAppointmentsOnSameDate, formatSelectedDateTime, formatTime, generateAppointmentTypeId, getFormatedDate, getUpcomingDates, isTimeInRange, ValidTime } from "../../services/helpers";
import { getAllAppoinment, getOneAppointment, saveAppointment, updateAppointments } from "../../services/apiservices/appoinments";
import CommonContext from "../../hooks/commonContext";
import AuthContext from "../../hooks/authContext";
import CircularProgress from "@mui/material/CircularProgress";
import { CancelApptDailog, ConfirmationApptDialog, SuccessApptDialog } from "../../components/DialogComponent/SupplyDataDialog";
import { useNavigate, useParams } from "react-router-dom";
import { getSettings } from "../../services/apiservices/settings";

const AppointmentTypeToggle = ({ isMobile, isLandscape, appointmentType, setAppointmentType }) => {

    const handleToggle = (type) => {
        setAppointmentType(type);
    };

    return (
        <div className="mt-25">
            <Typography
                variant="body"
                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}
            >
                Appointment type
            </Typography>
            <div className="mt-10 mb-30 w-100">
                <Button
                    variant={appointmentType === 'One Time' ? 'contained' : 'outlined'}
                    onClick={() => handleToggle('One Time')}
                    style={{
                        backgroundColor:
                            appointmentType === 'One Time' ? '#27ae60' : '#E0E0E0',
                        color: appointmentType === 'One Time' ? '#FFFFFF' : '#3d3d3d',
                        borderRadius: '5px 0 0 5px',
                        height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                        border: 0,
                        boxShadow: 'none',
                        width: `${isMobile || isLandscape ? "40%" : ""}`
                    }}
                >
                    One Time
                </Button>
                <Button
                    variant={
                        appointmentType === 'Recurring Appointment' ? 'contained' : 'outlined'
                    }
                    onClick={() => handleToggle('Recurring Appointment')}
                    style={{
                        backgroundColor:
                            appointmentType === 'Recurring Appointment' ? '#27ae60' : '#E0E0E0',
                        color: appointmentType === 'Recurring Appointment' ? '#FFFFFF' : '#3d3d3d',
                        borderRadius: '0 5px 5px 0',
                        border: 0,
                        height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                        boxShadow: 'none',
                        width: `${isMobile || isLandscape ? "60%" : ""}`
                    }}
                >
                    Recurring Appointment
                </Button>
            </div>
        </div>
    );
};

const AppoinmentSchedule = (props) => {
    const commonContext = useContext(CommonContext);
    const auth = useContext(AuthContext);
    const [clientNameList, setClientNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [clientName, setClientName] = useState("All");
    const [clientNames, SetClientNames] = useState();
    const [shiftNames, setShiftNames] = useState("Any");
    const [allShiftTasks, setAllShiftTasks] = useState([]);
    const [shiftNamesList, setShiftNamesList] = useState([
        { label: "Any", value: "Any" },
    ]);
    const [employeeNameList, setEmployeeNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [employeesNames, SetEmployeeNames] = useState();
    const [employeeEmail, setEmployeeEmail] = useState();
    const [employeeTimeZone, setEmployeeTimeZone] = useState();
    const [empNames, setEmpNames] = useState("All");
    const [allEmployees, setAllEmployees] = useState([]);
    const [cancelDialogOpen, setCancelDailogOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [manualDate, setManualDate] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [numberOfTime, setNumberOfTime] = useState();
    const [frequencyData, setFrequencyData] = useState();
    const [appoinments, setAppoinments] = useState([]);
    const [upcomingAppoinments, setUpcomingAppointments] = useState([]);
    const [isInputVisible, setInputVisible] = useState(false);
    const [timeVisible, setTimeVisible] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [aaptData, setApptdata] = useState();
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
    const [errors, setErrors] = useState({
        clientName: false,
        shiftName: false,
        empName: false,
        date: false,
        startTime: false,
        endTime: false,
        frequency: false,
        numberOfTimes: false,
    });
    const [recurringDates, setRecurringDates] = useState([]);
    const [appointmentType, setAppointmentType] = useState('Recurring Appointment');
    const [loader, setLoader] = useState(false);
    const { appointmentId } = useParams();
    const [editAppointmentData, setEditAppointmentData] = useState();
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const updateAppointmentData = auth.updateAppointmentData?.matchingAppointments;
    const navigate = useNavigate();
    const [settingsData, setSettingsData] = useState();
    const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

    const AppointmentsScheduling = async () => {
        try {
            commonContext?.setLoader(true);
            let editAppointmentData;
            if (appointmentId) {
                editAppointmentData = await getOneAppointment(appointmentId, 'timesheets');
                setEditAppointmentData(editAppointmentData.appointment);
            }

            const [appointmentResponse, clientsResponse, employeesResponse] = await Promise.all([
                getAllAppoinment(),
                new Promise((resolve) => getClientsList({}, (res) => resolve(res || []))),
                new Promise((resolve) => getAllEmployee((res) => resolve(res || {}))),
            ]);

            setAppoinments(appointmentResponse || []);

            const allShifts = [];
            if (clientsResponse) {
                const data = clientsResponse.filter((item)=> item.companyId === companyId);
                const clientNames = data.map(client => ({
                    value: client?.id,
                    label: client?.clientName,
                    firstName: client?.firstName,
                    lastName: client?.lastName,
                    timeZone: client?.timeZone,
                }));

                clientsResponse.forEach(client => {
                    Object.keys(client.shiftData || {}).forEach(shiftKey => {
                        Object.keys(client.shiftData[shiftKey] || {}).forEach(taskKey => {
                            const task = client.shiftData[shiftKey][taskKey];
                            if (task?.title !== "Opentasks" && task.tasksList.length > 0) {
                            allShifts.push({
                                clientId: client?.id,
                                shiftLabel: task?.title,
                            });
                            }
                        });
                    });
                });

                setClientNameList([{ label: "All", value: "All" }, ...clientNames]);
                setAllShiftTasks(allShifts);
            } else {
                setClientNameList([{ label: "All", value: "All" }]);
                setShiftNamesList([{ label: "Any", value: "Any" }]);
                setEmployeeNameList([{ label: "All", value: "All" }]);
            }

            let employeeNames = [];
            const filteredEmployees = employeesResponse?.data.filter(employee =>
                employee.companyId === companyId && employee.role === "Employee" && !employee.deleted && employee.status !== false
            );
            employeeNames = filteredEmployees?.map(emp => ({
                value: emp?.id,
                client: emp?.clients,
                label: `${emp?.firstName} ${emp?.lastName}`,
                email: emp?.email,
                timeZone: emp?.timeZone
            }));
            setAllEmployees(employeeNames);

            if (editAppointmentData && editAppointmentData?.appointment?.userId) {
                const clientId = editAppointmentData?.appointment?.clientId;

                const filteredShifts = allShifts.filter(shift => shift.clientId === clientId);
                const shiftOptions = [
                    { label: "Any", value: "Any" },
                    ...filteredShifts.map(shift => ({
                        value: shift.shiftLabel,
                        label: shift.shiftLabel,
                    })),
                ];

                setShiftNames(editAppointmentData?.appointment?.apoointmentDetails?.shiftName || "Any");
                setShiftNamesList(shiftOptions);

                const filteredEmployeeOptions = employeeNames.filter(employee =>
                    employee.client?.some(client => client.clientid === clientId)
                );
                const employeeOptions = [
                    { label: "All", value: "All" },
                    ...filteredEmployeeOptions.map(employee => ({
                        value: employee.value,
                        label: employee.label,
                        email: employee.email,
                        timeZone: employee.timeZone,
                    })),
                ];

                setEmpNames(editAppointmentData?.appointment?.userId || "All");
                setEmployeeNameList(employeeOptions);
                setClientName(clientId || "All");
                setSelectedDate(dayjs(editAppointmentData?.appointment?.apoointmentDetails?.date) || null);
                setStartTime(formatTime(editAppointmentData?.appointment?.apoointmentDetails?.time) || '');
                setEndTime(formatTime(editAppointmentData?.appointment?.apoointmentDetails.endTime) || '');
                setFrequencyData(editAppointmentData?.appointment?.apoointmentDetails?.frequency || null);
                setNumberOfTime(editAppointmentData?.appointment?.apoointmentDetails?.numberOfTimes ? 2 : null);
                setAppointmentType(editAppointmentData?.appointment?.apoointmentDetails?.type ? "One Time" : "Recurring Appointment");
            } else {
                setShiftNamesList([{ label: "Any", value: "Any" }]);
                setEmployeeNameList([{ label: "All", value: "All" }]);
            }
        } catch (error) {
            console.error("Error in AppointmentsScheduling:", error);
        }
        commonContext?.setLoader(false);
    };   


    const resetAll = async () => {
        setClientName("All");
        setShiftNames("Any");
        setEmpNames("All");
        setSelectedDate();
        setStartTime('');
        setEndTime('');
        setAppointmentType("Recurring Appointment");
        setInputVisible(false);
        setFrequencyData();
        setNumberOfTime();
        setIsDialogOpen(false);
        setUpcomingAppointments([]);
        setAppoinments([])
        setManualDate(null);
        setTimeVisible(false);
        setEmployeeTimeZone();
        setRecurringDates([]);
        AppointmentsScheduling();
        setApptdata();
        setEditAppointmentData();
        auth.setUpdateAppointmentData({});
        setSettingsData();
    };

    useEffect(() => {
        if (appointmentId) {
            AppointmentsScheduling();
        } else {
            resetAll();
            AppointmentsScheduling();
        }
     getSettings('appointment_settings', (res)=>{
        if (res?.data.length > 0) {
            const filteredData = res?.data?.filter(
              (item) => item?.companyId === companyId && !item.deleted
            );
            const data = filteredData?.[0];
            setSettingsData(data)
          } else {
            setSettingsData();
          }
     })
    }, [appointmentId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const resetDependentFields = () => {
        setRecurringDates([]);
        setStartTime('');
        setEndTime('');
        setFrequencyData();
        setNumberOfTime();
    };

    const handleClientNameChange = (e) => {
        const selectedClientId = e.target.value;
        setClientName(selectedClientId);
        resetDependentFields();
        const isClientNameError = selectedClientId === "All";
        setErrors((prevErrors) => ({ ...prevErrors, clientName: isClientNameError }));

        if (selectedClientId === "All") {
            setShiftNamesList([{ label: "Any", value: "Any" }]);
            setUpcomingAppointments([]);
            setEmpNames("All");
        } else {
            const selectedClient = clientNameList.find((client) => client.value === selectedClientId);
            if (selectedClient) {
                SetClientNames(selectedClient);
            } else {
                SetClientNames();
            }
        }

        const filteredShifts = allShiftTasks.filter(
            (shift) => shift.clientId === selectedClientId
        );

        const shiftOptions = [
            { label: "Any", value: "Any" },
            ...filteredShifts.map((shift) => ({
                value: shift.shiftLabel,
                label: shift.shiftLabel
            }))
        ];

        setShiftNamesList(shiftOptions);
        setShiftNames("Any");

        const filteredEmployees = allEmployees.filter((employee) =>
            employee.client?.some((client) => client.clientid === selectedClientId)
        );

        const employeeOptions = [
            { label: "All", value: "All" },
            ...filteredEmployees.map((employee) => ({
                value: employee.value,
                label: employee.label,
                email: employee.email,
                timeZone: employee.timeZone
            }))
        ];

        setEmployeeNameList(employeeOptions);
        setEmpNames("All");
    }

    const filterAppointmentOnTheDate = (date) => {
        if (!date) {
            setUpcomingAppointments([]);
            return;
        }

        if (Array.isArray(appoinments?.appointments) && appoinments.appointments.length > 0) {
            const filteredAppointments = filterAppointmentsOnSameDate(appoinments, clientName, date);
            setUpcomingAppointments(filteredAppointments.length > 0 ? filteredAppointments : []);
        } else {
            setUpcomingAppointments([]);
        }
    };;

    const handleShiftNameChange = (e) => {
        const selectedShift = e.target.value;
        setShiftNames(selectedShift);
        resetDependentFields();
        const isShiftNameError = selectedShift === "Any";
        setErrors((prevErrors) => ({ ...prevErrors, shiftName: isShiftNameError }));
    };

    const handleEmpNameChange = (e) => {

        const selectEmployee = e.target.value;
        setEmpNames(selectEmployee);
        resetDependentFields();
        const isEmpNameError = selectEmployee === "All";
        setErrors((prevErrors) => ({ ...prevErrors, empName: isEmpNameError }));

        if (selectEmployee !== "All") {
            const selectedEmployee = employeeNameList.find((employee) => employee.value === selectEmployee);

            if (selectedEmployee) {
                SetEmployeeNames(selectedEmployee?.label);
                setEmployeeEmail(selectedEmployee?.email);
                setEmployeeTimeZone(selectedEmployee?.timeZone);
            }
        } else {
            SetEmployeeNames('');
            setEmployeeEmail('');
            setEmployeeTimeZone('');
            setSelectedDate();
            setSelectedMonth(null);
            setSelectedDay(null);
            setSelectedYear(null);
            setDaysInMonth([]);
            setManualDate();
        }
    };

    const handelancelData = () => {
        setCancelDailogOpen(true);
    }
    const handelOpenDialog = async () => {
        setCancelDailogOpen(false);
        await resetAll();
    }
    const handelCloseDialog = () => {
        setCancelDailogOpen(false);
    }
    const handelConfirmCloseDialog = () => {
        setConfirmDialogOpen(false);
    }
    const handelSingleApptConfirmDialog = async () => {
        const dates = [recurringDates[0]];
        const ids = [editAppointmentData?.id];
        setConfirmDialogOpen(false);
        setLoader(true);
        // if (recurringDates && recurringDates.length > 0) {
        if (appointmentType === "Recurring Appointment") {
            const response = await updateAppointments(aaptData, recurringDates, ids);
            if (response.success) {
                setIsDialogOpen(true);
                setApptdata();
                setLoader(false);
            } else {
                console.error("Failed to save appointment:", response.error);
                setApptdata();
            }
        } else {
            const response = await updateAppointments(aaptData, recurringDates, editAppointmentData?.id);
            if (response.success) {
                setIsDialogOpen(true);
                setApptdata();
                setLoader(false);
            } else {
                console.error("Failed to save appointment:", response.error);
                setApptdata();
            }
            // setFrequencyData();
            // setNumberOfTime();
            // setLoader(false);
            // const newErrors = {
            //     frequency: true,
            //     numberOfTimes: true
            // };
            // setErrors(newErrors);
        }
    }

    const handelSeriesApptConfirmDialog = async () => {
        const apptids = updateAppointmentData?.map(item => item.id) || [];
        setConfirmDialogOpen(false);
        setLoader(true);
        if (recurringDates && recurringDates.length > 0) {
            const response = await updateAppointments(aaptData, recurringDates, apptids);
            if (response.success) {
                setIsDialogOpen(true);
                setLoader(false);
                setApptdata();
            } else {
                console.error("Failed to save appointment:", response.error);
                setApptdata();
                setLoader(false);
            }
        } else {
            setFrequencyData();
            setNumberOfTime();
            setLoader(false);
            const newErrors = {
                frequency: true,
                numberOfTimes: true
            };
            setErrors(newErrors);
        }
    }

    const handelSuccelCloseDialog = async () => {
        setIsDialogOpen(false);
        await resetAll();
        if (editAppointmentData) {
            setEditAppointmentData();
            navigate("/manageAppointments");
        }
    };

    const handleCancelTime = () => {
        setSelectedDate();
        setStartTime('');
        setEndTime('');
        setFrequencyData();
        setNumberOfTime();
        setManualDate(null);
        setRecurringDates([]);
    };

    const handleAddTime = () => {
        if (appointmentType === "Recurring Appointment") {
            const isStartTimeInRange = isTimeInRange(startTime, settingsData?.startTime, settingsData?.endTime);
            const isEndTimeInRange = isTimeInRange(endTime, settingsData?.startTime, settingsData?.endTime);

            const newErrors = {
                date: !(selectedDate || manualDate),
                startTime: {
                    hasError: !startTime || !isStartTimeInRange || !ValidTime(startTime),
                    text: !startTime ? "Choose One" : 
                          !isStartTimeInRange ? "Start time is out of range" : 
                          !ValidTime(startTime) ? "Invalid start time format" : ""
                },
                endTime: {
                    hasError: !endTime || !isEndTimeInRange || !ValidTime(endTime),
                    text: !endTime ? "Choose One" : 
                          !isEndTimeInRange ? "End time is out of range" : 
                          !ValidTime(endTime) ? "Invalid end time format" : ""
                },
                frequency: frequencyData ? !frequencyData : false,
                numberOfTimes: numberOfTime < 2 ? true : !numberOfTime,
                manualDate: !(selectedDate || manualDate),
                clientName: clientName === "All",
                shiftName: shiftNames === "Any",
                empName: empNames === "All"
            };

            const hasErrors = Object.values(newErrors).some(error => error === true);

            if (hasErrors) {
                setErrors(newErrors);
                setLoader(false);
                return;
            }

            if (manualDate) {
                const newDates = getUpcomingDates(manualDate, frequencyData, numberOfTime, settingsData?.workWeek);
                setRecurringDates(newDates);
            } else {
                const newDate = getUpcomingDates(selectedDate, frequencyData, numberOfTime, settingsData?.workWeek);
                setRecurringDates(newDate);
            }
        } else {
            setRecurringDates([]);
        }
    };

    const handleRemoveDate = (dateToRemove) => {
        setRecurringDates(prevDates => prevDates.filter(date => date !== dateToRemove));
    };
    const handelNavigate = async () => {
        await resetAll();
        navigate("/manageAppointments");
    };
           
    const dateCreated = moment().format('MMMM DD, YYYY');
    const datetimeCreated = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const appointmentTypeId = generateAppointmentTypeId();
    const appointmentDuration = calculateDurationInMinutes(startTime, endTime);
    const date = getFormatedDate(selectedDate);
    const selectedClient = clientNameList.find(client => client.value === clientName);
    const selectedShift = shiftNamesList.find(shift => shift.value === shiftNames);
    const selectedEmployee = employeeNameList.find(emp => emp.value === empNames);
    const timeZone = employeeTimeZone || selectedEmployee?.timeZone || null;
    const dateTimes = formatSelectedDateTime(selectedDate, startTime, timeZone);

    const handleAddAppointments = async () => {
        setLoader(true);
        const isStartTimeInRange = isTimeInRange(startTime, settingsData?.startTime, settingsData?.endTime);
        const isEndTimeInRange = isTimeInRange(endTime, settingsData?.startTime, settingsData?.endTime);

        const newErrors = {
            date: !(selectedDate || manualDate),
            startTime: {
                hasError: !startTime || !isStartTimeInRange || !ValidTime(startTime),
                text: !startTime ? "Start time is required" : 
                      !isStartTimeInRange ? "Start time is out of range" : 
                      !ValidTime(startTime) ? "Invalid start time format" : ""
            },
            endTime: {
                hasError: !endTime || !isEndTimeInRange || !ValidTime(endTime),
                text: !endTime ? "End time is required" : 
                      !isEndTimeInRange ? "End time is out of range" : 
                      !ValidTime(endTime) ? "Invalid end time format" : ""
            },
            frequency: appointmentType === "Recurring Appointment" && !frequencyData,
            numberOfTimes: appointmentType === "Recurring Appointment" && !numberOfTime,
            manualDate: !(selectedDate || manualDate),
            clientName: clientName === "All",
            shiftName: shiftNames === "Any",
            empName: empNames === "All",
        };

        const hasErrors = Object.values(newErrors).some(error => error === true);

        if (hasErrors) {
            setErrors(newErrors);
            setLoader(false);
            return;
        }

        try {
            const manualDates = manualDate && dayjs.isDayjs(manualDate) && manualDate.isValid()
                ? manualDate.format('MMMM D, YYYY')
                : null;
            const startTimes = (startTime && isTimeInRange(startTime, settingsData?.startTime, settingsData?.endTime) && ValidTime(startTime)) ? startTime : null;
            const manualDateTimes = manualDate && dayjs.isDayjs(manualDate) && manualDate.isValid()
                ? formatSelectedDateTime(manualDate, startTimes, timeZone)
                : null;
            const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

                const newAppointmentData = {
                appointmentTypeID: editAppointmentData ? editAppointmentData?.apoointmentDetails?.appointmentTypeID : appointmentTypeId,
                appointmentDateTime: manualDateTimes || dateTimes,
                calendar: employeesNames || selectedEmployee?.label,
                clientId: clientName,
                date: manualDates || date,
                datetimeCreated: editAppointmentData ? editAppointmentData?.apoointmentDetails?.datetimeCreated : datetimeCreated,
                dateCreated: editAppointmentData ? editAppointmentData?.apoointmentDetails?.dateCreated : dateCreated,
                duration: appointmentDuration,
                email: employeeEmail || selectedEmployee?.email,
                endTime: formatTime(endTime),
                firstName: clientNames?.firstName || selectedClient?.firstName,
                scheduledBy: auth?.userProfile?.email,
                frequency: frequencyData || "",
                lastName: clientNames?.lastName || selectedClient?.lastName,
                time: formatTime(startTime),
                type: appointmentType,
                numberOfTimes: numberOfTime,
                userId: empNames || selectedEmployee?.value,
                shiftName: shiftNames || selectedShift,
                calendarTimezone: employeeTimeZone || selectedEmployee?.timeZone || null,
                companyId: companyId
            };
            setApptdata(newAppointmentData);
            if (editAppointmentData && editAppointmentData !== undefined) {
                if (appointmentType === "One Time") {
                    setConfirmDialogOpen(true);
                    // const response = await updateAppointments(newAppointmentData, recurringDates, editAppointmentData?.id);
                    // if (response.success) {
                    //     setIsDialogOpen(true);
                    // } else {
                    //     console.error("Failed to save appointment:", response.error);
                    // }
                } else {
                    setConfirmDialogOpen(true);
                }
            } else {
                if (appointmentType === "Recurring Appointment") {
                    if (recurringDates && recurringDates.length > 0) {
                        const response = await saveAppointment(newAppointmentData, recurringDates);
                        if (response.success) {
                            setIsDialogOpen(true);
                        } else {
                            console.error("Failed to save appointment:", response.error);
                        }
                    } else {
                        setFrequencyData();
                        setNumberOfTime();
                        const newErrors = {
                            frequency: true,
                            numberOfTimes: true
                        };
                        setErrors(newErrors);
                    }
                } else {
                    const response = await saveAppointment(newAppointmentData);
                    if (response.success) {
                        setIsDialogOpen(true);
                    } else {
                        console.error("Failed to save appointment:", response.error);
                    }
                }
            }

            setLoader(false);
        } catch (error) {
            console.error("Error in handleAddAppointments:", error);
            setLoader(false);
        }
    };

    const hasTimeZoneConflict = clientNames?.timeZone && employeeTimeZone && clientNames.timeZone !== employeeTimeZone;

    const checkInTime = editAppointmentData?.checkInDateTime?.seconds 
    ? moment.unix(editAppointmentData.checkInDateTime.seconds).tz(editAppointmentData?.apoointmentDetails?.calendarTimezone).format("hh:mm a") : null;
    
    const checkOutTime = editAppointmentData?.checkOutDateTime?.seconds 
    ? moment.unix(editAppointmentData.checkOutDateTime.seconds).tz(editAppointmentData?.apoointmentDetails?.calendarTimezone).format("hh:mm a") : null;

    const DateTimeProps = {
        empNames, selectedDate, setSelectedDate, startTime, setStartTime, endTime, setEndTime, frequencyData, setFrequencyData, numberOfTime, setNumberOfTime, isInputVisible,
        setInputVisible, timeVisible, setTimeVisible, errors, setErrors, appointmentType, manualDate, setManualDate, handleAddTime, setRecurringDates, handleCancelTime,
        filterAppointmentOnTheDate, selectedMonth, selectedDay, selectedYear, setSelectedYear, setSelectedDay, setSelectedMonth, daysInMonth, setDaysInMonth, editAppointmentData, settingsData
    }

    return (
        <>
            <Container>
                    <PageTitle
                        pageTitle={`${appointmentId ? "ReSchedule Work" : "Schedule Work"}`}
                        showBreadcrumb={[
                            { label: "Home", to: "/" },
                            { label: "Appointments" },
                        ]}
                        BreadCrumbCurrentPageTitle={`${appointmentId ? "ReSchedule Work" : "Schedule Work"}`}
                        showLastSeen={true}
                    />
                <div className={` ${(isMobile || isLandscape ? 'sectionBox pv-35 ph-15' : 'sectionBox pv-40 ph-35')}`}>
                    <div>
                        <div className="w-100">
                            <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Client</Typography>
                            <div className={"mb-20 mt-10 width50"}>
                                <SingleSelect
                                    value={clientName}
                                    name={"clientName"}
                                    label={"Client Name"}
                                    onChange={handleClientNameChange}
                                    options={clientNameList}
                                    datetime={true}
                                    disabled={!!checkInTime || !!checkOutTime}
                                />
                                {errors.clientName && <div style={{ color: 'red' }}>Choose One</div>}
                            </div>
                            {/* <span className="ml-30"><Link className="link" to="/createClient">Add New Client</Link></span> */}
                        </div>
                        <div className={"w-100"}>
                            <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Shift</Typography>
                            <div className={"mb-20 mt-10 width50"}>
                                <SingleSelect
                                    value={shiftNames}
                                    name={"shiftName"}
                                    label={"Shift Name"}
                                    onChange={handleShiftNameChange}
                                    options={shiftNamesList}
                                    datetime={true}
                                    disabled={!!checkInTime || !!checkOutTime || clientName === "All"}
                                />
                                {errors.shiftName && <div style={{ color: 'red' }}>Choose One</div>}
                            </div>
                        </div>
                        <div className={"w-100"}>
                            <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Employee</Typography>
                            <div className={"mb-20 mt-10 width50"}>
                                <SingleSelect
                                    value={empNames}
                                    name={"employeeName"}
                                    label={"Employee Name"}
                                    onChange={handleEmpNameChange}
                                    options={employeeNameList}
                                    datetime={true}
                                    disabled={!!checkInTime || !!checkOutTime || shiftNames === "Any"}
                                />
                                {errors.empName && <div style={{ color: 'red' }}>Choose One</div>}
                            </div>
                        </div>
                    </div>
                   { !appointmentId ?
                    <AppointmentTypeToggle isMobile={isMobile} isLandscape={isLandscape} appointmentType={appointmentType} setAppointmentType={setAppointmentType} /> : null }

                    <DateAndTimeSelector
                        DateTimeProps={DateTimeProps}
                    />

                    {isMobile || isLandscape ? (
                        <div className="pt-15" style={{ color: "red", textDecoration: "underline" }}>
                            "Appointment Conflict"
                        </div>
                    ) : null}

                    {appointmentType === "Recurring Appointment" && Array.isArray(recurringDates) && recurringDates.length > 0 ? (
                        recurringDates.map((date, index) => {
                            const formattedDate = moment(date).format("MMMM DD, YYYY");

                            const hasConflict = appoinments?.appointments.some(appointment =>
                                appointment.apoointmentDetails?.date === formattedDate && appointment.apoointmentDetails?.clientId === clientName
                            );

                            return (
                                <div
                                    key={index}
                                    className="d-flex flex-space-between flex-center"
                                    style={{ width: isMobile || isLandscape ? "100%" : "60%" }}
                                >
                                    <div className={`${isMobile || isLandscape ? "mr-30 mv-10" : "mv-10"}`}>
                                        {`${settingsData?.timeFormat === '24hrs' ? moment(startTime, 'h:mm A').format('HH:mm') : startTime} on ${moment(date).format("MMMM DD, YYYY")} (${moment(date).format('ddd')})`} 
                                    </div>
                                    <div
                                        className="d-flex flex-space-between flex-center"
                                        style={{ width: isMobile || isLandscape ? "100%" : "55%" }}
                                    >
                                        <div
                                            className={`${isMobile || isLandscape ? "mr-15 link" : "link"}`}
                                            onClick={() => handleRemoveDate(date)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            remove
                                        </div>

                                        {hasConflict && !isMobile && !isLandscape ? (
                                            <span style={{ color: "red", textDecoration: "underline" }}>
                                                Appointment Conflict
                                            </span>
                                        ) : null}

                                        {hasTimeZoneConflict ? (
                                            <span style={{ color: "red", textDecoration: "underline", marginLeft: "10px" }}>
                                                TimeZone Conflict
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="mv-10"></div>
                    )}


                    {upcomingAppoinments.length > 0 ? (
                        <div className={`${isMobile || isLandscape ? "mt-30 mb-15" : "mv-30"}`}>
                            <Typography
                                variant="body"
                                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}>Appointment Conflict</Typography>
                            <div className="d-flex flex-center appointments-container  mt-15">
                                {upcomingAppoinments.map((appointment, index) => (
                                    <div key={index} className="appointment-card">
                                        <div className="d-flex flex-direction-column" style={{ width: "110px" }}>
                                            <div className="client-name" >
                                                {selectedClient.label !== "All" ? selectedClient.label : ""}</div>
                                            <div className="appointment-date">{`${formatDate(appointment?.date)}, ${appointment.time}`}</div>
                                            <div className="link ml-2 mv-3">View Details</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="mv-10"></div>
                    )}

                    {hasTimeZoneConflict && (
                        <div className={`${isMobile || isLandscape ? "w-100 mt-10" : "w-35"}`}>
                            <Typography
                                variant="body"
                                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}>
                                TimeZone Conflict
                            </Typography>
                            <div className="p-8 mt-10" style={{ marginRight: '20px', border: "1px solid red", borderRadius: "3px", fontSize: '14px', fontWeight: '600', color: '#3d3d3d' }}>
                                Client and staff are in different time zones.
                            </div>
                        </div>
                    )}

                    <div className="mt-30">
                        <Button variant="contained" style={{ backgroundColor: settingsData?.schedulingSystem === 'acuity' ? 'gray' : 'green', color: 'white', boxShadow: 'none' }} onClick={handleAddAppointments} disabled={settingsData?.schedulingSystem === 'acuity'}>
                            {loader ? (<CircularProgress size={28} style={{ color: 'white' }} />) : appointmentId ? ('ReSchedule Appointment') : ('Book Appointment')}
                        </Button>
                        <Button variant="contained" className="ml-20" style={{ backgroundColor: settingsData?.schedulingSystem === 'acuity' ? 'gray' : 'red', color: 'white', boxShadow: 'none' }} onClick={handelancelData} disabled={settingsData?.schedulingSystem === 'acuity'}>
                            Cancel
                        </Button>
                        {appointmentId ? <Button variant="contained" className="ml-20" style={{ backgroundColor: '#1369ff', color: 'white', boxShadow: 'none' }} onClick={handelNavigate}>
                            Close
                        </Button> : null }
                    </div>
                </div>
            </Container>
            <CancelApptDailog
                open={cancelDialogOpen}
                onConfirm={handelOpenDialog}
                onClose={handelCloseDialog}
            />
            {isDialogOpen && (
                <SuccessApptDialog open={isDialogOpen} onClose={handelSuccelCloseDialog} modal={appointmentId}/>
            )}
            {confirmDialogOpen && (
                <ConfirmationApptDialog open={confirmDialogOpen} onSingleAppt={handelSingleApptConfirmDialog} onConfirm={handelSeriesApptConfirmDialog} modal={appointmentId}
                    auth={editAppointmentData} update={updateAppointmentData} onClose={handelConfirmCloseDialog} client={clientNameList} data={aaptData}/>
            )}
        </>
    );
}

export default AppoinmentSchedule;