
import React, { useContext } from 'react';
import { Card, Divider, List, Typography, Collapse, IconButton } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faImage, faUsers } from '@fortawesome/free-solid-svg-icons';
import { getFormatedTime, getTimeZoneDate } from '../../../services/helpers';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AuthContext from '../../../hooks/authContext';

const IssueLabel = ({ issueId }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        Issue# : {issueId}
    </Typography>
);

const IssueCommonCard = ({ index, record, isOpen, handleExpandClick, redirectToDetails, columns, issue, issueUnarchive, handelUnArchive, settingsData }) => {
    const hasMainAttachments = record?.attachments && record?.attachments.length > 0;
    const hasResponseAttachments = Object.values(record?.responses || {}).some(
        response => response?.attachments && response?.attachments.length > 0
    );
    const auth = useContext(AuthContext);

    return (
        <React.Fragment key={`record-${index}`}>
            <Card style={{ boxShadow: "none", borderRadius: "0px", border: "1px solid #a9a9a9", width: "97%" }} className="mb-20">
                <div className="d-flex flex-center flex-space-between pv-5 pl-15 pr-8" style={{ backgroundColor: "#efefef" }}>
                    {issue === true || issueUnarchive === true ? (
                        <IssueLabel issueId={record?.isuueId} />
                    ) : null}
                    <div>
                        <MoreHorizRoundedIcon className="color-primary" fontSize="large" onClick={() => redirectToDetails(record?.id)} />
                    </div>
                </div>
                <Divider />
                <List className="mv-15">
                    {columns.filter(column => !column.isCollapsible).map((column, columnIndex) => (
                        <div className="d-flex flex-space-between ph-25 pv-10" key={`column-${columnIndex}`}>
                            {columnIndex === 0 ? (
                                <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#3d3d3d" }}>
                                    {column?.label}
                                </Typography>
                            ) : (
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column?.label}
                                </Typography>
                            )}
                            <div className="w-45">
                                {columnIndex === 0 ? (
                                    <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#606060" }}>
                                        {record[column?.id]}
                                    </Typography>
                                ) : (
                                    <Typography variant="body" style={{ color: "#606060" }}>
                                        {record[column?.id]}
                                    </Typography>
                                )}

                                {column?.id === "priority" && issue === true && (
                                    <>
                                        {record?.priority === "High" ? (
                                            <FontAwesomeIcon title="High Priority" className="color-danger mh-5" icon={faExclamation} />
                                        ) : (
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" className="svg-inline--fa fa-exclamation fa-w-6 color-danger mh-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                                                <path fill="currentColor" />
                                            </svg>
                                        )}
                                        {record?.escalated_to_admin && Boolean(record?.escalated_to_admin) && (
                                            <FontAwesomeIcon title="Escalate to admin" className="mh-5 color-black" icon={faUsers} />
                                        )}
                                        {(hasMainAttachments || hasResponseAttachments) && (
                                            <FontAwesomeIcon title="Attachment Available" className="mh-5 color-black" size="lg" icon={faImage} />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}

                    {!isOpen && issueUnarchive ? <div className="d-flex flex-space-between ph-25 pv-10">
                        <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}> UnArchive </Typography>
                        <Typography variant="body" style={{ color: "#606060" }} className="w-45 d-flex flex-center">
                            <IconButton onClick={() => handelUnArchive(record)} disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                                style={{
                                    color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green",
                                    padding: "0%"
                                }}>
                                <UnarchiveIcon />
                            </IconButton>
                            <span
                                className={`link ${!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "disabled" : ""}`}
                                onClick={() => {
                                    if (settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)) {
                                        handelUnArchive(record);
                                    }
                                }}
                                style={{
                                    pointerEvents: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "none" : "auto",
                                    color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "blue",
                                    cursor: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "not-allowed" : "pointer"
                                }}
                            >
                                Unarchive
                            </span>
                        </Typography>
                    </div> : null}

                    <Collapse in={isOpen} timeout="auto" unmountOnExit className="w-100">
                        {columns.filter(col => col.isCollapsible).map((column, columnIndex) => (
                            <div className="d-flex flex-space-between ph-25 pv-10" key={`collapsible-column-${columnIndex}`}>
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column.label}
                                </Typography>
                                <Typography variant="body" style={{ color: "#606060" }} className="w-45">
                                    {column?.id === "lastupdated" && issue === true ? (
                                        getTimeZoneDate(record?.updatedAt, record?.timeZone)
                                    ) : column?.id === "openedOn" && issueUnarchive === true ? (
                                        getTimeZoneDate(record?.openedOn, record?.timeZone)
                                    ) : column?.id === "closedOn" && issueUnarchive === true ? (
                                        getTimeZoneDate(record?.closedOn, record?.timeZone)
                                    ) : column?.id === "lastupdatedtime" && issue === true ? (
                                        getFormatedTime(record?.updatedAt, record?.timeZone)
                                    ) : column?.id === "unArchive" && issueUnarchive === true ? (
                                        <>
                                            <div className='d-flex flex-center'>
                                                <IconButton onClick={() => handelUnArchive(record)} disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                                                    style={{
                                                        color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "green",
                                                        padding: "0%"
                                                    }}>
                                                    <UnarchiveIcon />
                                                </IconButton>
                                                <span
                                                    className={`link ${!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        if (settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)) {
                                                            handelUnArchive(record);
                                                        }
                                                    }}
                                                    style={{
                                                        pointerEvents: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "none" : "auto",
                                                        color: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "gray" : "blue",
                                                        cursor: !settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role) ? "not-allowed" : "pointer"
                                                    }}
                                                >
                                                    Unarchive
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        record[column.id]
                                    )}
                                </Typography>
                            </div>
                        ))}
                    </Collapse>
                </List>
                <Divider />
                <div className="d-flex flex-justify-center">
                    <IconButton className="p-0" onClick={() => handleExpandClick(record?.id)}>
                        <ExpandMoreIcon
                            fontSize="large"
                            style={{
                                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                        />
                    </IconButton>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default IssueCommonCard;