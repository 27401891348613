import { Button, Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import styles from "../Accounts.module.scss"
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { useMediaQuery } from "@mui/material";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import { getSettings, resetSettings, saveSettings, updateSettings } from "../../../services/apiservices/settings";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import { ResetSettingDialog, ResetSettingsSuccessDialog, SuccessSettingsDialog, UpdateSettingsDialog } from "../../../components/DialogComponent/SupplyDataDialog";
import { useNavigate } from "react-router";


const AllowPublicPageShow = ({ isMobile, isLandscape, setPublicPageType, publicPageType }) => {

    const handleToggle = (type) => {
        setPublicPageType(type);
    };

    return (
        <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
            <Button
                variant={publicPageType === 'Yes' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('Yes')}
                style={{
                    backgroundColor:
                        publicPageType === 'Yes' ? '#27ae60' : '#E0E0E0',
                    color: publicPageType === 'Yes' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '5px 0 0 5px',
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    border: 0,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Yes
            </Button>
            <Button
                variant={
                    publicPageType === 'No' ? 'contained' : 'outlined'
                }
                onClick={() => handleToggle('No')}
                style={{
                    backgroundColor:
                        publicPageType === 'No' ? '#27ae60' : '#E0E0E0',
                    color: publicPageType === 'No' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '0 5px 5px 0',
                    border: 0,
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                No
            </Button>
        </div>
    );
};


const IssuePageSetting = () => {
    const auth = useContext(AuthContext);
    const commonContext = useContext(CommonContext)
    const [autoArchiveRequest, setAutoArchiveRequest] = useState('notAllowed');
    const [archiveRequestRole, setArchiveRequestRole] = useState([]);
    const [escalateRequestRole, setEscalateRequestRole] = useState([]);
    const [publicPageType, setPublicPageType] = useState('No');
    const [error, setError] = useState({});
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
    const navigate = useNavigate();

    const autoArchiveOptions = [
        { label: "Not Allowed", value: "notAllowed" },
        { label: "15 Days", value: "15 Days" },
        { label: "30 Days", value: "30 Days" },
        { label: "60 Days", value: "60 Days" },
        { label: "90 Days", value: "90 Days" },
    ];

    const Roles = [
        { label: "ADMIN", value: "admin" },
        { label: "SUPPORT", value: "support" },
    ];
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const fetchSettings = () => {
        getSettings('issue_settings', (res) => {
            const companyId = auth?.userProfile?.role === "companyUser"
                ? auth?.userProfile?.id
                : auth?.userProfile?.companyId;

            if (res?.data) {
                const filteredData = res.data.filter(
                    (item) => item.companyId === companyId && !item.deleted
                );

                if (filteredData.length > 0) {
                    const [firstItem] = filteredData;
                    setData(filteredData);
                    setAutoArchiveRequest(firstItem?.autoArchiveRequest);
                    setArchiveRequestRole(firstItem?.archiveRequestRole);
                    setEscalateRequestRole(firstItem?.escalateRequestRole);
                    setPublicPageType(firstItem?.publicPageType);
                } else {
                    setData();
                    setAutoArchiveRequest('notAllowed');
                    setArchiveRequestRole([]);
                    setEscalateRequestRole([]);
                    setPublicPageType('No');
                }
            } else {
                setData();
                setAutoArchiveRequest('notAllowed');
                setArchiveRequestRole([]);
                setEscalateRequestRole([]);
                setPublicPageType('No');
            }
        });
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const handleAutoRequestChange = (e) => {
        const data = e.target.value;
        setAutoArchiveRequest(data);
    }

    const handleArchiveRequestRoleChange = (e) => {
        setArchiveRequestRole(e);
    }

    const handleEscalateRequestRoleChange = (e) => {
        setEscalateRequestRole(e);
    }

    const validFields = () => {
        const errormessages = {};
        let isValid = true;

        if (!autoArchiveRequest) {
            isValid = false;
            errormessages.autoArchiveRequest = "Auto Archive Request is Required";
        }
        if (!archiveRequestRole.length) {
            isValid = false;
            errormessages.archiveRequestRole = "Archive Request Role is Required";
        }
        if (!escalateRequestRole.length) {
            isValid = false;
            errormessages.escalateRequestRole = "Escalate Request Role is Required";
        }

        setError(errormessages);
        return isValid;
    };

    const handleSaveData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

            const dataToSave = {
                autoArchiveRequest: autoArchiveRequest,
                archiveRequestRole: archiveRequestRole,
                escalateRequestRole: escalateRequestRole,
                publicPageType: publicPageType,
                companyId: companyId
            };

            const data = await saveSettings(dataToSave, 'issue_settings');
            if (data.status === true) {
                commonContext?.setLoader(false);
                setOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };

    const handleUpdateData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const dataToSave = {
                autoArchiveRequest: autoArchiveRequest,
                archiveRequestRole: archiveRequestRole,
                escalateRequestRole: escalateRequestRole,
                publicPageType: publicPageType,
            };
            const id = data[0]?.id;

            const details = await updateSettings(dataToSave, 'issue_settings', id);
            if (details.status === true) {
                commonContext?.setLoader(false);
                setUpdateOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };

    const handleResetConfirm = async () => {
        setResetOpen(false);
        commonContext?.setLoader(true);
        const id = data[0]?.id;
        const details = await resetSettings('issue_settings', id);
        if (details.status === true) {
            commonContext?.setLoader(false);
            setResetSuccessOpen(true);
        }
        commonContext?.setLoader(false);
    }

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleResetSuccessClose = () => {
        setResetSuccessOpen(false);
        fetchSettings();
    }

    const handleColse = () => {
        setOpen(false);
        navigate("/settings");
    }

    const handleUpdateClose = () => {
        setUpdateOpen(false);
        navigate("/settings");
    }

    const handleReset = () => {
        if (data) {
            setResetOpen(true);
        } else {
            setData();
            setAutoArchiveRequest('notAllowed');
            setArchiveRequestRole([]);
            setEscalateRequestRole([]);
            setPublicPageType('No');
            setError({});
        }
    };

    const handleCancel = () => {
        if (data) {
            navigate("/settings");
        } else {
            setData();
            setAutoArchiveRequest('notAllowed');
            setArchiveRequestRole([]);
            setEscalateRequestRole([]);
            setPublicPageType('No');
            setError({});
        }
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle={`Issue Settings`}
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Settings", to: "/settings" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Issue"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                        <div className={styles.widths}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Auto Archive Request</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={autoArchiveRequest}
                                        name={"autoArchiveRequest"}
                                        label={"Auto Archive Request"}
                                        onChange={handleAutoRequestChange}
                                        options={autoArchiveOptions}
                                    />
                                    {error.autoArchiveRequest && (
                                        <span className="d-flex small error color-danger">{error.autoArchiveRequest}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Archive Request Role</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={archiveRequestRole}
                                        name={"archiveRequestRole"}
                                        label={"Archive Request Role"}
                                        onChange={(e) => handleArchiveRequestRoleChange(e)}
                                        options={Roles}
                                        selectAllOption={true}
                                        defaultSelectAll={true} 
                                    />
                                    {error.archiveRequestRole && (
                                        <span className="d-flex small error color-danger">{error.archiveRequestRole}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Escalate Request Role</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={escalateRequestRole}
                                        name={"escalateRequestRole"}
                                        label={"Escalate Request Role"}
                                        onChange={(e) => handleEscalateRequestRoleChange(e)}
                                        options={Roles}
                                        selectAllOption={true}
                                        defaultSelectAll={true}
                                    />
                                    {error.escalateRequestRole && (
                                        <span className="d-flex small error color-danger">{error.escalateRequestRole}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Enable Public Page View</div>
                                <AllowPublicPageShow
                                    isMobile={isMobile}
                                    isLandscape={isLandscape}
                                    setPublicPageType={setPublicPageType}
                                    publicPageType={publicPageType}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mv-15">
                        <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={data ? handleUpdateData : handleSaveData}>{data ? "Update" : "Save"}</Button>
                        <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                    </div>
                </div>
            </Container>
            {open && <SuccessSettingsDialog open={open} onClose={handleColse} />}
            {updateOpen && <UpdateSettingsDialog open={updateOpen} onClose={handleUpdateClose} />}
            {resetOpen && <ResetSettingDialog open={resetOpen} onConfirm={handleResetConfirm} onClose={handleResetClose} />}
            {resetSuccessOpen && <ResetSettingsSuccessDialog open={resetSuccessOpen} onClose={handleResetSuccessClose} />}
        </>
    )
}

export default IssuePageSetting;