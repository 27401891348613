import React from "react";
import { useNavigate } from "react-router";
import { saveNotification2 } from "../../../../services/apiservices/notifications";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { addNotes, fetchNotes } from "../../../../services/apiservices/supplies";
import { convertFirestoreTimestampToDate, formatDate } from "../../../../services/helpers";
import { DOMAINURL_SUPPIESEMAIL } from "../../../../services/config";

export function NotesSection({ notes, onAddNote, employee, createdBy, orderId, setNotes, data, empData, clientData, commonContext, companyId, categoryMap }) {
  const [newNote, setNewNote] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const supply = true;
  const clientName = clientData ? clientData[0].label : null;
  const userData = employee?.filter((item) => item?.value === createdBy);


  let updatedTitles = [];
  const category = categoryMap[data?.id];
  if (category) {
    updatedTitles = data?.supplyItems.map((supplyItem, index) => {
      const categoryItem = category.find(cat => cat?.parentCategoryId === supplyItem?.parentCategoryId);
      const title = categoryItem ? `${categoryItem?.title} - ${supplyItem?.title}` : `${supplyItem?.title}`;
      return `${index + 1}. ${title} (${supplyItem?.qty})`;
    });
  }

  const sendPushAndEmailNotification = async (orderNumber, name, email) => {
    const formattedMessage = `New Message - Supply #${orderNumber}. Check for more details.`;
    const link = `https://${DOMAINURL_SUPPIESEMAIL}/SuppliesNote/${orderId}`
    const notifications = [
      {
        Type: "PushNotification",
        Message: formattedMessage,
        read: false,
        companyId: companyId,
        Employee: [
          {
            id: data?.createdBy,
            name: name
          }
        ],
        extData: { type: "SuppliesNote", id: orderId }
      },
      {
        Type: "Email",
        Message: `
          <strong>Client:</strong> ${clientName}<br>
          <strong>Order ID:</strong> ${orderNumber}<br>
          <strong>Status:</strong> ${data?.status}<br>
          <strong>Supplies:</strong> <br>${updatedTitles.join('<br>')}<br>
          <p></p>
          <p></p>
          <p></p><br>
          <p>${newNote.trim()}</p>
          <p></p>
          <p>To respond to this message, please click <a href="${link}" target="_blank">here</a> to open the issue the <span style="white-space: nowrap;">proteams.<wbr>io</span> app.</p><br>
          ProTeams Support Team.`,
        read: false,
        companyId: companyId,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];
    await saveNotification2(notifications, supply, orderNumber, clientName, orderId);
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    const note = {
      createBy: createdBy,
      note: newNote.trim(),
      orderId: orderId,
      companyId: companyId
    };

    let orderNumber = String(data.orderNumber).padStart(6, '0');
    const employee = empData.find(emp => emp.value === data.createdBy);
    const name = employee ? employee.label : '';
    const email = employee ? employee.email : '';

    try {
      commonContext?.setLoader(true);
      const notificationPromise = sendPushAndEmailNotification(orderNumber, name, email);
      const addedNotePromise = addNotes(note);
      const [addedNote] = await Promise.all([notificationPromise, addedNotePromise]);

      onAddNote && onAddNote(addedNote);
      commonContext?.setLoader(false);
      fetchNotes(orderId, (fetchedNotes) => {
        setNotes(fetchedNotes);
      });
    } catch (error) {
      commonContext?.setLoader(false);
      console.error('Error adding note: ', error);
    }
    setNewNote('');
  };

  const handleCancel = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setDialogOpen(false);
    navigate(-1);
    setNewNote('');
  };

  const createBy = (id, employees) => {
    const employee = employees.find(item => item.value === id);
    const data = employee ? employee.label : "";
    return data;
  };

  const sortedNotes = [...notes].sort((a, b) => {
    const dateA = convertFirestoreTimestampToDate(a.createdAt);
    const dateB = convertFirestoreTimestampToDate(b.createdAt);
    return dateB - dateA;
  });

  return (
    <>
      <div className="note-input mb-30">
        <TextField
          label="Add Notes"
          multiline
          rows={3}
          variant="outlined"
          className="mt-15"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          fullWidth
          disabled={userData?.[0]?.status === false}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddNote}
          className="mt-15"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={handleCancel}
          className="mt-24 m-10"
        >
          Cancel
        </Button>
      </div>

      <strong className="mt-20"> Notes </strong>
      <hr className="note-divider mb-25" />
      <div className="notes-section">
        {sortedNotes[0]?.message ? (
          <p>No Notes Available</p>
        ) : (
          sortedNotes.map((note, index) => (
            <div key={index} className="note">
              <p>{note?.note}</p>
              <p>
                <strong>Date:</strong> {formatDate(note?.createdAt)}{' '}
                <strong className="ml-10">Created By:</strong> {createBy(note?.createBy, employee)}
              </p>
              {index < sortedNotes.length - 1 && <hr className="note-divider" />}
            </div>
          ))
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
