/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../hooks/commonContext";
import { Button, Container, Dialog, DialogActions, DialogContent, useMediaQuery } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { fetchNotes, getCategories, getSupplyOrderListBasedOnId, updateArchive, updateSuppliesStatus, updateSupplyItemsStatus } from "../../../services/apiservices/supplies";
import SupplyRequestDetails, { SupplyRequestDetailsResponsive } from "./ManageSupplyRequestDetails/SupplyRequestDetails";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import { getAllEmployee, getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { convertFirestoreTimestampToDate, formatDate } from "../../../services/helpers";
import { SupplyArchivesDetailsResponsive } from "../../../components/ResponsiveCardComponent/SupplyComponent/ManageSupplyDetailsResponsive";
import AuthContext from "../../../hooks/authContext";
import { getSettings } from "../../../services/apiservices/settings";

const SuccessDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent>
                This order has been successfully UnArchived. You can view the order in Manage Supplies.
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const NotesSection = ({ notes, employee }) => {
    const createBy = (id, employees) => {
        const employee = employees.find(item => item.value === id);
        const data = employee ? employee.label : "";
        return data;
    };

    const sortedNotes = [...notes].sort((a, b) => {
        const dateA = convertFirestoreTimestampToDate(a.createdAt);
        const dateB = convertFirestoreTimestampToDate(b.createdAt);
        return dateB - dateA;
    });

    return (
        <>
            <strong> Notes </strong>
            <hr className="note-divider mb-25" />
            <div className="notes-section">
                {sortedNotes[0]?.message ? (
                    <p>No Notes Available</p>
                ) : (
                    sortedNotes.map((note, index) => (
                        <div key={index} className="note">
                            <p>{note?.note}</p>
                            <p>
                                <strong>Date:</strong> {formatDate(note?.createdAt)}{' '}
                                <strong className="ml-10">Created By:</strong> {createBy(note?.createBy, employee)}
                            </p>
                            {index < sortedNotes.length - 1 && <hr className="note-divider" />}
                        </div>
                    ))
                )}
            </div>
        </>
    );
};


const SuppliesArchiveRequestDetails = (props) => {
    const commonContext = useContext(CommonContext);
    const auth = useContext(AuthContext);
    const { requestId } = useParams();
    const [requestData, setRequestData] = React.useState({});
    const [allSupplies, setAllSupplies] = React.useState([]);
    const [categories, setCategories] = useState({});
    const [notes, setNotes] = useState([]);
    const [empName, setEmployeeName] = useState();
    const [empData, setEmpData] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [settingsData, setSettingsData] = useState();
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

    const getRequestData = (requestId) => {
        if (typeof requestId !== "undefined" && requestId !== "") {
            commonContext?.setLoader(true);

            getSupplyOrderListBasedOnId(
                requestId,
                async (res) => {
                    if (!res) {
                        setRequestData({});
                        setAllSupplies([]);
                        commonContext?.setLoader(false);
                        return;
                    }

                    await fetchAndStoreCategories(res?.supplyItems);

                    const deliveryStatus = await getDeliveryStatus(res?.supplyItems);

                    let clientIds = [res.clientId];
                    let empIds = [res.createdBy];

                    let clintsData = [];
                    await getClientsListbasedonIds(clientIds, (result) => {
                        const clients = result.map((item) => {
                            return { value: item?.id, label: item?.clientName };
                        });
                        clintsData = clients;
                    });

                    let employeeData = [];
                    await getEmployeesListBasedOnId(empIds, (result) => {
                        const employees = result.map((item) => {
                            return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}`, status: item.status };
                        });
                        setEmpData(employees);
                        employeeData = employees;
                    });

                    await getAllEmployee(
                        (result) => {
                            const employees = result.data.map((item) => {
                                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
                            });
                            setEmployeeName(employees);
                        });

                    const client = clintsData.find(client => client?.value === res?.clientId);
                    const clientName = client ? client.label : 'Unknown Client';

                    const employee = employeeData.find(emp => emp?.value === res?.createdBy);
                    const employeeName = employee ? employee.label : 'Unknown Employee';

                    let priorityLabel;
                    switch (res.priority) {
                        case "1":
                            priorityLabel = "High";
                            break;
                        case "2":
                            priorityLabel = "Medium";
                            break;
                        case "3":
                            priorityLabel = "Low";
                            break;
                        default:
                            priorityLabel = "Unknown";
                    }

                    const finaleData = {
                        ...res,
                        priority: priorityLabel,
                        orderNumber: String(res?.orderNumber).padStart(6, '0'),
                        clientName: clientName,
                        employeeName: employeeName,
                        deliveryStatus: deliveryStatus
                    };

                    setRequestData(finaleData);
                    setAllSupplies(finaleData?.supplyItems);

                    commonContext?.setLoader(false);
                },
                (resError) => {
                    commonContext?.setLoader(false);
                }
            );
        } else {
            setRequestData({});
            setAllSupplies([]);
            commonContext?.setLoader(false);
        }
    };

    useEffect(() => {
        getRequestData(requestId);
        getSettings('supplie_settings', (res) => {
            if (res?.data.length > 0) {
                const filteredData = res?.data?.filter(
                    (item) => item?.companyId === companyId && !item.deleted
                );
                const data = filteredData?.[0];
                setSettingsData(data)
              } else {
                setSettingsData();
            }
        })
    }, [requestId]);

    useEffect(() => {
        const loadNotes = async () => {
            if (!requestData?.id) {
                setNotes([{ message: 'No ID provided' }]);
                return;
            }

            fetchNotes(
                requestData.id,
                (fetchedNotes) => {
                    setNotes(fetchedNotes);
                },
                (error) => {
                    console.error('Error fetching notes: ', error);
                    setNotes([{ message: 'Error fetching notes' }]);
                }
            );
        };

        loadNotes();
    }, [requestData?.id])

    const fetchAndStoreCategories = async (items) => {
        for (const item of items) {
          if (item?.parentCategoryId) {
            await getCategories((categoriesData) => {
              
              if (Array.isArray(categoriesData)) {
                const category = categoriesData.find(cate => cate?.id === item.parentCategoryId && cate?.companyId === companyId);
                const title = category?.title;
      
                if (title) {
                  setCategories(prevCategories => ({
                    ...prevCategories,
                    [item.parentCategoryId]: title,
                  }));
                }
              } else {
                console.error('Expected categoriesData to be an array, but got:', categoriesData);
              }
            });
          }
        }
      };

    const Columns = [
        {
            id: "categories",
            numeric: false,
            disablePadding: true,
            label: "Categories",
            isSortable: false,
        },
        {
            id: "supplies",
            numeric: false,
            disablePadding: false,
            label: "Suplies",
            isSortable: false,
        },

        {
            id: "deliveryDate",
            numeric: false,
            disablePadding: false,
            label: "Est. Delivery Date",
            isSortable: false,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
            isSortable: false,
        },
    ];
    const getDeliveryStatus = (supplyItems) => {
        if (!supplyItems || !Array.isArray(supplyItems)) {
            return 'Invalid Supplies';
        }

        const statuses = supplyItems.map(item => item.status);

        const hasDelivered = statuses.includes('Delivered');
        const hasShipped = statuses.includes('Shipped');
        const hasOrdered = statuses.includes('Ordered');
        const hasBackOrdered = statuses.includes('Back Ordered');
        const hasCancelItem = statuses.includes('Cancel Item');

        if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
            return 'Full Delivery';
        }
        if (statuses.every(status => status === 'Cancel Item')) {
            return 'cancelled';
        }
        if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
            return 'Partial Delivery';
        }
        if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
            return 'Full Shipment';
        }
        if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
            return 'Partial Shipment';
        }
        if (hasOrdered && hasCancelItem) {
            return 'In Process';
        }
        if (statuses.every(status => status === 'Ordered')) {
            return 'In Process';
        }
        if (statuses.every(status => status === 'Back Ordered')) {
            return 'Back Ordered';
        }
        if (hasOrdered && hasBackOrdered) {
            return 'In Process';
        }
        return 'In Process';
    };

    const handelUnArchive = async () => {
        const formData = {
            archive: false
        }
        const data = {
            status: "Approved"
        }

        try {
            if (requestData?.status === "Approved") {
                await updateArchive(requestData?.id, formData);
            } else {
                const promises = [
                    updateArchive(requestData?.id, formData),
                    updateSuppliesStatus(requestData?.id, data),
                ];
                await Promise.all(promises);
                await updateSupplyItemsStatus(requestData?.id, "Ordered", null);
            }
            setDialogOpen(true);
        } catch (error) {
            console.error("Error during unarchiving process:", error);
        }
    };

    const handleCloseDialog = async () => {
        setDialogOpen(false);
        navigate(-1);
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "categories":
                if (rowindex !== 0 && categories[rcellItem?.parentCategoryId] !== categories[requestData.supplyItems[rowindex - 1]?.parentCategoryId]) {
                    return <span>{categories[rcellItem?.parentCategoryId]}</span>
                }
                else if (rowindex === 0) {
                    return <span>{categories[rcellItem?.parentCategoryId]}</span>
                } else {
                    return "";
                }
            case "supplies":
                return `${rcellItem?.title} (${rcellItem?.qty})`;
            case "deliveryDate":
                return (
                    <>
                        <span>
                            {rcellItem?.deliveryDate ? formatDate(rcellItem?.deliveryDate) : "---"}
                        </span>
                    </>
                );
            case "status":
                return (
                    <span>{rcellItem?.status}</span>
                );

            default:
                return rcellItem[hcellItem?.id];
        }
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Supplies"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "View Supplies Request", to: "/supplies/cleaning/archives" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Supplies Request Details"}
                />

                <div className={`sectionBox`}>

                    {!isMobile && !isLandscape && (
                    <div className="d-flex flex-justify-end">
                        <Button
                            color="primary"
                            className="mr-20"
                            variant="contained"
                            onClick={handelUnArchive}
                            disabled={!settingsData?.archiveRequestRole?.includes(auth?.userProfile?.role)}
                        >
                            UnArchive
                        </Button>
                    </div>
                    )}

                    <div className={isMobile || isLandscape  ?  "" : "p-18"}>

                    {isMobile || isLandscape  ?  <SupplyRequestDetailsResponsive requestData={requestData}  empData={empData}/> : <SupplyRequestDetails requestData={requestData}  empData={empData}/>}

                        <SupplyArchivesDetailsResponsive
                            rows={allSupplies}
                            headCells={Columns}
                            hidePagination={true}
                            renderRowCell={renderRowCell}
                            supplies={true}
                            requestData={requestData}
                            categories={categories}
                            handelUnArchive={handelUnArchive}
                            settingsData={settingsData}
                        />

                        <NotesSection
                            notes={notes}
                            employee={empName}
                        />

                    </div>
                </div>
            </Container>
            <SuccessDialog open={dialogOpen} onClose={handleCloseDialog} />
        </>
    );
};

export default SuppliesArchiveRequestDetails;
