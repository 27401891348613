import { Container } from "@material-ui/core";
import React, { useEffect } from "react";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import { useParams } from "react-router";
import ThemeTable from "../../../../components/Table/ThemeTable";
import { getEmployee } from "../../../../services/apiservices/employee";
import { getProductDetails, getSettings } from "../../../../services/apiservices/settings";


const ManageCompanyDetails = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [empData, setEmpData] = React.useState();
    const [subscriptionData, setSubcriptionData] = React.useState();
    const [productData, setProductData] = React.useState();
    const [finaleData, setFinalData] = React.useState([]);
    const { companyId } = useParams();

    const Columns = [
        {
            id: "companyName",
            numeric: false,
            disablePadding: true,
            label: "Company Name",
            isSortable: true,
        },
        {
            id: "plan",
            numeric: false,
            disablePadding: true,
            label: "Plan Name",
            isSortable: true,
        },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Price",
            isSortable: true,
        },
        {
            id: "features",
            numeric: false,
            disablePadding: true,
            label: "Product Features",
            isSortable: true,
        },
        {
            id: "active",
            numeric: false,
            disablePadding: true,
            label: "Active",
            isSortable: true,
        },
    ];

    const formatPrice = (amount, currency) => {
        if (!amount || !currency) return "";
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase()
        }).format(amount / 100);
    };

    const getCompanyDetails = async () => {
        try {
            const [empRes, subRes] = await Promise.all([
                new Promise((resolve) => getEmployee(companyId, resolve)),
                new Promise((resolve) => getSettings('strip_subscription', resolve))
            ]);

            const empData = empRes?.data || null;
            const filteredSubData = subRes?.data?.filter((item) => item.customer === empData?.customer) || [];
            const subcriptionData = filteredSubData.length > 0 ? filteredSubData[0] : null;

            setEmpData(empData);
            setSubcriptionData(filteredSubData);

            let productData = null;
            if (subcriptionData?.plan?.product) {
                 await getProductDetails(subcriptionData.plan.product, (res)=>{
                     productData = res?.data || null;
                     setProductData(res?.data);
                 })
            }

            const data = {
                companyName: empData?.firstName,
                plan: subcriptionData?.plan?.name,
                active: subcriptionData?.plan?.active ? "Active" : "Disabled",
                price: formatPrice(subcriptionData?.plan?.amount || 0, subcriptionData?.plan?.currency || "usd"),
                features: productData?.features?.length || 4
            };

            setFinalData(data);
        } catch (error) {
            console.error("Error fetching company details:", error);
        }
    };

    useEffect(() => {
        getCompanyDetails();
    }, []);

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            default:
                return <p>{rcellItem[hcellItem?.id]} </p>;
        }
    }

    return (
        <Container>
            <PageTitle
                pageTitle={"Manage Company Details"}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "Company", to: "/company" },
                ]}
                BreadCrumbCurrentPageTitle={"Manage Company Details"}
                showLastSeen={true}
            />
            <div className="sectionBox">
                <ThemeTable
                    rows={[finaleData]}
                    headCells={Columns}
                    hidePagination={false}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                />
            </div>
        </Container>
    )
}

export default ManageCompanyDetails;