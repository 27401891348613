import { Button, Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useMediaQuery } from "@mui/material";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import styles from "../Accounts.module.scss";
import { getSettings, resetSettings, saveSettings, updateSettings } from "../../../services/apiservices/settings";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import { useNavigate } from "react-router";
import { ResetSettingDialog, ResetSettingsSuccessDialog, SuccessSettingsDialog, UpdateSettingsDialog } from "../../../components/DialogComponent/SupplyDataDialog";


const NotificationPageSetting = () => {
    const isMobile = useMediaQuery('(max-width:767px)');
    const [smsRoles, setSMSRoles] = useState([]);
    const [emailRoles, setEmailRoles] = useState([]);
    const [pushNotificationRoles, setPushNotificationRoles] = useState([]);
    const [error, setError] = useState({});
    const [data, setData] = useState();
    const auth = useContext(AuthContext);
    const commonContext = useContext(CommonContext);
    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
    const navigate = useNavigate();

    const Roles = [
        { label: "ADMIN", value: "admin" },
        { label: "SUPPORT", value: "support" },
    ]

    const fetchSettings = () => {
        getSettings('notification_settings', (res) => {
            const companyId = auth?.userProfile?.role === "companyUser"
                ? auth?.userProfile?.id
                : auth?.userProfile?.companyId;

            if (res?.data) {
                const filteredData = res.data.filter(
                    (item) => item.companyId === companyId && !item.deleted
                );

                if (filteredData.length > 0) {
                    const [firstItem] = filteredData;
                    setData(filteredData);
                    setSMSRoles(firstItem?.smsRoles);
                    setEmailRoles(firstItem?.emailRoles);
                    setPushNotificationRoles(firstItem?.pushNotificationRoles);
                } else {
                    setData();
                    setSMSRoles([]);
                    setEmailRoles([]);
                    setPushNotificationRoles([]);
                }
            } else {
                setData();
                setSMSRoles([]);
                setEmailRoles([]);
                setPushNotificationRoles([]);
            }
        });
    };
    

    useEffect(() => {
        fetchSettings();
    }, []);

    const validFields = () => {
        const errormessages = {};
        let isValid = true;

        if (!smsRoles.length) {
            isValid = false;
            errormessages.smsRoles = "SMS Roles is Required";
        }
        if (!emailRoles.length) {
            isValid = false;
            errormessages.emailRoles = "Email Roles is Required";
        }
        if (!pushNotificationRoles.length) {
            isValid = false;
            errormessages.pushNotificationRoles = "Push Notification Roles is Required";
        }

        setError(errormessages);
        return isValid;
    };

    const handleSaveData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

            const dataToSave = {
                smsRoles: smsRoles,
                emailRoles: emailRoles,
                pushNotificationRoles: pushNotificationRoles,
                companyId: companyId
            };

            const data = await saveSettings(dataToSave, 'notification_settings');
            if (data.status === true) {
                commonContext?.setLoader(false);
                setOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };

    const handleUpdateData = async () => {
        commonContext?.setLoader(true);
        if (!validFields()) {
            commonContext?.setLoader(false);
            return;
        } else {
            const dataToSave = {
                smsRoles: smsRoles,
                emailRoles: emailRoles,
                pushNotificationRoles: pushNotificationRoles,
            };
            const id = data[0]?.id;

            const details = await updateSettings(dataToSave, 'notification_settings', id);
            if (details.status === true) {
                commonContext?.setLoader(false);
                setUpdateOpen(true);
            }
            commonContext?.setLoader(false);
        }
    };


    const handleResetConfirm = async() => {
        setResetOpen(false);
        commonContext?.setLoader(true);
        const id = data[0]?.id;
        const details = await resetSettings('notification_settings', id);
        if (details.status === true) {
            commonContext?.setLoader(false);
            setResetSuccessOpen(true);
        }
        commonContext?.setLoader(false);
    }

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleResetSuccessClose = () => {
        setResetSuccessOpen(false);
        fetchSettings();
    }

    const handleColse = () => {
        setOpen(false);
        navigate("/settings");
    }

    const handleUpdateClose = () => {
        setUpdateOpen(false);
        navigate("/settings");
    }

    const handleReset = () => {
        if (data) {
            setResetOpen(true);
        } else {
            setEmailRoles([]);
            setData();
            setSMSRoles([]);
            setPushNotificationRoles([]);
            setError({});
        }
    };
    
    const handleCancel = () => {
        if (data) {
            navigate("/settings");
        } else {
            setEmailRoles([]);
            setData();
            setSMSRoles([]);
            setPushNotificationRoles([]);
            setError({});
        }
    };


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Notification Settings"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Settings", to: "/settings" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Notification Settings"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                        <div className={styles.widths}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>SMS</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={smsRoles ? smsRoles : []}
                                        name="smsRoles"
                                        label="SMS Roles"
                                        onChange={(value) => setSMSRoles(value)}
                                        options={Roles}
                                    />
                                    {error.smsRoles && (
                                        <span className="d-flex small error color-danger">{error.smsRoles}</span>
                                    )}
                                </div>
                            </div>

                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>EMAIL</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={emailRoles ? emailRoles : []}
                                        name="emailRoles"
                                        label="Email Roles"
                                        onChange={(value) => setEmailRoles(value)}
                                        options={Roles}
                                    />
                                    {error.emailRoles && (
                                        <span className="d-flex small error color-danger">{error.emailRoles}</span>
                                    )}
                                </div>
                            </div>

                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>PUSH NOTIFICATION</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={pushNotificationRoles ? pushNotificationRoles : []}
                                        name="pushNotificationRoles"
                                        label="Push Notification Roles"
                                        onChange={(value) => setPushNotificationRoles(value)}
                                        options={Roles}
                                    />
                                    {error.pushNotificationRoles && (
                                        <span className="d-flex small error color-danger">
                                            {error.pushNotificationRoles}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mv-15">
                        <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={data ? handleUpdateData : handleSaveData}>{data ? "Update" : "Save"}</Button>
                        <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                    </div>
                </div>
            </Container>
            {open && <SuccessSettingsDialog open={open} onClose={handleColse} />}
            {updateOpen && <UpdateSettingsDialog open={updateOpen} onClose={handleUpdateClose} />}
            {resetOpen && <ResetSettingDialog open={resetOpen} onConfirm={handleResetConfirm} onClose={handleResetClose} />}
            {resetSuccessOpen && <ResetSettingsSuccessDialog open={resetSuccessOpen} onClose={handleResetSuccessClose} />}
        </>
    )

}
export default NotificationPageSetting;