import React, { useContext, useState } from "react";
import List from "@material-ui/core/List";
import UpdateIcon from '@mui/icons-material/Update';
import { Grid, Link, Typography } from "@material-ui/core";
import { getDocCountAl } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import moment from "moment";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import AuthContext from "../../../../hooks/authContext";


export default function Alerts() {
  const auth = useContext(AuthContext);
  const [alertData, setAlertData] = useState([]);
  const [alertType, setAlertType] = useState("nocheckin");
  const alertList = [
    { label: "Employee - No Check In", value: "nocheckin" },
    { label: "Employee - No Check Out", value: "nocheckout" },
    { label: "Client Added To Emp", value: "emptocl" },
    { label: "Client Removed from Emp", value: "emptoclr" },
    { label: "Employee - Late Check In", value: "checkinlate" },
    { label: "Client - New", value: "newcl" },
  ];
  const onChangeInput = async (e) => {
    setAlertType(e.target.value);
  };
  React.useEffect(() => {
    getDocCountAl(
      COLLECTIONS.ALERT,
      (res) => {
        const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
        const data = res?.filter((item)=> item.companyId === companyId);
        const sortedActivities = data.sort((a, b) => {
          let timr =
            a.createdOn.seconds * 1000 + a.createdOn.nanoseconds / 1000000;
          let timrt =
            b.createdOn.seconds * 1000 + b.createdOn.nanoseconds / 1000000;
          return timrt - timr;
        });

        setAlertData(res ? sortedActivities : []);
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  const chagedtime = (time) => {
    const fireBaseTime = moment(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );
    const timeno = moment(fireBaseTime);
    const nowtime = moment.tz(timeno, "America/New_York").format();
    var duration = ` ${moment(nowtime).format("MMM DD, YY")} ${"\n"} ${moment(
      nowtime
    ).format("hh:mm a")}`;

    return duration;
  };


  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={2}>
          <p className="p-0 m-0 mb-5 fonthe paddal">Alerts</p>
        </Grid>
        <Grid item xs={12} sm={10}>
          <SingleSelect
            value={alertType}
            className={`field newonr`}
            name={"alerttype"}
            onChange={(e) => onChangeInput(e)}
            options={alertList}
          />
        </Grid>
      </Grid>
      <List dense className="ulhide pl-5">
        {alertData
          .filter((value) => value.type === alertType)
          .map((value, index) => {
            if (alertType === value.type) {
              return (
                <Grid container spacing={1} key={value.id} className="alertm">
                  <Grid item xs={12} className="p-0">
                    <div className="mad d-flex flex-space-between mb-10">
                      {value?.empname &&
                        value.type !== "emptocl" &&
                        value.type !== "emptoclr" &&
                        value.type !== "newcl" ? (
                        <div className="mad">
                          <Typography
                            variant="body"
                            style={{ color: "#8d8d8d", fontWeight: "500" }}
                          >
                            {`${value.type === "newcl" ? "Created By:" : "Emp:"}`}{" "}
                          </Typography>
                          <Typography variant="body2" gutterBottom className="descol" alignLeft>
                            {value?.empname}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      <Grid item xs={4} className="d-flex flex-center">
                        <UpdateIcon className="posti svg-icon greencol mr-8" />
                        <Typography variant="body2">
                          {chagedtime(value?.createdOn)}
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {(value.type === "emptocl" || value.type === "emptoclr") && (
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          {typeof value?.clientname === "object"
                            ? value?.clientname
                              .map((itm) => itm.clientid)
                              .join(", ")
                            : value?.clientname?.length > 90
                              ? `${value?.clientname?.substring(0, 90)}...`
                              : value?.clientname}{" "}
                          <br />
                          {value.type === "emptoclr"
                            ? " removed from "
                            : " added to "}{" "}
                          <b>{value?.empname}</b>
                        </Typography>
                      )}

                      {value?.clientname &&
                        value.type !== "emptocl" &&
                        value.type !== "emptoclr" &&
                        typeof value?.clientname != "object" ? (
                        <div className="mad mb-15">
                          <Typography
                            variant="body"
                            style={{ color: "#8d8d8d", fontWeight: "500" }}
                          >
                            Client:{" "}
                          </Typography>
                          <Typography variant="body2" gutterBottom className="descol" alignLeft>
                            {value?.clientname}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}

                      <Typography
                        variant="body2"
                        gutterBottom
                        className=" mt-8"
                        alignLeft
                      >
                        {value?.title ? value?.title : ""}
                      </Typography>

                      {value.type === "newcl" ? (
                        <div className="mad">
                          Created By:
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="descol"
                            alignLeft
                          >
                            {value?.empname}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                      {(value.type === "nocheckin" || value.type === "checkinlate") &&
                        value.startTime && (
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="mt-8"
                            alignLeft
                          >
                            Start Time: {value?.startTime}
                          </Typography>
                        )}
                      {value.type === "nocheckout" && value.endTime && (
                        <>
                          <Typography> End Time: </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="descol"
                            alignLeft
                          >
                            {value?.endTime}
                          </Typography>
                        </>
                      )}
                      {value.type === "checkinlate" && value.checkInTime && (
                        <>
                          <Typography>Checked In: </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="descol"
                            alignLeft
                          >
                            {value?.checkInTime}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
      </List>
      <div className="mv-25"></div>
      <p className="viewLinks">
        <Link className="link" href="/teamAlerts">
          View all Alerts
        </Link>
      </p>

    </>
  );
}
