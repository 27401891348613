import { Button, Container, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styles from "../Accounts.module.scss";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import AuthContext from "../../../hooks/authContext";
import { getIndustriesList, getIndustryServices } from "../../../services/apiservices/supplies";
import { generateFirebaseId } from "../../../services/helpers";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getServicesDetails, getSettings, resetSettings, resetSuppliesIndustry, saveOrUpdateSuppliesIndustry, saveSettings, saveSuppliesIndustry, updateSettings } from "../../../services/apiservices/settings";
import { ResetSettingDialog, ResetSettingsSuccessDialog, SuccessSettingsDialog, UpdateSettingsDialog } from "../../../components/DialogComponent/SupplyDataDialog";
import { useNavigate } from "react-router";
import CommonContext from "../../../hooks/commonContext";

const AddServiceType = ({ open, onConfirm, onClose, newServiceType, setNewServiceType, editingBusiness, styles, errors }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <div className="d-flex flex-space-between flex-center">
                <Typography variant="body" className="mt-25 dialogTitle">
                    Add Service Type
                </Typography>
                <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider className="mt-15" />
            <DialogContent className="mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>
                <div className="form-container">
                    <input
                        type="text"
                        placeholder="Service Type Name"
                        value={newServiceType.name}
                        onChange={(e) => setNewServiceType(prev => ({ ...prev, name: e.target.value }))}
                        className={styles.textInput}
                    />
                    {errors.serviceTypeName && !newServiceType.name && (
                        <span className="d-flex small error color-danger">Service Type Name Required</span>
                    )}
                    <input
                        type="text"
                        placeholder="Service Type Description"
                        value={newServiceType.description}
                        onChange={(e) => setNewServiceType(prev => ({ ...prev, description: e.target.value }))}
                        className={styles.textInput}
                    />
                     {errors.serviceTypeDescription &&  !newServiceType.description && (
                        <span className="d-flex small error color-danger">Service Type Description Required</span>
                    )}
                </div>
            </DialogContent>
            <DialogActions className="mt-20 mb-15">
                <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                    {editingBusiness ? 'Update' : 'Add'}
                </Button>
                <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const EnableReporting = ({ isMobile, isLandscape, setEnableReporting, enableReporting }) => {

    const handleToggle = (type) => {
        setEnableReporting(type);
    };

    return (
        <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
            <Button
                variant={enableReporting === 'Yes' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('Yes')}
                style={{
                    backgroundColor:
                        enableReporting === 'Yes' ? '#27ae60' : '#E0E0E0',
                    color: enableReporting === 'Yes' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '5px 0 0 5px',
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    border: 0,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Yes
            </Button>
            <Button
                variant={
                    enableReporting === 'No' ? 'contained' : 'outlined'
                }
                onClick={() => handleToggle('No')}
                style={{
                    backgroundColor:
                        enableReporting === 'No' ? '#27ae60' : '#E0E0E0',
                    color: enableReporting === 'No' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '0 5px 5px 0',
                    border: 0,
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                No
            </Button>
        </div>
    );
};

const ClientPageSetting = () => {
    const commonContext = useContext(CommonContext);
    const auth = useContext(AuthContext);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [enableReporting, setEnableReporting] = useState('Yes');
    const [serviceTypes, setServiceTypes] = useState();
    const [servicesTasks, setServicesTasks] = useState();
    const [editingTask, setEditingTask] = useState(null);
    const [editingBusiness, setEditingBusiness] = useState(null);
    const [newTask, setNewTask] = useState({ taskName: '', description: '' });
    const [newIndustry, setNewIndustry] = useState({ name: '', description: '' });
    const [newServiceType, setNewServiceType] = useState({ name: '', description: '' });
    const [errors, setErrors] = useState({});
    const [industryName, setIndustryName] = useState();
    const [industry, setIndustry] = useState();
    const [industryNameList, setIndustryNameList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);
    const [subCategoryIds, setSubCategoryIds] = useState([]);
    const [filterData, setFilterData] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
    const navigate = useNavigate();
    const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;

    const fetchIndustries = async () => {
        if (filterData?.length) return;
        try {
            commonContext?.setLoader(true);
            const industriesRes = await new Promise((resolve, reject) =>
                getIndustriesList({}, (res) => res ? resolve(res) : reject("Failed to fetch industries"))
            );

            const sortedIndustries = sortingFunction(industriesRes);
            setIndustry(sortedIndustries);
            setCategoryIds(sortedIndustries?.map(item => item.id));
            setIndustryNameList(sortedIndustries?.map(item => ({ value: item.id, label: item.title })));
            await getIndustryServices({}, (res) => {
                if (res) {
                    const allCategoryIds = res.map(item => item.id);
                    setSubCategoryIds(allCategoryIds);
    
                    const matchedCategories = res.filter(item => sortedIndustries?.some(ind => ind.id === item.parentCategoryId));
                    setCategories(matchedCategories);
    
                    const findSubCategories = (parentIds) => {
                        let foundSubCategories = res.filter(item => parentIds.includes(item.parentCategoryId));
                        return foundSubCategories.length > 0
                            ? [...foundSubCategories, ...findSubCategories(foundSubCategories.map(item => item.id))]
                            : foundSubCategories;
                    };
    
                    setSubCategories(sortingFunction(findSubCategories(matchedCategories.map(item => item.id))));
                }
            });
        } catch (error) {
            console.error(error);
            setIndustryNameList([]);
        } finally {
            commonContext?.setLoader(false);
        }
    };

    const fetchServicesDetails = async (filteredData) => {
        if (!filteredData?.length || !filteredData[0]?.industry?.id) return;
    
        getServicesDetails(companyId, (res) => {
            if (!res?.data) return;
    
            const industryId = filteredData[0].industry.id;
            const serviceTypes = res.data.filter(item => item.parentCategoryId === industryId && !item.deleted);
            const servicesTasks = res.data.filter(item => item.parentCategoryId !== industryId && !item.deleted);
    
            setServiceTypes(sortingFunction(serviceTypes));
            setServicesTasks(sortingFunction(servicesTasks));
        });
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                commonContext?.setLoader(true);
    
                const settingsRes = await new Promise((resolve) =>
                    getSettings('client_settings', (res) => resolve(res.data))
                );

                const filteredData = settingsRes.filter(item => item.companyId === companyId && !item.deleted);
                setFilterData(filteredData.length ? filteredData : null);
    
                if (filteredData.length > 0) {
                    const selectedIndustry = filteredData[0]?.industry?.id || null;
                    setIndustry([filteredData[0]?.industry])
                    setIndustryName(selectedIndustry);
                    setIndustryNameList([{ value: filteredData[0]?.industry?.id, label: filteredData[0]?.industry?.title }])
                    setEnableReporting(filteredData[0]?.enableReporting || 'Yes');
                    await fetchServicesDetails(filteredData);
                } else {
                    await fetchIndustries();
                    setEnableReporting('Yes');
                }
            } catch (error) {
                console.error("Error fetching initial data:", error);
                setFilterData(null);
                setIndustry(null);
                setEnableReporting('Yes');
            } finally {
                commonContext?.setLoader(false);
            }
        };

        fetchInitialData();
    }, []);


    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.orders < b.orders) return -1;
            if (a.orders > b.orders) return 1;

            return 0;
        });

        return result;
    };

    // Get tasks for selected business
    const getBusinessTasks = (id) => {
        return servicesTasks.filter(task => task.parentCategoryId === id && !task.deleted);
    };

    // Add new industry
    const handleAddIndustry = () => {
        const newItemId = generateFirebaseId(categoryIds);
        if (newIndustry.name) {
            const business = {
                id: newItemId,
                title: newIndustry.name,
                orders: industry.length + 1,
                status: true,
            };
            setIndustry([...industry, business])
            const data = [
                ...industry,
                business
            ]
            const details = data?.map((item) => ({
                value: item.id,
                label: item.title,
            }));
            setIndustryNameList(details);
            setNewIndustry({ name: '', description: '' });
        }
    };

    const handleAddServiceType = () => {
        if (!industryName) {
            setDialogOpen(false);
            setErrors({ industry: true });
            return;
        }
    
        const newItemId = generateFirebaseId(subCategoryIds);
        const { name, description } = newServiceType;
    
        if (!name || !description) {
            setErrors({
                serviceTypeName: !name,
                serviceTypeDescription: !description
            });
            return;
        }
    
        const business = {
            id: newItemId,
            title: name,
            orders: serviceTypes.length + 1,
            status: true,
            description,
            parentCategoryId: industryName,
            isUpdated: true
        };
    
        setServiceTypes(recalculateOrders([...serviceTypes, business]));
        setNewServiceType({ name: '', description: '' });
        setDialogOpen(false);
    };

    // // Edit service type
    const handleSaveServiceTypeEdit = () => {
        if (!industryName) {
            setDialogOpen(false);
            setErrors({ industry: true });
            return;
        }

        const updatedServiceTypes = serviceTypes?.map(business =>
            business.id === editingBusiness.id
                ? {
                    ...business,
                    id: business.id,
                    title: newServiceType.name,
                    orders: business.orders,
                    status: true,
                    parentCategoryId: industryName,
                    isUpdated: true
                }
                : business
        );

        setServiceTypes(recalculateOrders(updatedServiceTypes));

        setDialogOpen(false);
        setEditingBusiness(null);
        setNewServiceType({ name: '', description: '' });
    };

    // Delete business
    const handleDeleteServiceType = (id) => {
        const updatedServiceTypes = serviceTypes?.map(item =>
            item.id === id
                ? { ...item, deleted: true, isUpdated: true }
                : item
        );

        setServiceTypes(recalculateOrders(updatedServiceTypes));

        const updatedTasks = servicesTasks?.map(task =>
            task.parentCategoryId === id
                ? { ...task, deleted: true, isUpdated: true }
                : task
        );

        setServicesTasks(recalculateOrders(updatedTasks));
    };

    // Add new task
    const handleAddTask = (businessId) => {
        const taskDetails = servicesTasks?.filter(item => item.parentCategoryId === businessId && !item.deleted);
        const data = sortingFunction(taskDetails);
    
        const newItemId = generateFirebaseId(subCategoryIds);
        const taskName = newTask[businessId]?.taskName;
        const description = newTask[businessId]?.description;
    
        if (taskName && description) {
            const task = {
                id: newItemId,
                title: taskName,
                description: description,
                orders: data.length + 1,
                parentCategoryId: businessId,
                status: true,
                isUpdated: true
            };
    
            setServicesTasks(recalculateOrders([...servicesTasks, task]));
            setSubCategories(recalculateOrders([...subCategories, task]));
    
            setNewTask({ ...newTask, [businessId]: { taskName: '', description: '' } });
    
            // Clear errors only for this businessId
            setErrors((prev) => ({
                ...prev,
                [businessId]: { serviceTaskName: false, serviceTaskDes: false }
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                [businessId]: {
                    serviceTaskName: !taskName,
                    serviceTaskDes: !description
                }
            }));
        }
    };
    

    // Edit task
    const handleSaveTaskEdit = (businessId) => {
        const updatedSubCategories = servicesTasks?.map(task =>
            task.id === editingTask.id
                ? {
                    ...task,
                    id: task.id,
                    title: newTask[businessId]?.taskName,
                    description: newTask[businessId]?.description,
                    orders: task.orders,
                    parentCategoryId: businessId,
                    status: true,
                    isUpdated: true
                }
                : task
        );

        setServicesTasks(recalculateOrders(updatedSubCategories));

        setEditingTask(null);
        setNewTask({ ...newTask, [businessId]: { taskName: '', description: '' } });
    };

    // Cancel task
    const handleCancelTask = (businessId) => {
        setEditingTask(null);
        setNewTask({ ...newTask, [businessId]: { taskName: '', description: '' } });
    };

    // Delete task
    const handleDeleteTask = (taskId) => {
        const updatedTasks = servicesTasks?.map(task =>
            task.id === taskId ? { ...task, deleted: true, isUpdated: true } : task
        );

        const parentCategoryId = updatedTasks?.find(task => task.id === taskId)?.parentCategoryId;
        if (!parentCategoryId) return;

        const finalTasks = recalculateOrders(updatedTasks);

        setServicesTasks(finalTasks);
    };

    const recalculateOrders = (categoriesItems) => {
        const activeItems = categoriesItems?.filter(item => !item.deleted);

        const groupedItems = activeItems.reduce((acc, item) => {
            const { parentCategoryId } = item;
            acc[parentCategoryId] = acc[parentCategoryId] || [];
            acc[parentCategoryId].push(item);
            return acc;
        }, {});

        const updatedItems = Object.values(groupedItems).flatMap((items) =>
            items
                .sort((a, b) => a.orders - b.orders)
                .map((item, index) => ({
                    ...item,
                    orders: index + 1,
                }))
        );

        const removedItems = categoriesItems.filter(item => item.deleted);
        return [...updatedItems, ...removedItems];
    };

    const handleSaveData = async () => {
        try {

            if (!industryName) {
                setErrors({
                    industry: !industryName,
                });
                return;
            }

            setErrors({industry: false });
            commonContext?.setLoader(true);

            const industryDetails = industry?.find((item) => item.id === industryName);

            const updatedServiceTypes = serviceTypes?.map((item) => {
                const { isUpdated, ...rest } = item;
                return {
                    ...rest,
                    companyId,
                };
            }) || [];

            const subDetails = servicesTasks?.filter((items) => updatedServiceTypes.some((item) => item.id === items.parentCategoryId));

            const updatedTasks = subDetails?.map((item) => {
                const { isUpdated, ...rest } = item;
                return {
                    ...rest,
                    companyId,
                };
            }) || [];


            const dataToSave = {
                industry: industryDetails,
                enableReporting: enableReporting,
                companyId: companyId,
            };

            const [settingsData, categoriesData] = await Promise.all([
                saveSettings(dataToSave, 'client_settings'),
                updatedTasks.length > 0 ? saveSuppliesIndustry(updatedTasks, updatedServiceTypes) : Promise.resolve({ status: true }),
            ]);

            if (settingsData.status && categoriesData.status) {
                setOpen(true);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            commonContext?.setLoader(false);
        }
    };

    const handleUpdateData = async () => {
        try {

            if (!industryName) {
                setErrors({
                    industry: !industryName,
                });
                return;
            }

            setErrors({industry: false });
            commonContext?.setLoader(true);

            const industryDetails = industry?.find((item) => item.id === industryName);

            const updatedServiceTypes = serviceTypes
            ?.filter(item => item.isUpdated)
            .map(item =>
                item.companyId === companyId
                    ? item
                    : { ...item, companyId}
            ) || [];
        
            const subDetails = servicesTasks?.filter(items =>
                serviceTypes.some(item => item.id === items.parentCategoryId)
            );

            const updatedTasks = subDetails
            ?.filter(item => item.isUpdated)
            .map(item =>
                item.companyId === companyId
                    ? item
                    : { ...item, companyId}
            ) || [];
            if (updatedServiceTypes.length === 0 && updatedTasks.length > 0) {
                const requiredServiceTypeIds = [...new Set(updatedTasks.map(task => task.parentCategoryId))];
                const additionalServiceTypes = serviceTypes
                    ?.filter(item => requiredServiceTypeIds.includes(item.id))
                    .map(item =>
                        item.companyId === companyId
                            ? item
                            : { ...item, companyId }
                    );
            
                updatedServiceTypes.push(...additionalServiceTypes);
            }
            const data = [...updatedServiceTypes, ...updatedTasks];

            const dataToSave = {
                industry: industryDetails,
                enableReporting: enableReporting,
                companyId: companyId
            };
            const id = filterData[0]?.id;

            const [settingsData, categoriesData] = await Promise.all([
                updateSettings(dataToSave, 'client_settings', id),
                data.length > 0 ? saveOrUpdateSuppliesIndustry(updatedTasks, updatedServiceTypes) : Promise.resolve({ status: true }),
            ]);

            if (settingsData.status && categoriesData.status) {
                setUpdateOpen(true);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            commonContext?.setLoader(false);
        }
    };

    const handleResetConfirm = async () => {
        setResetOpen(false);
        commonContext?.setLoader(true);

        const id = filterData[0]?.id;

        try {
            const [details, suppliesData] = await Promise.all([
                resetSettings('client_settings', id),
                resetSuppliesIndustry(companyId)
            ]);

            if (details.status === true && suppliesData.status === true) {
                commonContext?.setLoader(false);
                setResetSuccessOpen(true);
            }

            commonContext?.setLoader(false);
        } catch (error) {
            console.error('Error resetting settings or supplies:', error);
            commonContext?.setLoader(false);
        }
    };

    const handleIndustryNameChange = (e) => {
        const str = e.target.value;
        setIndustryName(str);

        const details = categories?.filter((item) => item.parentCategoryId === str);
        const data = sortingFunction(details);
        setServiceTypes(data);

        const subDetails = subCategories?.filter((items) => data.some((item) => item.id === items.parentCategoryId));
        setServicesTasks(subDetails);
    }

    const handleColse = () => {
        setOpen(false);
        navigate("/settings");
    }

    const handleUpdateClose = () => {
        setUpdateOpen(false);
        navigate("/settings");
    }

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleResetSuccessClose = () => {
        setResetSuccessOpen(false);
        navigate("/settings");
    }

    const handleReset = () => {
        if (filterData) {
            setResetOpen(true);
        } else {
            setFilterData();
            setEnableReporting('Yes');
            setIndustry();
            setIndustryName();
            setEnableReporting();
            setServiceTypes();
            setCategories();
            setSubCategories();
            setSubCategoryIds();
            setErrors({});
        }
    };

    const handleCancel = () => {
        if (filterData) {
            navigate("/settings");
        } else {
            setFilterData();
            setEnableReporting('Yes');
            setIndustry();
            setIndustryName();
            setEnableReporting();
            setServiceTypes();
            setCategories();
            setSubCategories();
            setSubCategoryIds();
            setErrors({});
        }
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle={`Client Settings`}
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Settings", to: "/settings" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Client"}
                    showLastSeen={true}
                />

                <div className="sectionBox">
                    <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                        <div className={styles.widths}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Enable Automated Reporting</div>
                                <EnableReporting
                                    isMobile={isMobile}
                                    isLandscape={isLandscape}
                                    setEnableReporting={setEnableReporting}
                                    enableReporting={enableReporting}
                                />
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Select Industry</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : ` ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={industryName}
                                        name={"industryName"}
                                        label={"Industry Name"}
                                        onChange={(e) => handleIndustryNameChange(e)}
                                        options={industryNameList}
                                    />
                                    {errors.industry && !industryName && (
                                        <span className="d-flex small error color-danger">Select Industry</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!filterData && (
                        <div className={`${isMobile ? `${styles.widths}` : `${styles.widths} ml-20`}`}>
                            <div className="">
                                <div className={styles.label}> Add Industry</div>
                                <div>
                                    <div className="form-container">
                                        <input
                                            type="text"
                                            placeholder="Industry Name"
                                            value={newIndustry.name}
                                            onChange={(e) => setNewIndustry(prev => ({ ...prev, name: e.target.value }))}
                                            className={styles.textInput}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Industry Description"
                                            value={newIndustry.description}
                                            onChange={(e) => setNewIndustry(prev => ({ ...prev, description: e.target.value }))}
                                            className={styles.textInput}
                                        />
                                        <div className="button-group mt-10">
                                            <Button className={styles.btnPrimary} onClick={handleAddIndustry}>Add</Button>
                                            <Button className={`${styles.cancel} ml-15`}
                                                onClick={() => {
                                                    setNewIndustry({ name: '', description: '' });
                                                }}
                                            > Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="d-flex flex-space-between flex-start mb-15 mt-20">
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>Manage Industry and Services</div>
                        <div>
                            <Button
                                className={styles.btnPrimary}
                                onClick={() => setDialogOpen(true)}
                                startIcon={<AddIcon />}
                            >
                                Add Service Type
                            </Button>
                        </div>
                    </div>
                    <div className="mt-40">
                        <div className={styles.categoriesList}>
                            {serviceTypes?.filter(business => !business.deleted)?.map((business, index) => (
                                <div key={business.id} className={styles.categoryCard}>
                                    <div className={styles.categoryHeader}>
                                        <div>
                                            <h2>{index + 1}. {business.title}</h2>
                                            {/* <div>{business.description}</div> */}
                                        </div>
                                        <div className="button-group">
                                            <Button
                                                className={`${styles.btnIcon} ${styles.edit}`}
                                                onClick={() => {
                                                    setEditingBusiness(business);
                                                    setNewServiceType({ name: business.title, description: business.description });
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                className={`${styles.btnIcon} ${styles.delete}`}
                                                onClick={() => handleDeleteServiceType(business.id)}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <input
                                            type="text"
                                            placeholder="Task Name"
                                            value={newTask[business.id]?.taskName || ''}
                                            onChange={(e) => setNewTask({
                                                ...newTask,
                                                [business.id]: {
                                                    ...(newTask[business.id] || {}),
                                                    taskName: e.target.value
                                                }
                                            })}
                                            className={styles.textInput}
                                            style={{ width: "45%" }}
                                        />
                                        {errors[business.id]?.serviceTaskName && !newTask[business.id]?.taskName && (
                                            <span className="d-flex small error color-danger">Task Name Required</span>
                                        )}
                                        <input
                                            type="text"
                                            placeholder="Task Description"
                                            value={newTask[business.id]?.description || ''}
                                            onChange={(e) => setNewTask({
                                                ...newTask,
                                                [business.id]: {
                                                    ...(newTask[business.id] || {}),
                                                    description: e.target.value
                                                }
                                            })}
                                            className={styles.textInput}
                                            style={{ width: "45%" }}
                                        />
                                        {errors[business.id]?.serviceTaskDes && !newTask[business.id]?.description && (
                                            <span className="d-flex small error color-danger">Task Description Required</span>
                                        )}
                                        <div>
                                            {editingTask?.parentCategoryId === business.id ? (
                                                <div className="d-flex">
                                                    <Button className={`${styles.btn} ${styles.btnSuccess} mr-20`} onClick={() => handleSaveTaskEdit(business.id)}>Save</Button>
                                                    <Button className={`${styles.btn} ${styles.btnSuccess}`} onClick={() => handleCancelTask(business.id)}>Cancel</Button>
                                                </div>
                                            ) : (
                                                <Button className={`${styles.btn} ${styles.btnSuccess} mr-20`} onClick={() => handleAddTask(business.id)}>Add Task</Button>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles.itemsList}>
                                        {getBusinessTasks(business.id).map((task, taskIndex) => (
                                            <div key={task.id} className={styles.itemCard}>
                                                <div className={styles.itemHeader}>
                                                    <h3>{taskIndex + 1}. {task.title}</h3>
                                                    <div className={styles.itemActions}>
                                                        <Button
                                                            className={styles.editBtn}
                                                            onClick={() => {
                                                                setEditingTask(task);
                                                                setNewTask({ ...newTask, [business.id]: { taskName: task.title, description: task.description } });
                                                            }}
                                                        >
                                                            <EditIcon fontSize="small" className={styles.editIcons} />
                                                        </Button>
                                                        <Button
                                                            className={styles.deleteBtn}
                                                            onClick={() => handleDeleteTask(task.id)}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </Button>
                                                    </div>
                                                </div>
                                                {task.description && (
                                                    <p className={styles.itemDescription}>
                                                        Description: {task.description}
                                                    </p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mv-15">
                        <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={filterData ? handleUpdateData : handleSaveData}>{filterData ? 'Update' : 'Save'}</Button>
                        <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                    </div>
                </div>
            </Container>
            {dialogOpen &&
                <AddServiceType
                    open={dialogOpen}
                    onConfirm={editingBusiness ? handleSaveServiceTypeEdit : handleAddServiceType}
                    onClose={() => {
                        setDialogOpen(false);
                        setEditingBusiness(null);
                        setNewServiceType({ name: '', description: '' })
                    }}
                    newServiceType={newServiceType}
                    setNewServiceType={setNewServiceType}
                    editingBusiness={editingBusiness}
                    styles={styles}
                    errors={errors}
                />}
            {open && <SuccessSettingsDialog open={open} onClose={handleColse} />}
            {updateOpen && <UpdateSettingsDialog open={updateOpen} onClose={handleUpdateClose} />}
            {resetOpen && <ResetSettingDialog open={resetOpen} onConfirm={handleResetConfirm} onClose={handleResetClose} />}
            {resetSuccessOpen && <ResetSettingsSuccessDialog open={resetSuccessOpen} onClose={handleResetSuccessClose} />}
        </>

    )
};


export default ClientPageSetting;