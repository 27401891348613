import { Button, Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { generateFirebaseId, generateNumberArray } from "../../../services/helpers";
import styles from "../Accounts.module.scss";
import { useMediaQuery } from "@mui/material";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommonContext from "../../../hooks/commonContext";
import AuthContext from "../../../hooks/authContext";
import { getSettings, getSuppliesCompanyCategories, resetSettings, resetSuppliesCompanyCategory, saveOrUpdateSuppliesCompanyCategories, saveSettings, updateSettings } from "../../../services/apiservices/settings";
import { ResetSettingDialog, ResetSettingsSuccessDialog, SuccessSettingsDialog, UpdateSettingsDialog } from "../../../components/DialogComponent/SupplyDataDialog";
import { useNavigate } from "react-router";

const SupplyCategorysDetails = ({ supplyCategoryPros }) => {
    const {
        styles, isMobile, handleRemoveCategory, handleRemoveItem, getCategoryItems, parentCategories, handleAddCategory, handleAddItem, newCategory, setNewCategory,
        newItem, setNewItem, handleSaveEdit, setEditItem, editItem } = supplyCategoryPros;

    const handleInputChange = (e, categoryId) => {
        const { name, value } = e.target;
        setNewItem((prev) => ({
            ...prev,
            [categoryId]: {
                ...prev[categoryId],
                [name]: value,
            },
        }));
    };

    const handleEditClick = (item, categoryId) => {
        setEditItem({
            categoryId,
            itemId: item.id,
            title: item.title,
            description: item.description,
            maxNumberAllowed: item.maxNumberAllowed || '',
            OrderPeriod: item.OrderPeriod || '',
        });
    };

    const orderPeriodOption = [
        { label: "Monthly", value: "Monthly" },
        { label: "Quarterly", value: "Quarterly" },
        { label: "Semi-Annual", value: "Semi-Annual" }
    ]

    return (
        <div className={styles.supplyContainer}>
            <div
                className={
                    isMobile
                        ? 'd-flex flex-direction-column mv-30 w-100'
                        : 'mv-30 w-100'
                }
            >
                <div className={`${styles.label} mb-10`}>Add New Category</div>
                <div
                    className={
                        isMobile
                            ? `d-flex mt-10 ${styles.selectWidth}`
                            : `d-flex flex-center w-35`
                    }
                >
                    <input
                        type="text"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Enter category name"
                        className={`${styles.textInput} mr-10`}
                    />
                    <Button
                        onClick={handleAddCategory}
                        className={`${styles.btn} ${styles.btnPrimary}`}
                    >
                        <AddIcon /> Add Category
                    </Button>
                </div>
            </div>

            <div className={styles.categoriesList}>
                {parentCategories?.filter(category => !category.deleted)?.map((category) => (
                    <div key={category.id} className={styles.categoryCard}>
                        <div className={styles.categoryHeader}>
                            <h2>{category.orders}. {category.title}</h2>
                            <Button
                                onClick={() => handleRemoveCategory(category.id)}
                                className={`${styles.btnIcon} ${styles.delete}`}
                            >
                                Remove
                            </Button>
                        </div>

                        <div className={styles.inputGroup}>
                            <div className="d-flex" style={{ width: "90%" }}>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder="Item Name"
                                    value={editItem?.categoryId === category.id ? editItem.title : newItem[category.id]?.title || ''}
                                    onChange={(e) => {
                                        if (editItem?.categoryId === category.id) {
                                            setEditItem({ ...editItem, title: e.target.value });
                                        } else {
                                            handleInputChange(e, category.id);
                                        }
                                    }}
                                    className={styles.textInput}
                                />
                                <input
                                    type="text"
                                    name="description"
                                    placeholder="Description"
                                    value={editItem?.categoryId === category.id ? editItem.description : newItem[category.id]?.description || ''}
                                    onChange={(e) => {
                                        if (editItem?.categoryId === category.id) {
                                            setEditItem({ ...editItem, description: e.target.value });
                                        } else {
                                            handleInputChange(e, category.id);
                                        }
                                    }}
                                    className={styles.textInput}
                                />
                                {category.title === 'Company Clothing' && (
                                    <>
                                        <input
                                            type="number"
                                            name="maxNumberAllowed"
                                            placeholder="Max Number Allowed"
                                            value={editItem?.categoryId === category.id ? editItem.maxNumberAllowed : newItem[category.id]?.maxNumberAllowed || ''}
                                            onChange={(e) => {
                                                if (editItem?.categoryId === category.id) {
                                                    setEditItem({ ...editItem, maxNumberAllowed: e.target.value });
                                                } else {
                                                    handleInputChange(e, category.id);
                                                }
                                            }}
                                            className={styles.textInput}
                                        />
                                        <div className={styles.orderSelect}>
                                            <SingleSelect
                                                name="OrderPeriod"
                                                label="OrderPeriod"
                                                placeholder="OrderPeriod"
                                                value={editItem?.categoryId === category.id ? editItem.OrderPeriod : newItem[category.id]?.OrderPeriod || ''}
                                                onChange={(e) => {
                                                    if (editItem?.categoryId === category.id) {
                                                        setEditItem({ ...editItem, OrderPeriod: e.target.value });
                                                    } else {
                                                        handleInputChange(e, category.id);
                                                    }
                                                }}
                                                options={orderPeriodOption}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {editItem?.categoryId === category.id ? (
                                    <Button
                                        onClick={() => handleSaveEdit(category.id)}
                                        className={`${styles.btn} ${styles.btnSuccess}`}
                                    >
                                        Save Item
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => handleAddItem(category.id)}
                                        className={`${styles.btn} ${styles.btnSuccess}`}
                                    >
                                        Add Item
                                    </Button>
                                )}
                            </div>
                        </div>

                        <div className={styles.itemsList}>
                            {getCategoryItems(category.id)?.map((item, index) => (
                                <div key={item.id} className={styles.itemCard}>
                                    <div className={styles.itemHeader}>
                                        <h3>
                                            {index + 1}. {item.title}
                                        </h3>
                                        <div className={styles.itemActions}>
                                            <Button
                                                onClick={() => handleEditClick(item, category.id)}
                                                className={styles.editBtn}
                                            >
                                                <EditIcon fontSize="small" className={styles.editIcons} />
                                            </Button>
                                            <Button
                                                onClick={() => handleRemoveItem(item.id)}
                                                className={styles.deleteBtn}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </Button>
                                        </div>
                                    </div>
                                    {item.description && (
                                        <p className={styles.itemDescription}>
                                            Description: {item.description}
                                        </p>
                                    )}

                                    {category.title.toLowerCase() === 'company clothing' && (
                                        <>
                                            {item.maxNumberAllowed && (
                                                <p>Max Number Allowed: {item.maxNumberAllowed}</p>
                                            )}
                                            {item.OrderPeriod && (
                                                <p>Order Period: {item.OrderPeriod}</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const SuppliePageSetting = () => {
    const [autoArchiveRequest, setAutoArchiveRequest] = useState();
    const [archiveRequestRole, setArchiveRequestRole] = useState([]);
    const [approveRequestRole, setApproveRequestRole] = useState([]);
    const [intervalTimeOfClothing, setIntervalTimeOfClothing] = useState({
        days: null,
        months: null,
        years: null
    });
    const [parentCategories, setParentCategories] = useState([]);
    const [categoriesItems, setCategoriesItems] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [editItem, setEditItem] = useState(null);
    const [newItem, setNewItem] = useState({
        title: '',
        description: '',
        maxNumberAllowed: '',
        OrderPeriod: ''
    });
    const commonContext = useContext(CommonContext);
    const auth = useContext(AuthContext);
    const [error, setError] = useState({});
    const [data, setData] = useState();
    const [categoryIds, setCategoryIds] = useState([]);
    const [open, setOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
    const navigate = useNavigate();
    const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
    const isMobile = useMediaQuery('(max-width:767px)');

    const autoArchiveOptions = [
        { label: "Not Allowed", value: "notAllowed" },
        { label: "15 Days", value: "15 Days" },
        { label: "30 Days", value: "30 Days" },
        { label: "60 Days", value: "60 Days" },
        { label: "90 Days", value: "90 Days" },
    ];

    const Roles = [
        { label: "ADMIN", value: "admin" },
        { label: "SUPPORT", value: "support" },
    ]

    const monthsOptions = generateNumberArray(1, 12);
    const daysOptions = generateNumberArray(1, 30);
    const yearsOptions = generateNumberArray(1, 2);

    const handleAutoRequestChange = (e) => {
        const data = e.target.value;
        setAutoArchiveRequest(data);
    }

    const handleArchiveRequestRoleChange = (e) => {
        setArchiveRequestRole(e);
    }

    const handleApproveRequestRoleChange = (e) => {
        setApproveRequestRole(e);
    }

    const handleIntervalTimeOfClothing = (e) => {
        const { name, value } = e.target;
        if (name === "days") {
            setIntervalTimeOfClothing({ days: value, months: null, years: null })
        } else if (name === "months") {
            setIntervalTimeOfClothing({ days: null, months: value, years: null })
        } else if (name === "years") {
            setIntervalTimeOfClothing({ days: null, months: null, years: value })
        }
    }

    const fetchSettings = async () => {
        try {
            commonContext.setLoader(true);

            await Promise.all([
                getSuppliesCompanyCategories((categoriesData) => {
                    const parentCategoriesCompanyData = categoriesData?.filter(
                        (item) => !item.parentCategoryId && item.companyId === companyId && !item.deleted
                    );
                    const childCategoriesCompanyData = categoriesData?.filter(
                        (item) => item.parentCategoryId && item.companyId === companyId && !item.deleted
                    );

                    setParentCategories(sortingFunction(parentCategoriesCompanyData));
                    setCategoriesItems(sortingFunction(childCategoriesCompanyData));

                    const allCategoryIds = categoriesData.map(item => item.id);
                    setCategoryIds(allCategoryIds);
                }),

                getSettings('supplie_settings', (res) => {
                    if (res?.data) {
                        const filteredData = res?.data?.filter(
                            (item) => item?.companyId === companyId && !item.deleted
                        );

                        if (filteredData.length > 0) {
                            const [firstItem] = filteredData;
                            setData(filteredData);
                            setAutoArchiveRequest(firstItem?.autoArchiveRequest);
                            setArchiveRequestRole(firstItem?.archiveRequestRole);
                            setApproveRequestRole(firstItem?.approveRequestRole);
                            setIntervalTimeOfClothing({
                                days: firstItem?.intervalTimeOfClothing?.days,
                                months: firstItem?.intervalTimeOfClothing?.months,
                                years: firstItem?.intervalTimeOfClothing?.years
                            });
                        } else {
                            setData();
                            setAutoArchiveRequest();
                            setArchiveRequestRole([]);
                            setApproveRequestRole([]);
                            setIntervalTimeOfClothing({});
                        }
                    } else {
                        setData();
                        setAutoArchiveRequest();
                        setArchiveRequestRole([]);
                        setApproveRequestRole([]);
                        setIntervalTimeOfClothing({});
                    }
                })
            ]);
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            commonContext.setLoader(false);
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.orders < b.orders) return -1;
            if (a.orders > b.orders) return 1;

            return 0;
        });

        return result;
    };

    const handleAddCategory = () => {
        if (newCategory.trim()) {
            const newCategoryId = generateFirebaseId(categoryIds);
            const currentCategories = Array.isArray(parentCategories) ? parentCategories : [];

            const updatedCategories = [
                ...currentCategories,
                {
                    id: newCategoryId,
                    orders: currentCategories.length + 1,
                    title: newCategory,
                    status: true,
                    description: "",
                    isUpdated: true
                }
            ];

            const recalculateOrders = updatedCategories.map((category, index) => ({
                ...category,
                orders: index + 1
            }));

            setParentCategories(recalculateOrders);
            setNewCategory('');
        }
    };

    const handleAddItem = (categoryId) => {
        const currentNewItem = newItem[categoryId];
        if (currentNewItem?.title?.trim()) {
            const newItemId = generateFirebaseId(categoryIds);

            setCategoriesItems((prev) => {
                const updatedItems = [
                    ...prev,
                    {
                        id: newItemId,
                        orders: prev.filter(item => item.parentCategoryId === categoryId).length + 1,
                        title: currentNewItem.title,
                        description: currentNewItem.description,
                        parentCategoryId: categoryId,
                        ...(currentNewItem?.maxNumberAllowed && { maxNumberAllowed: currentNewItem.maxNumberAllowed }),
                        ...(currentNewItem?.OrderPeriod && { OrderPeriod: currentNewItem.OrderPeriod }),
                        isUpdated: true
                    },
                ];
                return recalculateOrders(updatedItems);
            });

            setNewItem((prev) => ({
                ...prev,
                [categoryId]: {
                    title: '',
                    description: '',
                },
            }));
        }
    };

    const handleRemoveItem = (itemId) => {
        setCategoriesItems((prev) => {
            const updatedItems = prev.map(item =>
                item.id === itemId ? { ...item, deleted: true, isUpdated: true } : item
            );
            return recalculateOrders(updatedItems);
        });
    };

    const handleSaveEdit = () => {
        setCategoriesItems((prev) => {
            const updatedItems = prev.map((item) =>
                item.id === editItem.itemId
                    ? { ...item, ...editItem, isUpdated: true }
                    : item
            );
            return recalculateOrders(updatedItems);
        });

        setEditItem(null);
    };

    const handleRemoveCategory = (categoryId) => {
        const updatedCategories = parentCategories?.map(category =>
            category.id === categoryId ? { ...category, deleted: true, isUpdated: true } : category
        );

        const activeCategories = updatedCategories?.filter(category => !category.deleted);
        const reorderedActiveCategories = activeCategories?.map((category, index) => ({
            ...category,
            orders: index + 1,
        }));

        const removedCategories = updatedCategories?.filter(category => category.deleted);
        const finalCategories = [...reorderedActiveCategories, ...removedCategories];
        setParentCategories(finalCategories);

        const updatedItems = categoriesItems?.map(item =>
            item.parentCategoryId === categoryId ? { ...item, deleted: true, isUpdated: true } : item
        );
        setCategoriesItems(updatedItems);
    };

    const getCategoryItems = (categoryId) => {
        return categoriesItems?.filter(item => item?.parentCategoryId === categoryId && item?.deleted !== true);
    };

    const validFields = () => {
        const errormessages = {};
        let isValid = true;

        if (!autoArchiveRequest) {
            isValid = false;
            errormessages.autoArchiveRequest = "Auto Archive Request is Required";
        }
        if (!archiveRequestRole.length) {
            isValid = false;
            errormessages.archiveRequestRole = "Archive Request Role is Required";
        }
        if (!approveRequestRole.length) {
            isValid = false;
            errormessages.approveRequestRole = "Approve Request Role is Required";
        }

        setError(errormessages);
        return isValid;
    };

    const recalculateOrders = (categoriesItems) => {
        const activeItems = categoriesItems?.filter(item => !item.deleted);

        const groupedItems = activeItems.reduce((acc, item) => {
            const { parentCategoryId } = item;
            acc[parentCategoryId] = acc[parentCategoryId] || [];
            acc[parentCategoryId].push(item);
            return acc;
        }, {});

        const updatedItems = Object.values(groupedItems).flatMap((items) =>
            items
                .sort((a, b) => a.orders - b.orders)
                .map((item, index) => ({
                    ...item,
                    orders: index + 1,
                }))
        );

        const removedItems = categoriesItems.filter(item => item.deleted);
        return [...updatedItems, ...removedItems];
    };

    const handleSaveData = async () => {
        try {
            commonContext?.setLoader(true);

            if (!validFields()) return;

            const filterParentData = parentCategories?.filter(item => item.isUpdated).map(({ isUpdated, ...rest }) => rest) || [];
            const filterChildData = categoriesItems?.filter(item => item.isUpdated).map(({ isUpdated, ...rest }) => rest) || [];
            const data1 = [...filterParentData, ...filterChildData];

            const dataToSave = {
                autoArchiveRequest,
                archiveRequestRole,
                approveRequestRole,
                intervalTimeOfClothing,
                companyId
            };

            const [settingsData, categoriesData] = await Promise.all([
                saveSettings(dataToSave, 'supplie_settings'),
                data1.length > 0 ? saveOrUpdateSuppliesCompanyCategories(data1) : { status: true }
            ]);

            if (settingsData.status && categoriesData.status) {
                setOpen(true);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            commonContext?.setLoader(false);
        }
    };

    const handleUpdateData = async () => {
        try {
            commonContext?.setLoader(true);

            if (!validFields()) return;

            const filterParentData = parentCategories?.filter(item => item.isUpdated).map(({ isUpdated, ...rest }) => rest) || [];
            const filterChildData = categoriesItems?.filter(item => item.isUpdated).map(({ isUpdated, ...rest }) => rest) || [];
            const data1 = [...filterParentData, ...filterChildData];

            const dataToSave = {
                autoArchiveRequest,
                archiveRequestRole,
                approveRequestRole,
                intervalTimeOfClothing,
                companyId
            };

            const id = data[0]?.id || null;

            const [settingsData, categoriesData] = await Promise.all([
                updateSettings(dataToSave, 'supplie_settings', id),
                data1.length > 0 ? saveOrUpdateSuppliesCompanyCategories(data1) : { status: true }
            ]);

            if (settingsData.status && categoriesData.status) {
                setUpdateOpen(true);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            commonContext?.setLoader(false);
        }
    };

    const handleResetConfirm = async () => {
        setResetOpen(false);
        commonContext?.setLoader(true);

        const id = data[0]?.id;

        try {
            const [details, suppliesData] = await Promise.all([
                resetSettings('supplie_settings', id),
                resetSuppliesCompanyCategory(companyId)
            ]);

            if (details.status === true && suppliesData.status === true) {
                commonContext?.setLoader(false);
                setResetSuccessOpen(true);
            }

            commonContext?.setLoader(false);
        } catch (error) {
            console.error('Error resetting settings or supplies:', error);
            commonContext?.setLoader(false);
        }
    };

    const handleResetClose = () => {
        setResetOpen(false);
    }

    const handleResetSuccessClose = () => {
        setResetSuccessOpen(false);
        fetchSettings();
    }

    const handleColse = () => {
        setOpen(false);
        navigate("/settings");
    }

    const handleUpdateClose = () => {
        setUpdateOpen(false);
        navigate("/settings");
    }

    const handleReset = () => {
        if (data) {
            setResetOpen(true);
        } else {
            setData();
            setAutoArchiveRequest(null);
            setArchiveRequestRole([]);
            setApproveRequestRole([]);
            setIntervalTimeOfClothing({});
            setError({});
        }
    };

    const handleCancel = () => {
        if (data) {
            navigate("/settings");
        } else {
            setData();
            setAutoArchiveRequest(null);
            setArchiveRequestRole([]);
            setApproveRequestRole([]);
            setIntervalTimeOfClothing({});
            setError({});
        }
    };

    const supplyCategoryPros = {
        styles, isMobile, handleRemoveCategory, handleRemoveItem, getCategoryItems, parentCategories, handleAddCategory, handleAddItem, newCategory, setNewCategory,
        newItem, setNewItem, handleSaveEdit, setEditItem, editItem
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle={`Supplie Settings`}
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Settings", to: "/settings" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Supplie"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                        <div className={styles.widths}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Auto Archive Request</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <SingleSelect
                                        value={autoArchiveRequest}
                                        name={"autoArchiveRequest"}
                                        label={"Auto Archive Request"}
                                        onChange={handleAutoRequestChange}
                                        options={autoArchiveOptions}
                                    />
                                    {error.autoArchiveRequest && (
                                        <span className="d-flex small error color-danger">{error.autoArchiveRequest}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Archive Request Role</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={archiveRequestRole}
                                        name={"archiveRequestRole"}
                                        label={"Archive Request Role"}
                                        onChange={(e) => handleArchiveRequestRoleChange(e)}
                                        options={Roles}
                                        selectAllOption={true}
                                    />
                                    {error.archiveRequestRole && (
                                        <span className="d-flex small error color-danger">{error.archiveRequestRole}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${isMobile ? `${styles.widths}` : `${styles.widths} ml-20`}`}>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Approve Request Role</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <MultiSelect
                                        value={approveRequestRole}
                                        name={"approveRequestRole"}
                                        label={"Approve Request Role"}
                                        onChange={(e) => handleApproveRequestRoleChange(e)}
                                        options={Roles}
                                        selectAllOption={true}
                                    />
                                    {error.approveRequestRole && (
                                        <span className="d-flex small error color-danger">{error.approveRequestRole}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                                <div className={styles.label}>Interval Time Of Clothing</div>
                                <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                    <select
                                        value={intervalTimeOfClothing.days || ""}
                                        onChange={handleIntervalTimeOfClothing}
                                        name={"days"}
                                        className={`${styles.selectIntervalTime} mr-10`}
                                    ><option value="" disabled> Days </option>
                                        {daysOptions.map((day, index) => (
                                            <option key={index} value={day}>{day}</option>
                                        ))}
                                    </select>
                                    <select
                                        value={intervalTimeOfClothing.months || ""}
                                        onChange={handleIntervalTimeOfClothing}
                                        name={"months"}
                                        className={`${styles.selectIntervalTime} mh-10`}
                                    >  <option value="" disabled> Months </option>
                                        {monthsOptions.map((month, index) => (
                                            <option key={index} value={month}>{month}</option>
                                        ))}
                                    </select>
                                    <select
                                        value={intervalTimeOfClothing.years || ""}
                                        onChange={handleIntervalTimeOfClothing}
                                        name={"years"}
                                        className={`${styles.selectIntervalTime} ml-10`}
                                    > <option value="" disabled> Years </option>
                                        {yearsOptions.map((year, index) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SupplyCategorysDetails supplyCategoryPros={supplyCategoryPros} />
                    </div>
                    <div className="mv-15">
                        <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={data ? handleUpdateData : handleSaveData}>{data ? "Update" : "Save"}</Button>
                        <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }} onClick={handleReset}>Reset</Button>
                    </div>
                </div>
            </Container>
            {open && <SuccessSettingsDialog open={open} onClose={handleColse} />}
            {updateOpen && <UpdateSettingsDialog open={updateOpen} onClose={handleUpdateClose} />}
            {resetOpen && <ResetSettingDialog open={resetOpen} onConfirm={handleResetConfirm} onClose={handleResetClose} />}
            {resetSuccessOpen && <ResetSettingsSuccessDialog open={resetSuccessOpen} onClose={handleResetSuccessClose} />}
        </>
    )
}

export default SuppliePageSetting;