import React, { useContext } from 'react';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import AuthContext from '../../../hooks/authContext';
import { format, isSameDay } from 'date-fns';
import { filterAppointments } from '../../../services/helpers';

const MonthView = (props) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const isFullScreen = useMediaQuery('(min-width:1500px)');
  const auth = useContext(AuthContext);
  const isExpand = auth.isExpanded;
  const appointments = auth.appointmentsData;
  const setIsModalOpen = auth.setIsModalOpen;
  const setSelectedAppointment = auth.setSelectedAppointment;

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const subtractMonth = (date) => new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const generateMonthView = () => {
    const daysInMonth = getDaysInMonth(props?.currentDate);
    const firstDay = getFirstDayOfMonth(props?.currentDate);
    const previousMonthDays = getDaysInMonth(subtractMonth(props?.currentDate));
    const totalCells = 42;

    const days = [];

    // const filteredAppointments = appointments.filter((item) =>
    //   props?.employeeNameList?.some((employee) => employee.value === item.userId)
    // );
    
    const getAppointmentsForDate = (date) => {
      if (!Array.isArray(appointments)) {
        return [];
      }

      return appointments.filter((appointment) => {
        const appointmentDate = new Date(appointment.apoointmentDetails.date);
        return isSameDay(appointmentDate, date);
      });
    };

    // Add previous month's dates
    for (let i = firstDay - 1; i >= 0; i--) {
      const date = moment(props?.currentDate).subtract(1, "month").date(previousMonthDays - i);
      const dayAppointments = getAppointmentsForDate(date);
      const filterDayAppointments = filterAppointments(dayAppointments, props?.clientName, props?.empNames);
      const appointmentCount = filterAppointments(dayAppointments, props?.clientName, props?.empNames).length;
      days.push(
        <div key={`prev-${i}`} className="calendar-day-null">
          <div className="appointmentDateCount">
            <div className="calendar-day-number light-color" style={{width : isExpand && isFullScreen ? "81%" : ""}}>{previousMonthDays - i}</div>
            <div className="appointmentCount" style={{width : isExpand && isFullScreen ? "19%" : ""}} onClick={() => props?.handleScrollToAppointments(date)}>
              {appointmentCount > 0 && (
                <span>
                  {appointmentCount} {!isMobile ? 'Appts' : ''}
                </span>
              )}
            </div>
          </div>
          {filterDayAppointments.map((appointment, index) => {
            const emp = props?.employeeNameList?.find((emp) => emp.userId === appointment.userId);
            return (
              !isMobile ? (
                <div key={index} className="appointment" onClick={() => {
                  setSelectedAppointment(appointment);
                  setIsModalOpen(true);
                }}>
                  <span style={{ wordBreak: "break-all" }}>
                    <span className='clientTime'>{props?.settingsData?.timeFormat === "24hrs" ? moment(appointment.apoointmentDetails.time, 'h:mm A').format('HH:mm') : appointment.apoointmentDetails.time}</span> {" "}
                    {emp && (
                      <span className='clientLable'>
                        {emp?.firstName} {emp?.lastName}
                      </span>
                    )}
                  </span>
                </div>
              ) : null
            );
          })}
        </div>
      );
    }

    // Add current month's dates
    for (let day = 1; day <= daysInMonth; day++) {
      const date = moment(props?.currentDate).date(day);
      const dayAppointments = getAppointmentsForDate(date);
      const filterDayAppointments = filterAppointments(dayAppointments, props?.clientName, props?.empNames);
      const appointmentCount = filterAppointments(dayAppointments, props?.clientName, props?.empNames).length;
      days.push(
        <div key={`current-${day}`} className="calendar-day">
          <div className="appointmentDateCount">
            <div className="calendar-day-number" style={{width : isExpand && isFullScreen ? "81%" : ""}}>{day}</div>
            <div className="appointmentCount" style={{width : isExpand && isFullScreen ? "19%" : ""}} onClick={() => props?.handleScrollToAppointments(date)}>
              {appointmentCount > 0 && (
                <div>
                  {appointmentCount} {!isMobile ? 'Appts' : ''}
                </div>
              )}
            </div>
          </div>
          {filterDayAppointments.map((appointment, index) => {
            const emp = props?.employeeNameList?.find((emp) => emp.userId === appointment.userId);
            return (
              !isMobile ? (
                <div key={index} className="appointment" onClick={() => {
                  setSelectedAppointment(appointment);
                  setIsModalOpen(true);
                }}>
                  <span style={{ wordBreak: "break-all" }}>
                    <span className='clientTime'>{props?.settingsData?.timeFormat === "24hrs" ? moment(appointment.apoointmentDetails.time, 'h:mm A').format('HH:mm') : appointment.apoointmentDetails.time}</span> {" "}
                    {emp && (
                      <span className='clientLable'>
                        {emp?.firstName} {emp?.lastName}
                      </span>
                    )}
                  </span>
                </div>) : null
            );
          })}
        </div>
      );
    }

    // Add next month's dates
    const remainingCells = totalCells - days.length;
    for (let i = 1; i <= remainingCells; i++) {
      const date = moment(props?.currentDate).add(1, "month").date(i);
      const dayAppointments = getAppointmentsForDate(date);
      const filterDayAppointments = filterAppointments(dayAppointments, props?.clientName, props?.empNames);
      const appointmentCount = filterAppointments(dayAppointments, props?.clientName, props?.empNames).length;
      days.push(
        <div key={`next-${i}`} className="calendar-day-null">
          <div className="appointmentDateCount">
            <div className="calendar-day-number light-color" style={{width : isExpand && isFullScreen ? "81%" : ""}}>{i}</div>
            <div className="appointmentCount"  style={{width : isExpand && isFullScreen ? "19%" : ""}} onClick={() => props?.handleScrollToAppointments(date)}>
              {appointmentCount > 0 && (
                <span>
                  {appointmentCount} {!isMobile ? 'Appts' : ''}
                </span>
              )}
            </div>
          </div>
          {filterDayAppointments.map((appointment, index) => {
            const emp = props?.employeeNameList?.find((emp) => emp.userId === appointment.userId);
            return (
              !isMobile ? (
                <div key={index} className="appointment" onClick={() => {
                  setSelectedAppointment(appointment);
                  setIsModalOpen(true);
                }}>
                  <span style={{ wordBreak: "break-all" }}>
                    <span className='clientTime'>{props?.settingsData?.timeFormat === "24hrs" ? moment(appointment.apoointmentDetails.time, 'h:mm A').format('HH:mm') : appointment.apoointmentDetails.time}</span> {" "}
                    {emp && (
                      <span className='clientLable'>
                        {emp?.firstName} {emp?.lastName}
                      </span>
                    )}
                  </span>
                </div>
              ) : null
            );
          })}
        </div>
      );
    }

    return days;
  };


  return (
    <div className="calendar-container-month">
      <div className='mt-5'>
        <div className="calendar-grid-month">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className="weekday-header">
              {day}
            </div>
          ))}
        </div>
        <div className="calendar-grid-month">
          {generateMonthView()}
        </div>
      </div>
    </div>
  );
};

export default MonthView;