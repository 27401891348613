import React, { useContext, useEffect } from "react";
import { Container, TextField } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import TableToolbar from "../../../components/Table/TableToolbar";
import { getSettings } from "../../../services/apiservices/settings";
import { getAllEmployee } from "../../../services/apiservices/employee";
import ThemeTable from "../../../components/Table/ThemeTable";
import moment from "moment";
import AuthContext from "../../../hooks/authContext";

const CompanySubscription = () => {
    const [searchTerm, setsearchTerm] = React.useState("");
    const [companyData, setCompanyData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const auth = useContext(AuthContext);
    const companyId = auth?.userProfile?.role === "companyUser" ? auth?.userProfile?.id : auth?.userProfile?.companyId;
   
    const Columns = [
        {
            id: "date",
            numeric: false,
            disablePadding: true,
            label: "Date",
            isSortable: true,
        },
        {
            id: "companyName",
            numeric: false,
            disablePadding: true,
            label: "Company Name",
            isSortable: true,
        },
        {
            id: "product",
            numeric: false,
            disablePadding: true,
            label: "Product Name",
            isSortable: true,
        },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Price",
            isSortable: true,
        },
        {
            id: "period",
            numeric: false,
            disablePadding: true,
            label: "Plan Period",
            isSortable: true,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            isSortable: true,
        },
    ];

    const formatPrice = (amount, currency) => {
        if (!amount || !currency) return "";
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase()
        }).format(amount / 100);
    };

    const formatTimestamp = (timestamp) => {
        return moment.unix(timestamp).utc().format("DD MMM YYYY");
    };

    const fetchCompanyList = async () => {
        setIsLoading(true);
    
        const [companyResponse, subscriptionResponse] = await Promise.all([
            new Promise((resolve) => getAllEmployee(resolve)),
            new Promise((resolve) => getSettings('strip_subscription', resolve))
        ]);
    
        let companyData = companyResponse?.data || [];
        let subscriptionData = subscriptionResponse?.data || [];
    
        const companyDetails = companyData?.filter(
            (item) => item.role === 'companyUser' && !item.deleted
        );
    
        const subscriptionMap = new Map(subscriptionData?.map(sub => [sub?.customer, sub]));
    
        const filteredCompanyDetails = auth?.userProfile?.role === "superUser"
            ? companyDetails
            : companyDetails.filter(company => company.id === companyId);
    
        const newData = filteredCompanyDetails?.map((company) => {
            const matchinSubsriptonData = subscriptionMap?.get(company?.customer);
            const createdDate = new Date(company?.createdAt.seconds * 1000 + company?.createdAt.nanoseconds / 1000000);
            return {
                ...company,
                date: matchinSubsriptonData?.start_date ? formatTimestamp(matchinSubsriptonData?.start_date) : moment(createdDate).format("DD MMM YYYY"),
                companyName: `${company.firstName} ${company.lastName}`,
                product: matchinSubsriptonData?.plan?.name,
                price: formatPrice(matchinSubsriptonData?.plan?.amount, matchinSubsriptonData?.currency),
                period: matchinSubsriptonData?.plan?.interval,
                status: matchinSubsriptonData?.status
            };
        });
    
        const sortedData = sortingFunction(newData);
        setCompanyData(sortedData);
        setIsLoading(false);
    };
    

    const sortingFunction = (data) => {
        const result = data?.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;

            if (a.companyName < b.companyName) return -1;
            if (a.companyName > b.companyName) return 1;

            return 0;
        });

        return result;
    };


    useEffect(() => {
        fetchCompanyList();
    }, []);

    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch">
                <div className="mh-9">
                    <TextField
                        fullWidth={true}
                        size="small"
                        name="searchTerm"
                        id="searchTerm"
                        label=""
                        variant="outlined"
                        placeholder="Search Company..."
                        className="input"
                        onChange={(event) => {
                            setsearchTerm(event.target.value);
                        }}
                        value={searchTerm ? searchTerm : ""}
                    />
                </div>
            </div>
        )
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            default:
                return <p>{rcellItem[hcellItem?.id]} </p>;
        }
    }

    const checkRowSearch = (searchTerm, row) => {
        return (
            String(row.companyName)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.period)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.product)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.status)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase())
        );
    };

    const filteredList =
        searchTerm !== ""
            ? companyData?.filter((item) => checkRowSearch(searchTerm, item))
            : companyData;

    return (
        <Container>
            <PageTitle
                pageTitle={"Company Subscription"}
                showBreadcrumb={[
                    { label: "Home"}
                ]}
                BreadCrumbCurrentPageTitle={"Company Subscription"}
                showLastSeen={true} />

            <div className="sectionBox">
                <TableToolbar
                    title={"Company Subscription"}
                    rightComponent={tableRightComponent()}
                />

                <ThemeTable
                    rows={filteredList}
                    headCells={Columns}
                    hidePagination={false}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                />
            </div>
        </Container>
    )
}
export default CompanySubscription;